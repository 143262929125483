import { Col, Container, Row } from 'styled-bootstrap-grid';
import { IApiCustomerRadarLicense, PriceGroup, ProductGetResponse, ProductType } from 'api';
import { ICarouselImg, ImgCarousel } from 'components/Shared/ImgCarousel/ImgCarousel';
import React, { FC, useEffect, useState } from 'react';
import { useGolfShopStore, useNotificationStore } from 'stores/hooks';

import { Button } from '@trackman/react-ui';
import { Colors } from 'styles/colors';
import { CourseDisclaimerModal } from './components/CourseDisclaimerModal/CourseDisclaimerModal';
import { Currency } from 'models/Currency';
import { DisclaimerModal } from './components/DisclaimerModal/DisclaimerModal';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import { History } from 'history';
import { IListItem } from 'models/IListItem';
import { ListItems } from './components/ListItems/ListItems';
import { Notification } from 'components/Notification/Notification';
import { NotificationType } from 'models/NotficationType';
import Radio from '@material-ui/core/Radio';
import RadioGroup from '@material-ui/core/RadioGroup';
import { SpecialDiscounts } from './components/SpecialDiscounts/SpecialDiscounts';
import { SubscriptionInformationModal } from './components/SubscriptionInformation/SubscriptionInformationModal';
import { TotalPrice } from './components/TotalPrice/TotalPrice';
import { calculateFullThreeYearPrice } from 'utils';
import { observer } from 'mobx-react-lite';
import parse from 'html-react-parser';
import styled from 'styled-components';
import { translate } from 'models/translate';
import useListItemsFilteredByProductType from 'utils/useListItemsFilteredByProductType';
import { useMediaQuery } from 'react-responsive';
import { useRouteMatch } from 'react-router-dom';
import { v1 as uuid } from 'uuid';

const Wrapper = styled.div`
  .header-and-sub-header {
    h1 {
      text-transform: uppercase;
      font-family: Lato;
      font-weight: bold;
      font-size: 22px;
      margin-bottom: 12px;
    }
    p {
      color: ${Colors.BRAND};
      font-size: 20px;
      font-weight: bold;
      margin-bottom: 29px;
    }
    margin: 10px 0px 10px 0px;
  }

  img {
    margin: 0 0;
    width: 100%;
  }

  .description-header {
    text-transform: uppercase;
    font-size: 18px;
    margin-bottom: 29px;
    margin-top: 29px;
  }

  .description-content {
    font-size: 15px;
    line-height: 21px;
    color: ${Colors.SECONDARY_GRAY};
    margin: 10px 10px 20px 0px;
    a {
      color: ${Colors.SECONDARY_GRAY};
    }
  }

  .product-description-content {
    a {
      cursor: pointer;
      color: ${Colors.SECONDARY_GRAY};
    }
    ul {
      list-style-type: disc;
      margin-left: 20px;
    }
  }

  .disclaimer {
    color: ${Colors.GRAY};
    margin: 10px 0px 10px 0px;
  }

  .add-cart-button {
    background-color: ${Colors.ACCEPT_GREEN};
    border: solid 2px ${Colors.ACCEPT_GREEN};
    margin: 10px 0px 10px 0px;
  }

  .info-text {
    color: ${Colors.SECONDARY_GRAY};
    display: flex;
    flex-direction: column;
    justify-content: space-around;
    margin: 10px 0px 10px 0px;
  }
  .read-more {
    font-weight: bold;
    font-size: 13px;
    color: ${Colors.GRAY};
  }

  //TODO: Remove later
  .mui-form-control-label-root {
    justify-content: space-between;
    background: ${Colors.WHITE};
    box-shadow: 0px 5px 15px rgba(0, 0, 0, 0.1);
    border-radius: 4px;
    padding: 19px 16px 16px 16px;
    margin: 10px 0px 10px 0px;

    .MuiIconButton-colorSecondary.Mui-checked {
      color: ${Colors.BRAND};
    }
  }
`;

type Props = {
  history: History;
};

const priceGroupDisplay: Record<PriceGroup, string> = {
  B1Software: 'Baseball B1 Software',
  TMiOHome: 'TMiO Home',
  TMiOHomeComplete: 'TMiO Home Complete',
  TMiOCommercial: 'TMiO Commercial',
  TM4: 'TM4',
  TMiOHomeDuo: 'TMiO Home Duo',
  TMiOHomeCompleteDuo: 'TMiO Home Complete Duo',
  TMiOCommercialDuo: 'TMiO Commercial Duo',
};

export const ProductPage: FC<Props> = observer(({ history }) => {
  const match = useRouteMatch<{ id: string; productType: string }>();
  const id = match.params.id;
  const productType: ProductType = Number(match.params.productType || '0');
  const {
    products,
    currency,
    yearDuration,
    listItems,
    isSoftwareExpiryModalVisible,
    currencySymbol,
    editItem,
    addToCart,
    setIsSoftwareExpiryModalVisible,
    getAllLicenseInfos,
    getRadarLicenses,
    setYearDuration,
    getProductImage,
    getProductName,
    getProductDescriptionContent,
    getProductDescriptionHeader,
    getCoursePackResources,
    clearItem,
  } = useGolfShopStore();
  const t = translate('products');
  const { addNotification } = useNotificationStore();
  const [product, setProduct] = useState<ProductGetResponse>();
  const [readMore, setReadMore] = useState<boolean>(false);
  const [isCourseDisclaimerModalVisible, setIsCourseDisclaimerModalVisible] =
    useState<boolean>(false);
  const [isTrialDisclaimerModalVisible, setIsTrialDisclaimerModalVisible] =
    useState<boolean>(false);
  const [isSubscriptionInformationModalVisible, setIsSubscriptionInformationModalVisible] =
    useState<boolean>(false);
  const [selectedItem, setSelectedItem] = useState<IListItem>();

  const listItemsFiltered = useListItemsFilteredByProductType(productType);

  const [imgSrc, setImgSrc] = useState<string>('');
  const [result, setResult] = useState<{ isError: boolean; errorMessage: string }>({
    isError: false,
    errorMessage: '',
  });
  const isTabletOrDesktop = useMediaQuery({ query: '(min-width: 576px)' });
  const [courseImages, setCourseImages] = useState<ICarouselImg[]>([]);

  useEffect(() => {
    const filteredProducts = products.filter((p: ProductGetResponse) => p.id === id);
    if (filteredProducts.length > 0) {
      setProduct(filteredProducts[0]);
    } else {
      // todo: show 404
    }
  }, [id, products]);

  useEffect(() => {
    if (product) {
      if (productType === ProductType.CoursePack || productType === ProductType.CoursePackDuo) {
        setCourseImages(getCoursePackResources(product.nameIdentifier));
        //setIsCourseDisclaimerModalVisible(true); leave the comment in case we need to enable it again
      } else {
        setImgSrc(getProductImage(product.productType, product.nameIdentifier));
      }
    }
  }, [product, isTabletOrDesktop, productType]);

  const handleOnClickCheckButtonCallback = async () => {
    let licenses: IApiCustomerRadarLicense;
    try {
      licenses = await getRadarLicenses();
    } catch (err) {
      return {
        errorMessage: 'This serial number is not valid.',
        isError: true,
      };
    }
    const userEnteredProduct = licenses.radarLicenseInfos.find(
      (license) => license.serial.toString() === editItem.value
    );
    if (!userEnteredProduct) return { errorMessage: '', isError: false };

    const userEnteredPriceGroup = userEnteredProduct.priceGroup;

    const isTM4Device = userEnteredPriceGroup === 'TM4';
    const isB1Device = userEnteredPriceGroup === 'B1Software';
    const isTMiODevice =
      userEnteredPriceGroup === 'TMiOHome' ||
      userEnteredPriceGroup === 'TMiOHomeComplete' ||
      userEnteredPriceGroup === 'TMiOCommercial' ||
      userEnteredPriceGroup === 'TMiOHomeDuo' ||
      userEnteredPriceGroup === 'TMiOHomeCompleteDuo' ||
      userEnteredPriceGroup === 'TMiOCommercialDuo';

    const isTM4Page =
      productType === ProductType.Hardware ||
      productType === ProductType.Software ||
      productType === ProductType.CoursePack;
    const isB1Page = productType === ProductType.B1Software;
    const isTMiOPage =
      productType === ProductType.IOHardware ||
      productType === ProductType.IOSoftwareHome ||
      productType === ProductType.IOSoftwareHomeComplete ||
      productType === ProductType.IOSoftwareCommercial ||
      productType === ProductType.IOSoftwareHomeDuo ||
      productType === ProductType.IOSoftwareHomeCompleteDuo ||
      productType === ProductType.IOSoftwareCommercialDuo ||
      productType === ProductType.CoursePack ||
      productType === ProductType.IOHardwareDuo ||
      productType === ProductType.CoursePackDuo;

    const isHardwarePage =
      productType === ProductType.Hardware ||
      productType === ProductType.IOHardware ||
      productType === ProductType.IOHardwareDuo;

    const physicalProduct = isTM4Device ? 'TM4' : isB1Device ? 'Baseball B1 Software' : 'TMiO';

    if ((isTM4Device && isTM4Page) || (isB1Device && isB1Page) || (isTMiODevice && isTMiOPage)) {
      // user is on the correct page

      const samePriceGroupProducts =
        productType === ProductType.IOHardware
          ? licenses.radarLicenseInfos.filter(
              (product) =>
                product.priceGroup === 'TMiOCommercial' ||
                product.priceGroup === 'TMiOHome' ||
                product.priceGroup === 'TMiOHomeComplete'
            )
          : productType === ProductType.IOHardwareDuo
          ? licenses.radarLicenseInfos.filter(
              (product) =>
                product.priceGroup === 'TMiOCommercialDuo' ||
                product.priceGroup === 'TMiOHomeDuo' ||
                product.priceGroup === 'TMiOHomeCompleteDuo'
            )
          : licenses.radarLicenseInfos.filter(
              (product) => product.priceGroup === userEnteredPriceGroup
            );

      const coursePackCapableProducts = licenses.radarLicenseInfos.filter(
        (product) =>
          product.priceGroup === 'TMiOCommercial' ||
          product.priceGroup === 'TMiOHome' ||
          product.priceGroup === 'TMiOHomeComplete' ||
          product.priceGroup === 'TM4'
      );

      const coursePackDuoCapableProducts = licenses.radarLicenseInfos.filter(
        (product) =>
          product.priceGroup === 'TMiOCommercialDuo' ||
          product.priceGroup === 'TMiOHomeDuo' ||
          product.priceGroup === 'TMiOHomeCompleteDuo'
      );

      const message = isHardwarePage
        ? `We’ve recognized ${samePriceGroupProducts.length} serial numbers for the product ${physicalProduct} in your facility (${licenses?.customerName}). Would you like to add them to the list?`
        : productType === ProductType.CoursePack
        ? `We’ve recognized ${coursePackCapableProducts.length} serial numbers suitable for Pebble Beach Course Pack purchase in your facility (${licenses?.customerName}). Would you like to add them to the list?`
        : productType === ProductType.CoursePackDuo
        ? `We’ve recognized ${coursePackDuoCapableProducts.length} TMiO Duo serial numbers suitable for Pebble Beach Course Pack purchase in your facility (${licenses?.customerName}). Would you like to add them to the list?`
        : `We’ve recognized ${samePriceGroupProducts.length} serial numbers with license ${
            priceGroupDisplay[userEnteredProduct.priceGroup]
          } in your facility (${licenses?.customerName}). Would you like to add them to the list?`;

      if (samePriceGroupProducts.length > 1) {
        addNotification({
          id: uuid(),
          message: message,
          type: NotificationType.FacilityNotification,
        });
      } else {
        await getAllLicenseInfos(false);
      }
    } else {
      // redirect user
      const targetProductType: ProductType = (function () {
        if (isHardwarePage) {
          if (isTM4Device) return ProductType.Hardware;
          if (isB1Device) return ProductType.B1Software;
          return ProductType.IOHardware;
        } else {
          if (isB1Device) return ProductType.B1Software;
          if (userEnteredPriceGroup === 'TMiOHome') return ProductType.IOSoftwareHome;
          if (userEnteredPriceGroup === 'TMiOHomeComplete')
            return ProductType.IOSoftwareHomeComplete;
          if (userEnteredPriceGroup === 'TMiOCommercial') return ProductType.IOSoftwareCommercial;
          if (userEnteredPriceGroup === 'TMiOHomeDuo') return ProductType.IOSoftwareHomeDuo;
          if (userEnteredPriceGroup === 'TMiOHomeCompleteDuo')
            return ProductType.IOSoftwareHomeCompleteDuo;
          if (userEnteredPriceGroup === 'TMiOCommercialDuo')
            return ProductType.IOSoftwareCommercialDuo;
          return ProductType.Software;
        }
      })();
      addNotification({
        id: uuid(),
        message: `The serial number you entered is associated to a different product (${physicalProduct}). Would you like to go to its page?`,
        type: NotificationType.DifferentProduct,
        callback: () =>
          history.push(
            `/product/${
              products.find((product) => product.productType === targetProductType)?.id
            }/${targetProductType}`
          ),
      });
    }

    // Handle automatic redirects for TMiO Software page (single product page handles 6 different product types)
    if (
      productType === ProductType.IOSoftwareHome ||
      productType === ProductType.IOSoftwareHomeComplete ||
      productType === ProductType.IOSoftwareCommercial ||
      productType === ProductType.IOSoftwareHomeDuo ||
      productType === ProductType.IOSoftwareHomeCompleteDuo ||
      productType === ProductType.IOSoftwareCommercialDuo
    ) {
      if (
        listItemsFiltered.length === 0 ||
        userEnteredProduct.priceGroup === listItemsFiltered[0].priceGroup
      ) {
        if (userEnteredPriceGroup === 'TMiOHome')
          history.replace(
            `/product/${
              products.find((product) => product.productType === ProductType.IOSoftwareHome)?.id
            }/${ProductType.IOSoftwareHome}`
          );
        else if (userEnteredPriceGroup === 'TMiOHomeComplete')
          history.replace(
            `/product/${
              products.find((product) => product.productType === ProductType.IOSoftwareHomeComplete)
                ?.id
            }/${ProductType.IOSoftwareHomeComplete}`
          );
        else if (userEnteredPriceGroup === 'TMiOCommercial')
          history.replace(
            `/product/${
              products.find((product) => product.productType === ProductType.IOSoftwareCommercial)
                ?.id
            }/${ProductType.IOSoftwareCommercial}`
          );
        else if (userEnteredPriceGroup === 'TMiOHomeDuo')
          history.replace(
            `/product/${
              products.find((product) => product.productType === ProductType.IOSoftwareHomeDuo)?.id
            }/${ProductType.IOSoftwareHomeDuo}`
          );
        else if (userEnteredPriceGroup === 'TMiOHomeCompleteDuo')
          history.replace(
            `/product/${
              products.find(
                (product) => product.productType === ProductType.IOSoftwareHomeCompleteDuo
              )?.id
            }/${ProductType.IOSoftwareHomeCompleteDuo}`
          );
        else if (userEnteredPriceGroup === 'TMiOCommercialDuo')
          history.replace(
            `/product/${
              products.find(
                (product) => product.productType === ProductType.IOSoftwareCommercialDuo
              )?.id
            }/${ProductType.IOSoftwareCommercialDuo}`
          );
      } else {
        addNotification({
          id: uuid(),
          message: `The serial number you entered (${
            userEnteredProduct.serial
          }) is associated to a different TMiO software license (${
            priceGroupDisplay[userEnteredProduct.priceGroup]
          }). Please add licenses currently listed on the page to the cart, and then enter the serial number again.`,
          type: NotificationType.UnhandledLicense,
        });
      }
    }

    const TMiOPriceGroups = ['TMiOHome', 'TMiOHomeComplete', 'TMiOCommercial'];

    const TMiODuoPriceGroups = ['TMiOHomeDuo', 'TMiOHomeCompleteDuo', 'TMiOCommercialDuo'];

    // Handle automatic redirects for TMiO Hardware page (single product page handles 2 different product types)
    if (productType === ProductType.IOHardware || productType === ProductType.IOHardwareDuo) {
      if (
        listItemsFiltered.length === 0 ||
        (TMiOPriceGroups.includes(userEnteredPriceGroup) &&
          TMiOPriceGroups.includes(listItemsFiltered[0].priceGroup ?? '')) ||
        (TMiODuoPriceGroups.includes(userEnteredPriceGroup) &&
          TMiODuoPriceGroups.includes(listItemsFiltered[0].priceGroup ?? ''))
      ) {
        if (TMiOPriceGroups.includes(userEnteredPriceGroup))
          history.replace(
            `/product/${
              products.find((product) => product.productType === ProductType.IOHardware)?.id
            }/${ProductType.IOHardware}`
          );
        else if (TMiODuoPriceGroups.includes(userEnteredPriceGroup))
          history.replace(
            `/product/${
              products.find((product) => product.productType === ProductType.IOHardwareDuo)?.id
            }/${ProductType.IOHardwareDuo}`
          );
      } else {
        addNotification({
          id: uuid(),
          message: `The serial number you entered (${
            userEnteredProduct.serial
          }) is associated to a different TMiO hardware subscription (${
            priceGroupDisplay[userEnteredProduct.priceGroup]
          }). Please add subscriptions currently listed on the page to the cart, and then enter the serial number again.`,
          type: NotificationType.UnhandledLicense,
        });
      }
    }

    // Handle automatic redirects for Pebble Beach Course Pac page (single product page handles 2 different product types)
    if (productType === ProductType.CoursePack || productType === ProductType.CoursePackDuo) {
      if (
        listItemsFiltered.length === 0 ||
        (TMiOPriceGroups.includes(userEnteredPriceGroup) &&
          TMiOPriceGroups.includes(listItemsFiltered[0].priceGroup ?? '')) ||
        (TMiODuoPriceGroups.includes(userEnteredPriceGroup) &&
          TMiODuoPriceGroups.includes(listItemsFiltered[0].priceGroup ?? ''))
      ) {
        if (TMiOPriceGroups.includes(userEnteredPriceGroup))
          history.replace(
            `/product/${
              products.find((product) => product.productType === ProductType.CoursePack)?.id
            }/${ProductType.CoursePack}`
          );
        else if (TMiODuoPriceGroups.includes(userEnteredPriceGroup))
          history.replace(
            `/product/${
              products.find((product) => product.productType === ProductType.CoursePackDuo)?.id
            }/${ProductType.CoursePackDuo}`
          );
      } else {
        addNotification({
          id: uuid(),
          message: `The serial number you entered (${
            userEnteredProduct.serial
          }) is associated to a different Pebble Beach Course Pack subscription (${
            priceGroupDisplay[userEnteredProduct.priceGroup]
          }). Please add subscriptions currently listed on the page to the cart, and then enter the serial number again.`,
          type: NotificationType.UnhandledLicense,
        });
      }
    }

    return { errorMessage: '', isError: false };
  };

  const handleOnClickRenewCallback = (product: ProductGetResponse) => {
    const filteredProducts = products.filter((p) => p.productType === ProductType.Software);
    if (filteredProducts.length > 0) {
      addToCart(ProductType.Software, filteredProducts[0]);
      addToCart(productType, product);
    } else {
      // todo: show error, page doesn't exist
    }
    setIsSoftwareExpiryModalVisible(false);
  };

  const onClickCheckButtonCallback = async () => {
    const result = await handleOnClickCheckButtonCallback();
    setResult(result);
  };
  const onCloseError = () => {
    setResult({ isError: false, errorMessage: '' });
    clearItem();
  };

  const renderDescription = () => {
    let fullDescriptionJSX: JSX.Element | JSX.Element[];
    let subDescriptionJSX: JSX.Element | JSX.Element[];

    const readMoreJSX: JSX.Element = (
      <span className="read-more" onClick={() => setReadMore(!readMore)}>
        {readMore ? 'Read less' : 'Read more'}
      </span>
    );
    if (product) {
      fullDescriptionJSX = parse(
        getProductDescriptionContent(product.productType, product.nameIdentifier)
      );
      subDescriptionJSX = parse(
        getProductDescriptionContent(product.productType, product.nameIdentifier)?.substr(0, 400)
      );
      if (isTabletOrDesktop) {
        return <>{fullDescriptionJSX}</>;
      } else {
        if (readMore) {
          return (
            <>
              {fullDescriptionJSX}
              {readMoreJSX}
            </>
          );
        } else {
          return (
            <>
              {subDescriptionJSX}
              {readMoreJSX}
            </>
          );
        }
      }
    }
  };

  const oneYear = (product: ProductGetResponse, productType: ProductType) => {
    const year =
      productType === ProductType.CoursePack || productType === ProductType.CoursePackDuo
        ? 'up to 1 year *'
        : '1 year';
    return `${getProductName(productType, product.nameIdentifier)} - ${year} ${currencySymbol}${
      product && product.price[currency]
    }`;
  };

  const threeYears = (product: ProductGetResponse, productType: ProductType) => {
    const years =
      productType === ProductType.CoursePack || productType === ProductType.CoursePackDuo
        ? 'up to 3 years**'
        : '3 years';
    return `${getProductName(productType, product.nameIdentifier)} - ${years} ${currencySymbol}${
      product && calculateFullThreeYearPrice(productType, product.price[currency])
    }`;
  };

  return (
    <Wrapper>
      {product ? (
        <>
          <Container>
            <Row>
              <Col md={7} xsOrder={1}>
                <div className="header-and-sub-header">
                  <h1>
                    {getProductName(
                      productType,
                      product.nameIdentifier,
                      product.productType === ProductType.IOSoftwareHome ||
                        product.productType === ProductType.IOSoftwareHomeComplete ||
                        product.productType === ProductType.IOSoftwareCommercial ||
                        product.productType === ProductType.IOSoftwareHomeDuo ||
                        product.productType === ProductType.IOSoftwareHomeCompleteDuo ||
                        product.productType === ProductType.IOSoftwareCommercialDuo ||
                        product.productType === ProductType.IOHardware ||
                        product.productType === ProductType.IOHardwareDuo ||
                        product.productType === ProductType.CoursePack ||
                        product.productType === ProductType.CoursePackDuo
                    )}
                  </h1>
                  <p>
                    from {currencySymbol}
                    {product.productType === ProductType.IOSoftwareHome ||
                    product.productType === ProductType.IOSoftwareHomeComplete ||
                    product.productType === ProductType.IOSoftwareCommercial ||
                    product.productType === ProductType.IOSoftwareHomeDuo ||
                    product.productType === ProductType.IOSoftwareHomeCompleteDuo ||
                    product.productType === ProductType.IOSoftwareCommercialDuo
                      ? products.find(
                          (product) => product.productType === ProductType.IOSoftwareHome
                        )?.price[currency as Currency]
                      : product.productType === ProductType.IOHardware ||
                        product.productType === ProductType.IOHardwareDuo
                      ? products.find((product) => product.productType === ProductType.IOHardware)
                          ?.price[currency as Currency]
                      : product.productType === ProductType.CoursePack ||
                        product.productType === ProductType.CoursePackDuo
                      ? products.find((product) => product.productType === ProductType.CoursePack)
                          ?.price[currency as Currency]
                      : product?.price[currency as Currency]}
                  </p>
                </div>
              </Col>
              <Col className="info-text" md={5} xsOrder={3}>
                <p>
                  In order to purchase this subscription you need to submit your serial number/s
                  here (numbers only):
                </p>
              </Col>
              <Col md={6} xsOrder={2}>
                <div className="image-section">
                  {productType === ProductType.CoursePack ||
                  productType === ProductType.CoursePackDuo ? (
                    <ImgCarousel images={courseImages} />
                  ) : (
                    <img src={imgSrc} alt={getProductName(productType, product.nameIdentifier)} />
                  )}
                  <h2 className="description-header">
                    {getProductDescriptionHeader(product.productType, product.nameIdentifier)}
                  </h2>
                  <div className="description-content">{renderDescription()}</div>
                </div>
              </Col>
              <Col md={1} xsOrder={2}></Col>
              <Col md={5} xsOrder={4}>
                <ListItems
                  productType={productType}
                  onClickCheckButtonCallback={onClickCheckButtonCallback}
                  onClickTrialWarning={() => setIsTrialDisclaimerModalVisible(true)}
                  onClickSubscriptionInformation={(item) => {
                    setSelectedItem(item);
                    setIsSubscriptionInformationModalVisible(true);
                  }}
                  product={product}
                  error={result.errorMessage}
                  onCloseError={onCloseError}
                />
                {/* <p className="info-text">Select an option:</p> */}
                {product && listItemsFiltered.some((x) => x.isMapped && !x.isTrial) && (
                  <RadioGroup
                    aria-label="yearDuration"
                    name="yearDuration"
                    value={yearDuration}
                    onChange={(e, value) => setYearDuration(parseInt(value))}
                  >
                    <FormControlLabel
                      value={1}
                      className="mui-form-control-label-root"
                      control={<Radio />}
                      labelPlacement="start"
                      label={oneYear(product, productType)}
                    />
                    {/* <FormControlLabel
                      value={3}
                      className="mui-form-control-label-root"
                      control={<Radio />}
                      labelPlacement="start"
                      label={threeYears(product, productType)}
                    /> */}
                  </RadioGroup>
                )}
                {listItemsFiltered.some((x) => x.isMapped && !x.isTrial) && (
                  <TotalPrice productType={productType} product={product} />
                )}
                <Button
                  className="add-cart-button"
                  label="Add to cart"
                  onClick={() => {
                    addToCart(productType, product, listItemsFiltered);
                  }}
                  outlook="solid"
                  intent="primary"
                  margin="0"
                  width="100%"
                  height="48px"
                  isDisabled={!listItemsFiltered.some((x) => x.isMapped && !x.isTrial)}
                />
                {(productType === ProductType.CoursePack ||
                  productType === ProductType.CoursePackDuo) && <SpecialDiscounts />}
              </Col>
            </Row>
          </Container>

          <DisclaimerModal
            visible={isSoftwareExpiryModalVisible}
            onDismissCallback={() => setIsSoftwareExpiryModalVisible(false)}
            // onClickRenewCallBack={() => handleOnClickRenewCallback(product)}
          />
          <CourseDisclaimerModal
            visible={isCourseDisclaimerModalVisible}
            onDismissCallback={() => setIsCourseDisclaimerModalVisible(false)}
            disclaimer={t(`${product?.nameIdentifier?.toLowerCase()}_course_disclaimer_modal`)}
          />
          <CourseDisclaimerModal
            visible={isTrialDisclaimerModalVisible}
            onDismissCallback={() => setIsTrialDisclaimerModalVisible(false)}
            disclaimer={t(`trial_disclaimer_modal`)}
          />
          {selectedItem && (
            <SubscriptionInformationModal
              visible={isSubscriptionInformationModalVisible}
              onDismissCallback={() => setIsSubscriptionInformationModalVisible(false)}
              item={selectedItem}
            />
          )}
          <Notification />
        </>
      ) : (
        // todo: handle if no product is found
        <div></div>
      )}
    </Wrapper>
  );
});
