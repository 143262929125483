import React, { FC } from 'react';
import styled from 'styled-components';
import { ProductType } from 'api';
import { observer } from 'mobx-react-lite';
import { useGolfShopStore } from 'stores/hooks';

const Wrapper = styled.li`
  display: flex;
  font-size: 15px;

  h4 {
    font-weight: normal;
    margin-bottom: 7px;
  }

  img {
    width: 133px;
    height: 80px;
    border-radius: 4px;
    box-shadow: 0px 0px 20px rgba(0, 0, 0, 0.14);
    margin-right: 26px;
  }

  .bold {
    font-weight: 600;
  }

  .price {
    text-align: right;
  }

  div {
    border-bottom: 1px solid #e0e0e0;
    width: 100%;
  }
`;

interface EachOrderProps {
  subtotal: number;
  quantity: number;
  productType: ProductType;
  productIdentifier: string;
}

export const EachOrder: FC<EachOrderProps> = observer(
  ({ subtotal, quantity, productType, productIdentifier }) => {
    const { currencySymbol, getProductImage, getProductName } = useGolfShopStore();

    return (
      <Wrapper>
        <img
          src={getProductImage(productType, productIdentifier)}
          alt={getProductName(productType, productIdentifier)}
        />
        <div>
          <h4>{getProductName(productType, productIdentifier)}</h4>
          <p>
            Qty: <span className="bold">{quantity}</span>
          </p>
          <p className="price">{`${currencySymbol}${subtotal.toFixed(2)}`}</p>
        </div>
      </Wrapper>
    );
  }
);
