import { useGolfShopStore, useNotificationStore } from 'stores/hooks';

import { CustomSnackbar } from '../Shared/SnackBar/CustomSnackbar';
import React from 'react';
import { observer } from 'mobx-react-lite';
import { useHistory } from 'react-router-dom';

export const Notification: React.FC = observer(() => {
  const { getAllLicenseInfos, clearListItems } = useGolfShopStore();
  const history = useHistory();

  const { notifications, removeNotificationById } = useNotificationStore();

  const handleOnClickCartCallback = (index: string) => {
    removeNotificationById(index);
    clearListItems();
    history.push('/cart');
  };

  const handleOnClickRedirectCallback = (
    index: string,
    withCallback: boolean,
    callback?: () => void
  ) => {
    removeNotificationById(index);
    if (withCallback) callback?.();
  };

  const handleOnClickCallback = async (index: string, getAll: boolean) => {
    removeNotificationById(index);
    await getAllLicenseInfos(getAll);
  };

  return (
    <>
      {notifications.map((notification, index) => {
        return (
          <CustomSnackbar
            key={index}
            notificationType={notification.type}
            onClose={() => removeNotificationById(notification.id)}
            message={notification.message}
            onClickCartCallback={() => handleOnClickCartCallback(notification.id)}
            onClickRedirectCallback={(withCallback: boolean) =>
              handleOnClickRedirectCallback(notification.id, withCallback, notification.callback)
            }
            onClickCallback={(getAll: boolean) => handleOnClickCallback(notification.id, getAll)}
          />
        );
      })}
    </>
  );
});
