import { StripeError } from '@stripe/stripe-js';
import { translate } from 'models/translate';

export const StripeErrorCodes = [
  'amount_too_large',
  'amount_too_small',
  'balance_insufficient',
  'card_declined',
  'country_unsupported',
  'email_invalid',
  'expired_card',
  'incorrect_address',
  'incorrect_cvc',
  'incorrect_number',
  'incomplete_number',
  'incomplete_cvc',
  'incomplete_expiry',
  'incorrect_zip',
  'invalid_card_type',
  'invalid_charge_amount',
  'invalid_cvc',
  'invalid_expiry_month',
  'invalid_expiry_month_past',
  'invalid_expiry_year',
  'invalid_expiry_year_past',
  'invalid_number',
  'postal_code_invalid',
  'processing_error',
  'state_unsupported',
];

export const stripeErrors = (error: StripeError) => {
  const t = translate('stripe');
  const { code } = error;

  if (code && StripeErrorCodes.indexOf(code) !== -1) {
    return t(code);
  }
  return t('processing_error');
};
