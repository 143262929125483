import './ListItem.scss';

import { CloseIcon, TrashIcon } from 'icons';
import { ProductGetResponse, ProductType } from 'api';
import React, { FC, useEffect, useState } from 'react';

import { Button } from '@trackman/web-shared-components';
import { IListItem } from '../../../../models/IListItem';
import { ListItemDisplay } from '../ListItemDisplay/ListItemDisplay';
import { ListItemInput } from '../ListItemInput/ListItemInput';
import { observer } from 'mobx-react-lite';

interface IProps {
  index: number;
  onClick?: (index: number) => void;
  onCloseError: () => void;
  onChange?: (value: string, index: number) => void;
  error: string;
  item: IListItem;
  productType: ProductType;
  product?: ProductGetResponse;
  isRemovable?: boolean;
  isDisabled?: boolean;
  onClickTrialWarning?: () => void;
  onClickSubscriptionInformation?: () => void;
}

export const ListItem: FC<IProps> = observer(
  ({
    index,
    onClick,
    onCloseError,
    onChange,
    error,
    item,
    productType,
    product,
    isRemovable = true,
    isDisabled,
    onClickTrialWarning,
    onClickSubscriptionInformation,
  }) => {
    const [hasValue, setHasValue] = useState(false);

    const handleOnClick = (e: any) => {
      e.stopPropagation();
      !isDisabled && onClick && onClick(index);
    };

    const handleOnChange = (value: string, index: number) => {
      setHasValue(!!value?.trim());
      onChange && onChange(value, index);
    };

    useEffect(() => {
      setHasValue(!!item.value?.trim());
    }, [item]);

    return (
      <div className={'list-item' + (item.isTrial ? ' trial' : '')}>
        {item.isMapped ? (
          <>
            <ListItemDisplay
              productType={productType}
              item={item}
              product={product}
              onClickTrialWarning={() => onClickTrialWarning && onClickTrialWarning()}
              onClickSubscriptionInformation={() =>
                onClickSubscriptionInformation && onClickSubscriptionInformation()
              }
            />
            <div
              className={'delete-icon' + (!isDisabled ? '' : ' disabled')}
              onClick={handleOnClick}
            >
              {isRemovable && <TrashIcon />}
            </div>
          </>
        ) : (
          <>
            <ListItemInput
              index={index}
              value={item.value}
              onChange={handleOnChange}
              error={error}
              isDisabled={isDisabled}
            />
            {error ? (
              <div
                className={'array-icon' + (!isDisabled && hasValue ? '' : ' disabled')}
                onClick={onCloseError}
              >
                <CloseIcon />
              </div>
            ) : (
              <div className="add-button">
                <Button
                  label="ADD"
                  onClick={handleOnClick}
                  intent="secondary"
                  margin="10px"
                  width="73px"
                  disabled={isDisabled || !hasValue}
                />
              </div>
            )}
          </>
        )}
      </div>
    );
  }
);
