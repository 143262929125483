import { translate } from 'models/translate';
import React from 'react';
import styled from 'styled-components';
import { useMediaQuery } from 'react-responsive';
import { Button } from '@trackman/web-shared-components';
import { images } from 'images';
import { useHistory } from 'react-router-dom';

const Wrapper = styled.div`
  display: flex;
  margin-bottom: 9px;
  border: 1px solid $secondary-grey;
  border-radius: 4px;
  align-items: center;
  font-family: Lato;
  justify-content: center;
  flex-direction: column;
  position: relative;

  .page-not-found-title {
    font-family: Oswald;
    font-size: 30px;
    font-weight: 500;
    text-transform: uppercase;
    padding-bottom: 25px;
  }
  .page-not-found-text {
    font-size: 14px;
    font-weight: normal;
    font-size: 15px;
  }
  .notfound-buttons {
    display: flex;
    margin-top: 100px;
    font-family: Lato;
    justify-content: space-between;
    flex-direction: row;
  }
`;

export const NotFound = () => {
  const t = translate('products');
  const isTabletOrDesktop = useMediaQuery({ query: '(min-width: 576px)' });
  const image = isTabletOrDesktop ? images.notFoundImage : images.notFoundImageMobile;
  const history = useHistory();

  return (
    <Wrapper>
      <div>
        <div className="page-not-found-title">{t('page_not_found_title')}</div>
        <div className="page-not-found-text">{t('page_not_found_text')}</div>
        <img className="banner-img" src={image} alt="Not found img" />
      </div>
      <div className="notfound-buttons">
        <Button label="GO HOME" onClick={() => history.push('/')} intent="primary" />
        <Button
          label="GO TO PREVIOUS PAGE"
          onClick={() => history.goBack()}
          intent="tertiary"
          width="200px"
        />
      </div>
    </Wrapper>
  );
};
