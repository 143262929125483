import React, { FC } from 'react';
import './NavBar.scss';
import { MobileMenu } from './components/MobileMenu/MobileMenu';
import { NavLink } from 'react-router-dom';
import { useGolfShopStore } from 'stores/hooks';
import { LogoIcon, MenuIcon, CartIcon } from 'icons';
import { CurrencyDropDown } from './components/DropDowns/CurrencyDropDown';
import { observer } from 'mobx-react-lite';
import useCurrency from './components/useCurrency';

export const NavBar: FC = observer(() => {
  const { cartCount, isMobileMenuExpanded, setIsMobileMenuExpanded } = useGolfShopStore();

  const [isCurrencyChangeable] = useCurrency();

  return (
    <>
      <div className="nav-bar">
        <div className="left-side">
          <NavLink
            className="menu-item home hidden-on-mobile"
            activeClassName="active"
            exact
            to="/"
          >
            Home
          </NavLink>
          <NavLink
            className="menu-item home hidden-on-mobile"
            activeClassName="active"
            exact
            to="/terms"
          >
            {'Terms & conditions'}
          </NavLink>

          <div
            className="burger-menu"
            onClick={() => setIsMobileMenuExpanded(!isMobileMenuExpanded)}
          >
            <MenuIcon />
          </div>
        </div>
        <div className="logo-container">
          <NavLink
            className="menu-item home hidden-on-mobile"
            activeClassName="active"
            exact
            to="/"
          >
            <div className="logo">
              <LogoIcon />
            </div>
          </NavLink>
        </div>
        <div className="right-side">
          <div className="localization hidden-on-mobile">
            {/* <LanguageDropDown /> */}
            {isCurrencyChangeable && <CurrencyDropDown />}
          </div>
          <NavLink className="menu-item cart" to="/cart">
            <span className="cart-text hidden-on-mobile">Cart</span>
            <span className="cart-icon">
              <CartIcon />
            </span>
            {cartCount > 0 && <span className="cart-count">{cartCount}</span>}
          </NavLink>
        </div>
      </div>
      <MobileMenu />
    </>
  );
});
