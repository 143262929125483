import { IApiCourseLicense, IApiRadarLicenseInfo } from 'api';
import { ICoursePack, IListItem } from '../models/IListItem';

//to Change Names only
//TODO: either change the model in the backend or in frontend to get rid of this map
const mapCourseLicenses = (courseLicenses: IApiCourseLicense[]) =>
  courseLicenses.map(
    ({
      expireDate: expiration,
      isCoursePackActive: isActive,
      productIdentifier,
      priceAndDate,
      renewSoftwareSubscriptionOneYearPriceAndDate,
      renewSoftwareSubscriptionThreeYearPriceAndDate,
    }) =>
      ({
        expiration,
        isActive,
        productIdentifier,
        priceAndDate,
        renewSoftwareSubscriptionOneYearPriceAndDate,
        renewSoftwareSubscriptionThreeYearPriceAndDate,
      } as ICoursePack)
  );

export const mapLicenseInfos = (
  listItems: IListItem[],
  licenseInfos: IApiRadarLicenseInfo[],
  mapAll: boolean
): IListItem[] => {
  const listItemSerials = listItems.map((x) => x.serial);
  const values = listItems.map((x) => (!x.isMapped ? x.value : '')).filter((x) => x);

  let newListItems: (IListItem | undefined)[] = [];
  if (mapAll) {
    newListItems = licenseInfos.map(
      ({
        serial,
        courseLicenses,
        hardware,
        software,
        b1Software,
        ioHardware,
        ioSoftwareHome,
        ioSoftwareHomeComplete,
        ioSoftwareCommercial,
        ioSoftwareHomeDuo,
        ioSoftwareHomeCompleteDuo,
        ioSoftwareCommercialDuo,
        ioHardwareDuo,
        courseLicensesDuo,
        status,
        licenseType,
        priceGroup,
      }) => {
        if (!listItemSerials.includes(serial)) {
          const returnObj = {
            serial,
            licenseType,
            priceGroup,
            isMapped: true,
            isTrial: status === 3,
            isSerialUserEntered: values.includes(serial.toString()),
          };
          if (priceGroup === 'B1Software') {
            // for B1 we support only software purchases
            returnObj['b1Software'] = b1Software;
          }
          if (priceGroup === 'TMiOHome') {
            returnObj['ioSoftwareHome'] = ioSoftwareHome;
            returnObj['ioHardware'] = ioHardware;
            returnObj['coursePacks'] = mapCourseLicenses(courseLicenses);
          }
          if (priceGroup === 'TMiOHomeComplete') {
            returnObj['ioSoftwareHomeComplete'] = ioSoftwareHomeComplete;
            returnObj['ioHardware'] = ioHardware;
            returnObj['coursePacks'] = mapCourseLicenses(courseLicenses);
          }
          if (priceGroup === 'TMiOCommercial') {
            returnObj['ioSoftwareCommercial'] = ioSoftwareCommercial;
            returnObj['ioHardware'] = ioHardware;
            returnObj['coursePacks'] = mapCourseLicenses(courseLicenses);
          }
          if (priceGroup === 'TMiOHomeDuo') {
            returnObj['ioSoftwareHomeDuo'] = ioSoftwareHomeDuo;
            returnObj['ioHardwareDuo'] = ioHardwareDuo;
            returnObj['coursePacksDuo'] = mapCourseLicenses(courseLicensesDuo);
          }
          if (priceGroup === 'TMiOHomeCompleteDuo') {
            returnObj['ioSoftwareHomeCompleteDuo'] = ioSoftwareHomeCompleteDuo;
            returnObj['ioHardwareDuo'] = ioHardwareDuo;
            returnObj['coursePacksDuo'] = mapCourseLicenses(courseLicensesDuo);
          }
          if (priceGroup === 'TMiOCommercialDuo') {
            returnObj['ioSoftwareCommercialDuo'] = ioSoftwareCommercialDuo;
            returnObj['ioHardwareDuo'] = ioHardwareDuo;
            returnObj['coursePacksDuo'] = mapCourseLicenses(courseLicensesDuo);
          }
          if (priceGroup === 'TM4') {
            returnObj['hardware'] = hardware;
            returnObj['software'] = software;
            returnObj['coursePacks'] = mapCourseLicenses(courseLicenses);
          }
          return returnObj;
        }
      }
    );
  } else {
    newListItems = licenseInfos.map(
      ({
        serial,
        courseLicenses,
        hardware,
        software,
        b1Software,
        ioHardware,
        ioSoftwareHome,
        ioSoftwareHomeComplete,
        ioSoftwareCommercial,
        ioSoftwareHomeDuo,
        ioSoftwareHomeCompleteDuo,
        ioSoftwareCommercialDuo,
        ioHardwareDuo,
        courseLicensesDuo,
        status,
        licenseType,
        priceGroup,
      }) => {
        if (!listItemSerials.includes(serial) && values.includes(serial.toString())) {
          const returnObj = {
            value: '',
            serial: serial,
            licenseType,
            priceGroup,
            isMapped: true,
            isTrial: status === 3,
            isSerialUserEntered: values.includes(serial.toString()),
          };
          if (priceGroup === 'B1Software') {
            // for B1 we support only software purchases
            returnObj['b1Software'] = b1Software;
          }
          if (priceGroup === 'TMiOHome') {
            returnObj['ioSoftwareHome'] = ioSoftwareHome;
            returnObj['ioHardware'] = ioHardware;
            returnObj['coursePacks'] = mapCourseLicenses(courseLicenses);
          }
          if (priceGroup === 'TMiOHomeComplete') {
            returnObj['ioSoftwareHomeComplete'] = ioSoftwareHomeComplete;
            returnObj['ioHardware'] = ioHardware;
            returnObj['coursePacks'] = mapCourseLicenses(courseLicenses);
          }
          if (priceGroup === 'TMiOCommercial') {
            returnObj['ioSoftwareCommercial'] = ioSoftwareCommercial;
            returnObj['ioHardware'] = ioHardware;
            returnObj['coursePacks'] = mapCourseLicenses(courseLicenses);
          }
          if (priceGroup === 'TMiOHomeDuo') {
            returnObj['ioSoftwareHomeDuo'] = ioSoftwareHomeDuo;
            returnObj['ioHardwareDuo'] = ioHardwareDuo;
            returnObj['coursePacksDuo'] = mapCourseLicenses(courseLicensesDuo);
          }
          if (priceGroup === 'TMiOHomeCompleteDuo') {
            returnObj['ioSoftwareHomeCompleteDuo'] = ioSoftwareHomeCompleteDuo;
            returnObj['ioHardwareDuo'] = ioHardwareDuo;
            returnObj['coursePacksDuo'] = mapCourseLicenses(courseLicensesDuo);
          }
          if (priceGroup === 'TMiOCommercialDuo') {
            returnObj['ioSoftwareCommercialDuo'] = ioSoftwareCommercialDuo;
            returnObj['ioHardwareDuo'] = ioHardwareDuo;
            returnObj['coursePacksDuo'] = mapCourseLicenses(courseLicensesDuo);
          }
          if (priceGroup === 'TM4') {
            returnObj['hardware'] = hardware;
            returnObj['software'] = software;
            returnObj['coursePacks'] = mapCourseLicenses(courseLicenses);
          }
          return returnObj;
        }
      }
    );
  }
  newListItems.forEach((newListItem) => {
    if (newListItem) {
      listItems.push(newListItem);
    }
  });
  // remove non mapped items from list
  listItems = listItems.filter((x) => x.isMapped);

  return listItems;
};
