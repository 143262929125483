import React, { FC } from 'react';
import './ListItemInput.scss';

interface IProps {
  onChange: (value: string, index: number) => void;
  error: string;
  index: number;
  value?: string;
  isDisabled?: boolean;
}

export const ListItemInput: FC<IProps> = ({
  index,
  value = '',
  error,
  isDisabled,
  onChange: onChange,
}) => {
  const handleOnChange = (value: string, index: number) => {
    const hasMatch = /^\d*$/.test(value.trim());
    hasMatch && onChange && onChange(value, index);
  };

  return (
    <div className="list-item-input">
      {error ? (
        <>
          <div className="error-value">{value}</div>
          <div className="error-message">{error}</div>
        </>
      ) : (
        <input
          disabled={isDisabled}
          type="text"
          maxLength={10}
          placeholder="Enter serial number"
          value={value}
          onChange={(e) => handleOnChange(e.target.value, index)}
        />
      )}
    </div>
  );
};
