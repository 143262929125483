import { RootStore } from './RootStore';
import { observable, action } from 'mobx';
import { AlertType } from 'models/Alert';

interface AlertMessage {
  text?: string;
  type?: AlertType;
  lifetimeInSec?: number;
}

export class AlertStore {
  constructor(rootStore: RootStore) {
    this.rootStore = rootStore;
  }

  rootStore: RootStore;
  messages: Array<AlertMessage> = [];
  @observable activeMessage: AlertMessage = {};
  @observable type: AlertType = AlertType.SUCCESS;
  @observable isShown = false;

  private showAlert = (args: AlertMessage) => {
    this.activeMessage = args;
    this.messages.shift();
    this.isShown = true;
    setTimeout(() => {
      this.isShown = false;
      if (this.messages?.[0]?.text) {
        this.activeMessage = args;
        this.showAlert(this.messages[0]);
      }
    }, this.activeMessage.lifetimeInSec);
  };

  @action hideAlert = () => {
    this.isShown = false;
  };

  @action pushAlert = (args: AlertMessage) => {
    this.messages.push({
      text: args.text,
      type: args.type,
      lifetimeInSec: (args.lifetimeInSec || 4) * 1000,
    });

    if (!this.isShown) {
      this.showAlert(this.messages[0]);
    }
  };
}
