import React, { useState, FC, useEffect } from 'react';
import { observer } from 'mobx-react-lite';
import { CheckoutForm } from './CheckoutForm';
import { OrderSummary } from './OrderSummary';
import styled from 'styled-components';
import { Container, Row, Col } from 'styled-bootstrap-grid';
import { useGolfShopStore } from 'stores/hooks';
import { CheckoutOrderDetails, CardDetails, BillingDetails } from './models';
import { useHistory } from 'react-router-dom';
import { OrderConfirmation } from './OrderConfirmation';
import { Currency } from 'models/Currency';
import { LoadingPlaceholder } from '@trackman/react-ui/components/LoadingPlaceholder';

const Wrapper = styled.div`
  h2 {
    font-size: 22px;
    text-transform: uppercase;
    padding-bottom: 33px;
  }
`;

export const Checkout: FC = observer(() => {
  const { cartGroupedByProductType, isLoading } = useGolfShopStore();
  const history = useHistory();
  const [orderDetails, setOrderDetails] = useState<CheckoutOrderDetails>({
    taxPercent: 0,
    taxAmount: 0,
    totalPrice: 0,
    totalPriceExcludingTax: 0,
    orderItems: [],
  });
  const [billingDetails, setBillingDetails] = useState<BillingDetails>({
    firstName: '',
    lastName: '',
    address: '',
    postCode: '',
    city: '',
    countryCode: '',
    phone: '',
    email: '',
  });
  const [cardDetails, setCardDetails] = useState({
    lastDigits: 0,
    brand: '',
    expiry: '',
  });
  const [orderNumber, setOrderNumber] = useState('');
  const [orderDate, setOrderDate] = useState('');
  const [currency, setCurrency] = useState<Currency>(Currency.EUR);

  const onOrderConfirmation = (args: {
    billingDetails: BillingDetails;
    cardDetails: CardDetails;
    orderDate: string;
    orderNumber: string;
    currency: Currency;
  }) => {
    setBillingDetails(args.billingDetails);
    setCardDetails(args.cardDetails);
    setOrderNumber(args.orderNumber);
    setOrderDate(args.orderDate);
    setCurrency(args.currency);
  };

  useEffect(() => {
    if (cartGroupedByProductType.length) {
      setOrderDetails({
        taxPercent: 0,
        taxAmount: 0,
        totalPrice: cartGroupedByProductType.reduce(
          (total, current) => total + current.subtotal,
          0
        ),
        totalPriceExcludingTax: cartGroupedByProductType.reduce(
          (total, current) => total + current.subtotal,
          0
        ),
        orderItems: cartGroupedByProductType.map((item) => ({
          price: item.subtotal,
          qty: item.items.length,
          productType: item.productType,
          productIdentifier: item.items?.[0].productIdentifier ?? '',
        })),
      });
    }
  }, [cartGroupedByProductType]);

  if (orderNumber) {
    return (
      <OrderConfirmation
        orderDetails={orderDetails}
        orderNumber={orderNumber}
        billingDetails={billingDetails}
        cardDetails={cardDetails}
        orderDate={orderDate}
        currency={currency}
      />
    );
  }

  if (cartGroupedByProductType.length === 0) {
    history.push('/');
    return null;
  }

  return (
    <Wrapper>
      <Container>
        {
          <Row>
            <Col smOrder={1} sm={6}>
              <h2>Order summary</h2>
              {isLoading ? (
                <LoadingPlaceholder />
              ) : (
                <OrderSummary
                  orderDetails={orderDetails}
                  countryCode={billingDetails.countryCode}
                />
              )}
            </Col>
            <Col smOrder={0} sm={6}>
              <h2>Checkout</h2>
              <CheckoutForm
                onBillingInfoChange={setBillingDetails}
                onOrderChange={setOrderDetails}
                onOrderConfirmation={onOrderConfirmation}
              />
            </Col>
          </Row>
        }
      </Container>
    </Wrapper>
  );
});
