import { Col, Container, Row } from 'styled-bootstrap-grid';
import React, { FC } from 'react';

import { History } from 'history';
import { Product } from './components/Product/Product';
import { ProductType } from 'api';
import { images } from 'images';
import { observer } from 'mobx-react-lite';
import styled from 'styled-components';
import { useGolfShopStore } from 'stores/hooks';

const Wrapper = styled.div`
  margin-top: -64px;
  .banner-img {
    width: 100%;
  }
`;

interface IProps {
  history: History;
}

// Order of tiles in homepage
const orderedProductTypes = [
  ProductType.IOHardware,
  ProductType.IOSoftwareHome,
  ProductType.Hardware,
  ProductType.Software,
  ProductType.CoursePack,
] as const;

export const ProductsOverview: FC<IProps> = observer(({ history }) => {
  const { products, currency, getProductImage, getProductName } = useGolfShopStore();

  return (
    <Wrapper>
      <Container fluid>
        <Row>
          <img className="banner-img" src={images.heroImage} alt="golfer carousel img" />
        </Row>
        <Row>
          <Container>
            <Row>
              {orderedProductTypes.map((productType, index) => {
                const product = products.find((product) => product.productType === productType);
                if (!product) return <></>;
                return (
                  <Col key={index} md={6} lg={4}>
                    <Product
                      onClickCallback={() =>
                        history.push('product/' + product.id + '/' + product.productType)
                      }
                      productName={getProductName(
                        product.productType,
                        product.nameIdentifier,
                        product.productType === ProductType.IOSoftwareHome
                      )}
                      productImgSrc={getProductImage(product.productType, product.nameIdentifier)}
                      productPrice={product.price[currency]}
                    />
                  </Col>
                );
              })}
            </Row>
          </Container>
        </Row>
      </Container>
    </Wrapper>
  );
});
