const getWindowOrigin = () => {
  if (process.env.NODE_ENV === 'development') {
    return 'https://localhost:' + 55926; // TODO: REACT_API_DEV_PORT
  } else if (process.env.NODE_ENV === 'production') {
    if (!window.location.origin) {
      (window.location as any).origin =
        window.location.protocol +
        '//' +
        window.location.hostname +
        (window.location.port ? ':' + window.location.port : '');
    }
  }

  return window.location.origin;
};

export const ConfigRuntime = {
  backendUrl: getWindowOrigin(),
};
