import {
  CheckoutPostBody,
  CheckoutPostResponse,
  IApiCustomerRadarLicense,
  ProductGetResponse,
  ProductType,
  Region,
  checkoutApi,
  configsApi,
  euCountriesApi,
  productsApi,
  radarLicenseApi,
} from 'api';
import { action, computed, observable } from 'mobx';
import {
  getCoursePackByProductId,
  getDiscount,
  getExpiration,
  getPrice,
  getTotalDiscountPrice,
  getTotalPriceAfterDiscount,
  mapLicenseInfos,
} from 'utils';

import { Currency } from 'models/Currency';
import { IListItem } from 'models/IListItem';
import { LocalStorageStore } from 'models/LocalStorageStore';
import { NotificationType } from 'models/NotficationType';
import { RootStore } from './RootStore';
import { currencies } from 'data/currencies';
import { translate } from 'models/translate';
import { v1 as uuid } from 'uuid';

export class GolfShopStore {
  constructor(rootStore: RootStore) {
    this.rootStore = rootStore;
    this.t = translate('products');
    this.asyncInit();
  }

  t: (x: string, option?: string) => string;
  cdnUri = '';
  rootStore: RootStore;
  @observable region: Region = Region.EU;
  @observable isSoftwareExpiryModalVisible = false;
  @observable isLoading = false;
  @observable products: ProductGetResponse[] = [];
  @observable customerRadarLicense: IApiCustomerRadarLicense = {} as IApiCustomerRadarLicense;
  @observable isMobileMenuExpanded = false;
  @observable cart: IListItem[] = [];
  @observable listItems: IListItem[] = [];
  @observable editItem: IListItem = {
    value: '',
    serial: undefined,
    coursePacks: undefined,
    hardware: undefined,
    software: undefined,
    b1Software: undefined,
    isMapped: false,
  };
  @observable currency: Currency = Currency.EUR; //TODO: what is the difference between the currency that comes from backend and the one we set in frontend?
  @observable euCountryCodes: string[] = [];
  @observable stripePublicKey = '';
  @observable yearDuration = 1;
  @observable isInitialized = false;

  asyncInit = async (): Promise<void> => {
    const [config, euCountryCodes] = await Promise.all([configsApi.get(), euCountriesApi.get()]);
    this.euCountryCodes = euCountryCodes;
    this.currency = config.currency;
    this.region = config.region;
    this.cdnUri = config.cdnUri;
    this.stripePublicKey = config.stripePublicKey;
    this.isInitialized = true;
    this.getAllFromLocalStorage();
  };

  getCoursePackResources = (productIdentifier: string) => {
    const pi = productIdentifier?.toLowerCase();
    return [
      {
        src: this.getImageUri(this.t(`course_pack_image_1`, pi)),
        legend: this.t(`${pi}_legend_1`),
      },
      {
        src: this.getImageUri(this.t(`course_pack_image_2`, pi)),
        legend: this.t(`${pi}_legend_2`),
      },
      {
        src: this.getImageUri(this.t(`course_pack_image_3`, pi)),
        legend: this.t(`${pi}_legend_3`),
      },
      {
        src: this.getImageUri(this.t(`course_pack_image_4`, pi)),
        legend: this.t(`${pi}_legend_4`),
      },
      {
        src: this.getImageUri(this.t(`course_pack_image_5`, pi)),
        legend: this.t(`${pi}_legend_5`),
      },
      {
        src: this.getImageUri(this.t(`course_pack_image_6`, pi)),
        legend: this.t(`${pi}_legend_6`),
      },
    ];
  };

  getProductImage = (productType: ProductType, productIdentifier: string) => {
    const pi = productIdentifier?.toLowerCase();
    switch (productType) {
      case ProductType.Hardware:
        return this.getImageUri(this.t('hardware_subscription_image'));
      case ProductType.Software:
        return this.getImageUri(this.t('software_subscription_image'));
      case ProductType.CoursePack:
      case ProductType.CoursePackDuo:
        return this.getImageUri(this.t(`course_pack_image_1`, pi));
      case ProductType.B1Software:
        return this.getImageUri(this.t('b1software_subscription_image'));
      case ProductType.IOSoftwareHome:
      case ProductType.IOSoftwareHomeComplete:
      case ProductType.IOSoftwareCommercial:
      case ProductType.IOSoftwareHomeDuo:
      case ProductType.IOSoftwareHomeCompleteDuo:
      case ProductType.IOSoftwareCommercialDuo:
        return this.getImageUri(this.t('iosoftware_subscription_image'));
      case ProductType.IOHardware:
      case ProductType.IOHardwareDuo:
        return this.getImageUri(this.t('iohardware_subscription_image'));
      default:
        return this.getImageUri(pi);
    }
  };

  getProductName = (
    productType: ProductType,
    productIdentifier: string,
    isUniversalName?: boolean
  ) => {
    const pi = productIdentifier?.toLowerCase();
    if (isUniversalName) {
      if (
        productType === ProductType.IOSoftwareHome ||
        productType === ProductType.IOSoftwareHomeComplete ||
        productType === ProductType.IOSoftwareCommercial ||
        productType === ProductType.IOSoftwareHomeDuo ||
        productType === ProductType.IOSoftwareHomeCompleteDuo ||
        productType === ProductType.IOSoftwareCommercialDuo
      )
        return this.t('iosoftware_universal_name');
      if (productType === ProductType.IOHardwareDuo) return this.t('iohardware_universal_name');
      if (productType === ProductType.CoursePackDuo) return this.t('pebblebeach_universal_name');
    }
    switch (productType) {
      case ProductType.Hardware:
        return this.t('hardware');
      case ProductType.Software:
        return this.t('software');
      case ProductType.IOHardware:
        return this.t('iohardware');
      case ProductType.IOSoftwareHome:
        return this.t('iosoftware_home');
      case ProductType.IOSoftwareHomeComplete:
        return this.t('iosoftware_home_complete');
      case ProductType.IOSoftwareCommercial:
        return this.t('iosoftware_commercial');
      case ProductType.IOSoftwareHomeDuo:
        return this.t('iosoftware_home_duo');
      case ProductType.IOSoftwareHomeCompleteDuo:
        return this.t('iosoftware_home_complete_duo');
      case ProductType.IOSoftwareCommercialDuo:
        return this.t('iosoftware_commercial_duo');
      case ProductType.B1Software:
        return this.t('b1software');
      case ProductType.IOHardwareDuo:
        return this.t('iohardwareDuo');
      default:
        return this.t(pi);
    }
  };

  getProductDescriptionContent = (productType: ProductType, productIdentifier: string) => {
    switch (productType) {
      case ProductType.Hardware:
        return this.t('hardware_product_description_content');
      case ProductType.Software:
        return this.t('software_product_description_content');
      case ProductType.IOSoftwareHome:
      case ProductType.IOSoftwareHomeComplete:
      case ProductType.IOSoftwareCommercial:
      case ProductType.IOSoftwareHomeDuo:
      case ProductType.IOSoftwareHomeCompleteDuo:
      case ProductType.IOSoftwareCommercialDuo:
        return this.t('iosoftware_product_description_content');
      case ProductType.IOHardware:
      case ProductType.IOHardwareDuo:
        return this.t('iohardware_product_description_content');
      case ProductType.B1Software:
        return this.t('b1software_product_description_content');
      default:
        return this.t(`${productIdentifier.toLowerCase()}_product_description_content`);
    }
  };

  getProductDescriptionHeader = (productType: ProductType, productIdentifier: string) => {
    switch (productType) {
      case ProductType.Hardware:
        return this.t('hardware_product_description_header');
      case ProductType.Software:
        return this.t('software_product_description_header');
      case ProductType.IOSoftwareHome:
      case ProductType.IOSoftwareHomeComplete:
      case ProductType.IOSoftwareCommercial:
      case ProductType.IOSoftwareHomeDuo:
      case ProductType.IOSoftwareHomeCompleteDuo:
      case ProductType.IOSoftwareCommercialDuo:
        return this.t('iosoftware_product_description_header');
      case ProductType.IOHardware:
      case ProductType.IOHardwareDuo:
        return this.t('iohardware_product_description_header');
      case ProductType.B1Software:
        return this.t('b1software_product_description_header');
      default:
        return this.t(`${productIdentifier.toLowerCase()}_product_description_header`);
    }
  };

  getImageUri = (image: string) => {
    return `${this.cdnUri}/${image}`;
  };

  @action
  setCurrency = (currency: Currency) => {
    this.currency = currency;
  };

  @action
  setIsLoading = (isLoading: boolean) => {
    this.isLoading = isLoading;
  };

  //TODO: use currencySymbol in other places where getCurrencySymbol is used
  @computed
  get currencySymbol() {
    return currencies.find((c) => c.code === this.currency)?.symbol || '';
  }

  @computed
  get dateFormat() {
    return this.region && this.region === Region.US ? 'MM/DD/YYYY' : 'DD/MM/YYYY';
  }

  @computed
  get canUserChangeCurrency() {
    return this.region && this.region === Region.EU;
  }

  @computed
  get cartCount() {
    const softwareCount = this.cart.filter((el) => el.software).length;
    const hardwareCount = this.cart.filter((el) => el.hardware).length;
    const coursePackCount = this.cart.filter((el) => el.coursePacks).length;
    const b1softwareCount = this.cart.filter((el) => el.b1Software).length;
    const ioHardwareCount = this.cart.filter((el) => el.ioHardware).length;
    const ioSoftwareHomeCount = this.cart.filter((el) => el.ioSoftwareHome).length;
    const ioSoftwareHomeCompleteCount = this.cart.filter((el) => el.ioSoftwareHomeComplete).length;
    const ioSoftwareCommercialCount = this.cart.filter((el) => el.ioSoftwareCommercial).length;
    const ioSoftwareHomeDuoCount = this.cart.filter((el) => el.ioSoftwareHomeDuo).length;
    const ioSoftwareHomeCompleteDuoCount = this.cart.filter(
      (el) => el.ioSoftwareHomeCompleteDuo
    ).length;
    const ioSoftwareCommercialDuoCount = this.cart.filter(
      (el) => el.ioSoftwareCommercialDuo
    ).length;
    const ioHardwareDuoCount = this.cart.filter((el) => el.ioHardwareDuo).length;
    const coursePackDuoCount = this.cart.filter((el) => el.coursePacksDuo).length;
    return (
      softwareCount +
      hardwareCount +
      coursePackCount +
      b1softwareCount +
      ioHardwareCount +
      ioSoftwareHomeCount +
      ioSoftwareHomeCompleteCount +
      ioSoftwareCommercialCount +
      ioSoftwareHomeDuoCount +
      ioSoftwareHomeCompleteDuoCount +
      ioSoftwareCommercialDuoCount +
      ioHardwareDuoCount +
      coursePackDuoCount
    );
  }

  @computed
  get cartGroupedByProductType() {
    const items = [
      {
        productType: ProductType.Software,
        items: this.cart
          .filter((el) => el.software)
          .map((el) => ({
            serial: el.serial || 0,
            productIdentifier: 'software',
            price:
              getPrice({
                productType: ProductType.Software,
                item: el.software,
                currency: this.currency,
                yearDuration: el.software?.yearDuration,
              }) || 0,
            expiration:
              getExpiration({
                productType: ProductType.Software,
                item: el.software,
                yearDuration: el.software?.yearDuration,
              }) || '',
          })),
      },
      {
        productType: ProductType.Hardware,
        items: this.cart
          .filter((el) => el.hardware)
          .map((el) => ({
            serial: el.serial || 0,
            productIdentifier: 'hardware',
            price:
              getPrice({
                productType: ProductType.Hardware,
                item: el.hardware,
                currency: this.currency,
                yearDuration: el.hardware?.yearDuration,
              }) || 0,
            expiration:
              getExpiration({
                productType: ProductType.Hardware,
                item: el.hardware,
                yearDuration: el.hardware?.yearDuration,
              }) || '',
          })),
      },
      {
        productType: ProductType.CoursePack,
        items: this.cart
          .filter((el) => el.coursePacks)
          .map((el) => ({
            productIdentifier: el.coursePacks?.[0]?.productIdentifier,
            serial: el.serial || 0,
            price:
              getPrice({
                productType: ProductType.CoursePack,
                item: el.coursePacks?.[0],
                currency: this.currency,
                softwareYearDuration: el.software?.yearDuration,
                yearDuration: el.coursePacks?.[0].yearDuration,
              }) || 0,
            expiration:
              getExpiration({
                productType: ProductType.CoursePack,
                item: el.coursePacks?.[0],
                softwareYearDuration: el.software?.yearDuration,
                yearDuration: el.coursePacks?.[0].yearDuration,
              }) || '',
          })),
      },
      {
        productType: ProductType.B1Software,
        items: this.cart
          .filter((el) => el.b1Software)
          .map((el) => ({
            serial: el.serial || 0,
            productIdentifier: 'b1software',
            price:
              getPrice({
                productType: ProductType.B1Software,
                item: el.b1Software,
                currency: this.currency,
                yearDuration: el.b1Software?.yearDuration,
              }) || 0,
            expiration:
              getExpiration({
                productType: ProductType.B1Software,
                item: el.b1Software,
                yearDuration: el.b1Software?.yearDuration,
              }) || '',
          })),
      },
      {
        productType: ProductType.IOHardware,
        items: this.cart
          .filter((el) => el.ioHardware)
          .map((el) => ({
            serial: el.serial || 0,
            productIdentifier: 'ioHardware',
            price:
              getPrice({
                productType: ProductType.IOHardware,
                item: el.ioHardware,
                currency: this.currency,
                yearDuration: el.ioHardware?.yearDuration,
              }) || 0,
            expiration:
              getExpiration({
                productType: ProductType.IOHardware,
                item: el.ioHardware,
                yearDuration: el.ioHardware?.yearDuration,
              }) || '',
          })),
      },
      {
        productType: ProductType.IOSoftwareHome,
        items: this.cart
          .filter((el) => el.ioSoftwareHome)
          .map((el) => ({
            serial: el.serial || 0,
            productIdentifier: 'ioSoftwareHome',
            price:
              getPrice({
                productType: ProductType.IOSoftwareHome,
                item: el.ioSoftwareHome,
                currency: this.currency,
                yearDuration: el.ioSoftwareHome?.yearDuration,
              }) || 0,
            expiration:
              getExpiration({
                productType: ProductType.IOSoftwareHome,
                item: el.ioSoftwareHome,
                yearDuration: el.ioSoftwareHome?.yearDuration,
              }) || '',
          })),
      },
      {
        productType: ProductType.IOSoftwareHomeComplete,
        items: this.cart
          .filter((el) => el.ioSoftwareHomeComplete)
          .map((el) => ({
            serial: el.serial || 0,
            productIdentifier: 'ioSoftwareHomeComplete',
            price:
              getPrice({
                productType: ProductType.IOSoftwareHomeComplete,
                item: el.ioSoftwareHomeComplete,
                currency: this.currency,
                yearDuration: el.ioSoftwareHomeComplete?.yearDuration,
              }) || 0,
            expiration:
              getExpiration({
                productType: ProductType.IOSoftwareHomeComplete,
                item: el.ioSoftwareHomeComplete,
                yearDuration: el.ioSoftwareHomeComplete?.yearDuration,
              }) || '',
          })),
      },
      {
        productType: ProductType.IOSoftwareCommercial,
        items: this.cart
          .filter((el) => el.ioSoftwareCommercial)
          .map((el) => ({
            serial: el.serial || 0,
            productIdentifier: 'ioSoftwareCommercial',
            price:
              getPrice({
                productType: ProductType.IOSoftwareCommercial,
                item: el.ioSoftwareCommercial,
                currency: this.currency,
                yearDuration: el.ioSoftwareCommercial?.yearDuration,
              }) || 0,
            expiration:
              getExpiration({
                productType: ProductType.IOSoftwareCommercial,
                item: el.ioSoftwareCommercial,
                yearDuration: el.ioSoftwareCommercial?.yearDuration,
              }) || '',
          })),
      },
      {
        productType: ProductType.IOSoftwareHomeDuo,
        items: this.cart
          .filter((el) => el.ioSoftwareHomeDuo)
          .map((el) => ({
            serial: el.serial || 0,
            productIdentifier: 'ioSoftwareHomeDuo',
            price:
              getPrice({
                productType: ProductType.IOSoftwareHomeDuo,
                item: el.ioSoftwareHomeDuo,
                currency: this.currency,
                yearDuration: el.ioSoftwareHomeDuo?.yearDuration,
              }) || 0,
            expiration:
              getExpiration({
                productType: ProductType.IOSoftwareHomeDuo,
                item: el.ioSoftwareHomeDuo,
                yearDuration: el.ioSoftwareHomeDuo?.yearDuration,
              }) || '',
          })),
      },
      {
        productType: ProductType.IOSoftwareHomeCompleteDuo,
        items: this.cart
          .filter((el) => el.ioSoftwareHomeCompleteDuo)
          .map((el) => ({
            serial: el.serial || 0,
            productIdentifier: 'ioSoftwareHomeCompleteDuo',
            price:
              getPrice({
                productType: ProductType.IOSoftwareHomeCompleteDuo,
                item: el.ioSoftwareHomeCompleteDuo,
                currency: this.currency,
                yearDuration: el.ioSoftwareHomeCompleteDuo?.yearDuration,
              }) || 0,
            expiration:
              getExpiration({
                productType: ProductType.IOSoftwareHomeCompleteDuo,
                item: el.ioSoftwareHomeCompleteDuo,
                yearDuration: el.ioSoftwareHomeCompleteDuo?.yearDuration,
              }) || '',
          })),
      },
      {
        productType: ProductType.IOSoftwareCommercialDuo,
        items: this.cart
          .filter((el) => el.ioSoftwareCommercialDuo)
          .map((el) => ({
            serial: el.serial || 0,
            productIdentifier: 'ioSoftwareCommercialDuo',
            price:
              getPrice({
                productType: ProductType.IOSoftwareCommercialDuo,
                item: el.ioSoftwareCommercialDuo,
                currency: this.currency,
                yearDuration: el.ioSoftwareCommercialDuo?.yearDuration,
              }) || 0,
            expiration:
              getExpiration({
                productType: ProductType.IOSoftwareCommercialDuo,
                item: el.ioSoftwareCommercialDuo,
                yearDuration: el.ioSoftwareCommercialDuo?.yearDuration,
              }) || '',
          })),
      },
      {
        productType: ProductType.CoursePackDuo,
        items: this.cart
          .filter((el) => el.coursePacksDuo)
          .map((el) => ({
            productIdentifier: el.coursePacksDuo?.[0]?.productIdentifier,
            serial: el.serial || 0,
            price:
              getPrice({
                productType: ProductType.CoursePackDuo,
                item: el.coursePacksDuo?.[0],
                currency: this.currency,
                softwareYearDuration:
                  el?.ioSoftwareHomeDuo?.yearDuration ??
                  el?.ioSoftwareHomeCompleteDuo?.yearDuration ??
                  el?.ioSoftwareCommercialDuo?.yearDuration,
                yearDuration: el.coursePacksDuo?.[0].yearDuration,
              }) || 0,
            expiration:
              getExpiration({
                productType: ProductType.CoursePackDuo,
                item: el.coursePacksDuo?.[0],
                softwareYearDuration:
                  el?.ioSoftwareHomeDuo?.yearDuration ??
                  el?.ioSoftwareHomeCompleteDuo?.yearDuration ??
                  el?.ioSoftwareCommercialDuo?.yearDuration,
                yearDuration: el.coursePacksDuo?.[0].yearDuration,
              }) || '',
          })),
      },
      {
        productType: ProductType.IOHardwareDuo,
        items: this.cart
          .filter((el) => el.ioHardwareDuo)
          .map((el) => ({
            serial: el.serial || 0,
            productIdentifier: 'ioHardwareDuo',
            price:
              getPrice({
                productType: ProductType.IOHardwareDuo,
                item: el.ioHardwareDuo,
                currency: this.currency,
                yearDuration: el.ioHardwareDuo?.yearDuration,
              }) || 0,
            expiration:
              getExpiration({
                productType: ProductType.IOHardwareDuo,
                item: el.ioHardwareDuo,
                yearDuration: el.ioHardwareDuo?.yearDuration,
              }) || '',
          })),
      },
    ].filter((el) => el.items.length);

    // For Duo purchases we count every second item as a full unit for discount calculation
    const coursePackFullUnitsCount =
      items
        .filter((item) => item.productType === ProductType.CoursePack)
        .map((item) => item.items)
        .flat().length +
      Math.floor(
        items
          .filter((item) => item.productType === ProductType.CoursePackDuo)
          .map((item) => item.items)
          .flat().length / 2
      );

    return items.map((el) => {
      const subtotal = el.items.map((i) => i.price).reduce((total, current) => total + current, 0);
      const isCoursePack =
        el.productType === ProductType.CoursePack || el.productType === ProductType.CoursePackDuo;
      return {
        ...el,
        subtotal: isCoursePack
          ? getTotalPriceAfterDiscount(subtotal, coursePackFullUnitsCount)
          : subtotal,
        discount: getTotalDiscountPrice(
          subtotal,
          isCoursePack ? coursePackFullUnitsCount : el.items.length
        ),
        discountPercentage: getDiscount(isCoursePack ? coursePackFullUnitsCount : el.items.length),
      };
    });
  }

  @action
  setYearDuration = (yearDuration: number): void => {
    this.yearDuration = yearDuration;
  };

  @action setIsSoftwareExpiryModalVisible = (visible: boolean): void => {
    this.isSoftwareExpiryModalVisible = visible;
  };

  @action clearItem = (): void => {
    this.editItem = {
      value: '',
      serial: undefined,
      coursePacks: undefined,
      hardware: undefined,
      b1Software: undefined,
      software: undefined,
      isMapped: false,
    };
  };

  @action clearListItems = (): void => {
    this.listItems = [];
  };

  @action removeListItem = (item: IListItem): void => {
    const array = [...this.listItems];
    array.splice(this.listItems.indexOf(item), 1);
    this.listItems = array;
  };

  @action updateListItem = (value: string): void => {
    this.editItem.value = value;
  };

  @action setIsMobileMenuExpanded = (expanded: boolean): void => {
    this.isMobileMenuExpanded = expanded;
  };

  @action getAllLicenseInfos = async (getAll: boolean): Promise<void> => {
    this.listItems = mapLicenseInfos(
      [...this.listItems, { ...this.editItem }],
      this.customerRadarLicense.radarLicenseInfos,
      getAll
    );
    this.clearItem();
  };

  @action loadProducts = async (): Promise<void> => {
    const products = await productsApi.getAll();
    if (products) {
      this.products = products.sort((a, b) => a.productType - b.productType);
    }
  };

  @action getRadarLicenses = async (): Promise<IApiCustomerRadarLicense> => {
    this.isLoading = true;
    let licenses = this.customerRadarLicense;
    // get radar licenses

    const serial = this.editItem?.value ?? '';

    // if: this.customerRadarLicense.radarLicenseInfos does not includes serial
    if (
      !this.customerRadarLicense?.radarLicenseInfos?.filter((x) => x.serial === parseInt(serial))
        .length
    ) {
      try {
        licenses = await radarLicenseApi.get(serial);
      } catch (err) {
        this.isLoading = false;
        if (typeof err === 'string') throw new Error(err);
      }
      if (licenses) {
        this.customerRadarLicense = licenses;
        this.updateLocalStorage(LocalStorageStore.RadarLicenses, this.customerRadarLicense);
        this.isLoading = false;
      }
    }
    this.isLoading = false;
    return licenses;
  };

  @action getAllFromLocalStorage = (): void => {
    const expiration = Number(localStorage.getItem(LocalStorageStore.Expiration));

    if (!expiration) {
      this.updateLocalStorage(LocalStorageStore.Expiration, Date.now() + 3600 * 1000 * 24);
    }

    if (expiration && Date.now() > expiration) {
      this.clearStore();
    }

    const radarLicenses = localStorage.getItem(LocalStorageStore.RadarLicenses);
    const cart = localStorage.getItem(LocalStorageStore.Cart);

    if (radarLicenses) {
      this.customerRadarLicense = JSON.parse(radarLicenses);
    }
    if (cart) {
      this.cart = JSON.parse(cart);
    }
  };

  @action updateLocalStorage = (localStorageStore: LocalStorageStore, value: any): void => {
    localStorage.setItem(localStorageStore, JSON.stringify(value));
  };

  setYearDurationOnCartItem = (
    productType: ProductType,
    yearDuration: number,
    product?: ProductGetResponse
  ): void => {
    if (product) {
      if (productType === ProductType.Hardware) {
        this.listItems.forEach((item) => {
          if (item.hardware) {
            item.hardware.yearDuration = yearDuration;
          }
        });
      } else if (productType === ProductType.Software) {
        this.listItems.forEach((item) => {
          if (item.software) {
            item.software.yearDuration = yearDuration;
          }
        });
      } else if (productType === ProductType.CoursePack) {
        this.listItems.forEach((item) => {
          const coursePack = getCoursePackByProductId(
            item,
            this.getCoursePackProductIdByDocumentId(product.id)
          );
          if (coursePack) {
            coursePack.yearDuration = yearDuration;
          }
        });
      } else if (productType === ProductType.B1Software) {
        this.listItems.forEach((item) => {
          if (item.b1Software) {
            item.b1Software.yearDuration = yearDuration;
          }
        });
      } else if (productType === ProductType.IOSoftwareHome) {
        this.listItems.forEach((item) => {
          if (item.ioSoftwareHome) {
            item.ioSoftwareHome.yearDuration = yearDuration;
          }
        });
      } else if (productType === ProductType.IOSoftwareHomeComplete) {
        this.listItems.forEach((item) => {
          if (item.ioSoftwareHomeComplete) {
            item.ioSoftwareHomeComplete.yearDuration = yearDuration;
          }
        });
      } else if (productType === ProductType.IOSoftwareCommercial) {
        this.listItems.forEach((item) => {
          if (item.ioSoftwareCommercial) {
            item.ioSoftwareCommercial.yearDuration = yearDuration;
          }
        });
      } else if (productType === ProductType.IOSoftwareHomeDuo) {
        this.listItems.forEach((item) => {
          if (item.ioSoftwareHomeDuo) {
            item.ioSoftwareHomeDuo.yearDuration = yearDuration;
          }
        });
      } else if (productType === ProductType.IOSoftwareHomeCompleteDuo) {
        this.listItems.forEach((item) => {
          if (item.ioSoftwareHomeCompleteDuo) {
            item.ioSoftwareHomeCompleteDuo.yearDuration = yearDuration;
          }
        });
      } else if (productType === ProductType.IOSoftwareCommercialDuo) {
        this.listItems.forEach((item) => {
          if (item.ioSoftwareCommercialDuo) {
            item.ioSoftwareCommercialDuo.yearDuration = yearDuration;
          }
        });
      } else if (productType === ProductType.IOHardware) {
        this.listItems.forEach((item) => {
          if (item.ioHardware) {
            item.ioHardware.yearDuration = yearDuration;
          }
        });
      } else if (productType === ProductType.CoursePackDuo) {
        this.listItems.forEach((item) => {
          const coursePackDuo = getCoursePackByProductId(
            item,
            this.getCoursePackProductIdByDocumentId(product.id)
          );
          if (coursePackDuo) {
            coursePackDuo.yearDuration = yearDuration;
          }
        });
      } else if (productType === ProductType.IOHardwareDuo) {
        this.listItems.forEach((item) => {
          if (item.ioHardwareDuo) {
            item.ioHardwareDuo.yearDuration = yearDuration;
          }
        });
      }
    }
  };
  @action addToCart = (
    productType: ProductType,
    product: ProductGetResponse,
    filteredListItems?: IListItem[]
  ): void => {
    const allListItems = this.listItems.filter((x) => x.isMapped && !x.isTrial);

    const isSameExpireDate = this.checkIfSoftwareExpirationEqualsCoursePackExpiration(
      allListItems,
      product,
      this.cart
    );

    if (
      (productType === ProductType.CoursePack || productType === ProductType.CoursePackDuo) &&
      isSameExpireDate
    ) {
      this.setIsSoftwareExpiryModalVisible(true);
      return;
    }

    this.setYearDurationOnCartItem(productType, this.yearDuration, product);

    this.cart = this.updateCartItems(
      this.cart,
      filteredListItems ?? allListItems,
      productType,
      product
    );
    this.updateLocalStorage(LocalStorageStore.Cart, this.cart);
    this.clearListItems();
  };

  updateCartItems = (
    cart: IListItem[],
    productPageListItems: IListItem[],
    productType: ProductType,
    product?: ProductGetResponse
  ) => {
    const newCart: IListItem[] = [...cart];
    let addedItems = 0;

    productPageListItems.forEach(
      ({
        coursePacks,
        hardware,
        software,
        serial,
        b1Software,
        ioSoftwareHome,
        ioSoftwareHomeComplete,
        ioSoftwareCommercial,
        ioSoftwareHomeDuo,
        ioSoftwareHomeCompleteDuo,
        ioSoftwareCommercialDuo,
        ioHardware,
        ioHardwareDuo,
        coursePacksDuo,
      }) => {
        const existingIndex = cart.findIndex((c) => c.serial === serial);

        if (productType === ProductType.Hardware) {
          if (existingIndex > -1) {
            newCart[existingIndex].hardware = hardware;
            return;
          } else {
            newCart.push({
              isMapped: true,
              serial,
              hardware,
            });
            addedItems = addedItems + 1;
          }
        }

        if (productType === ProductType.Software) {
          if (existingIndex > -1) {
            newCart[existingIndex].software = software;
          } else {
            newCart.push({
              isMapped: true,
              serial,
              software,
            });
            addedItems = addedItems + 1;
          }
        }

        if (productType === ProductType.CoursePack) {
          if (existingIndex > -1) {
            newCart[existingIndex].coursePacks = coursePacks;
            return;
          } else {
            newCart.push({
              isMapped: true,
              serial,
              coursePacks,
            });
            addedItems = addedItems + 1;
          }
        }

        if (productType === ProductType.B1Software) {
          if (existingIndex > -1) {
            newCart[existingIndex].b1Software = b1Software;
            return;
          } else {
            newCart.push({
              isMapped: true,
              serial,
              b1Software,
            });
            addedItems = addedItems + 1;
          }
        }

        if (productType === ProductType.IOSoftwareHome) {
          if (existingIndex > -1) {
            newCart[existingIndex].ioSoftwareHome = ioSoftwareHome;
            return;
          } else {
            newCart.push({
              isMapped: true,
              serial,
              ioSoftwareHome,
            });
            addedItems = addedItems + 1;
          }
        }

        if (productType === ProductType.IOSoftwareHomeComplete) {
          if (existingIndex > -1) {
            newCart[existingIndex].ioSoftwareHomeComplete = ioSoftwareHomeComplete;
            return;
          } else {
            newCart.push({
              isMapped: true,
              serial,
              ioSoftwareHomeComplete,
            });
            addedItems = addedItems + 1;
          }
        }

        if (productType === ProductType.IOSoftwareCommercial) {
          if (existingIndex > -1) {
            newCart[existingIndex].ioSoftwareCommercial = ioSoftwareCommercial;
            return;
          } else {
            newCart.push({
              isMapped: true,
              serial,
              ioSoftwareCommercial,
            });
            addedItems = addedItems + 1;
          }
        }

        if (productType === ProductType.IOSoftwareHomeDuo) {
          if (existingIndex > -1) {
            newCart[existingIndex].ioSoftwareHomeDuo = ioSoftwareHomeDuo;
            return;
          } else {
            newCart.push({
              isMapped: true,
              serial,
              ioSoftwareHomeDuo,
            });
            addedItems = addedItems + 1;
          }
        }

        if (productType === ProductType.IOSoftwareHomeCompleteDuo) {
          if (existingIndex > -1) {
            newCart[existingIndex].ioSoftwareHomeCompleteDuo = ioSoftwareHomeCompleteDuo;
            return;
          } else {
            newCart.push({
              isMapped: true,
              serial,
              ioSoftwareHomeCompleteDuo,
            });
            addedItems = addedItems + 1;
          }
        }

        if (productType === ProductType.IOSoftwareCommercialDuo) {
          if (existingIndex > -1) {
            newCart[existingIndex].ioSoftwareCommercialDuo = ioSoftwareCommercialDuo;
            return;
          } else {
            newCart.push({
              isMapped: true,
              serial,
              ioSoftwareCommercialDuo,
            });
            addedItems = addedItems + 1;
          }
        }

        if (productType === ProductType.IOHardware) {
          if (existingIndex > -1) {
            newCart[existingIndex].ioHardware = ioHardware;
            return;
          } else {
            newCart.push({
              isMapped: true,
              serial,
              ioHardware,
            });
            addedItems = addedItems + 1;
          }
        }

        if (productType === ProductType.IOHardwareDuo) {
          if (existingIndex > -1) {
            newCart[existingIndex].ioHardwareDuo = ioHardwareDuo;
            return;
          } else {
            newCart.push({
              isMapped: true,
              serial,
              ioHardwareDuo,
            });
            addedItems = addedItems + 1;
          }
        }

        if (productType === ProductType.CoursePackDuo) {
          if (existingIndex > -1) {
            newCart[existingIndex].coursePacksDuo = coursePacksDuo;
            return;
          } else {
            newCart.push({
              isMapped: true,
              serial,
              coursePacksDuo,
            });
            addedItems = addedItems + 1;
          }
        }
      }
    );

    if (product) {
      this.rootStore.notificationStore.addNotification({
        id: 'cart-update',
        message: `Just added: ${this.t(product?.nameIdentifier)} x${productPageListItems.length}`,
        type: NotificationType.CartNotification,
      });
    }
    return newCart;
  };

  // I think this is never used
  addCartItems = (
    productType: ProductType,
    productPageListItems: IListItem[],
    product?: ProductGetResponse
  ) => {
    const newCart: IListItem[] = [];

    productPageListItems.forEach(
      ({
        serial,
        coursePacks,
        hardware,
        software,
        b1Software,
        ioSoftwareHome,
        ioSoftwareHomeComplete,
        ioSoftwareCommercial,
        ioSoftwareHomeDuo,
        ioSoftwareHomeCompleteDuo,
        ioSoftwareCommercialDuo,
        ioHardware,
        ioHardwareDuo,
        coursePacksDuo,
      }) => {
        if (productType === ProductType.Hardware) {
          newCart.push({
            isMapped: true,
            serial,
            hardware,
          });
        }

        if (productType === ProductType.Software) {
          newCart.push({
            isMapped: true,
            serial,
            software,
          });
        }

        if (productType === ProductType.CoursePack) {
          newCart.push({
            isMapped: true,
            serial,
            coursePacks,
          });
        }

        if (productType === ProductType.B1Software) {
          newCart.push({
            isMapped: true,
            serial,
            b1Software,
          });
        }

        if (productType === ProductType.IOSoftwareHome) {
          newCart.push({
            isMapped: true,
            serial,
            ioSoftwareHome,
          });
        }

        if (productType === ProductType.IOSoftwareHomeComplete) {
          newCart.push({
            isMapped: true,
            serial,
            ioSoftwareHomeComplete,
          });
        }

        if (productType === ProductType.IOSoftwareCommercial) {
          newCart.push({
            isMapped: true,
            serial,
            ioSoftwareCommercial,
          });
        }

        if (productType === ProductType.IOSoftwareHomeDuo) {
          newCart.push({
            isMapped: true,
            serial,
            ioSoftwareHomeDuo,
          });
        }

        if (productType === ProductType.IOSoftwareHomeCompleteDuo) {
          newCart.push({
            isMapped: true,
            serial,
            ioSoftwareHomeCompleteDuo,
          });
        }

        if (productType === ProductType.IOSoftwareCommercialDuo) {
          newCart.push({
            isMapped: true,
            serial,
            ioSoftwareCommercialDuo,
          });
        }

        if (productType === ProductType.IOHardware) {
          newCart.push({
            isMapped: true,
            serial,
            ioHardware,
          });
        }

        if (productType === ProductType.IOHardwareDuo) {
          newCart.push({
            isMapped: true,
            serial,
            ioHardwareDuo,
          });
        }

        if (productType === ProductType.CoursePackDuo) {
          newCart.push({
            isMapped: true,
            serial,
            coursePacksDuo,
          });
        }
      }
    );

    this.rootStore.notificationStore.addNotification({
      id: uuid(),
      message: `Just added: ${this.t(product ? product.nameIdentifier : '')} x${newCart?.length}`,
      type: NotificationType.CartNotification,
    });

    return newCart;
  };

  @action deleteCartItem = async (args: { serial: number; productType: ProductType }) => {
    const index = this.cart.findIndex((c) => c.serial === args.serial);

    if (index === -1) {
      return;
    }

    //Check if a coursepack is tied to this software
    if (
      args.productType === ProductType.Software ||
      args.productType === ProductType.IOSoftwareHome ||
      args.productType === ProductType.IOSoftwareHomeComplete ||
      args.productType === ProductType.IOSoftwareCommercial ||
      args.productType === ProductType.IOSoftwareHomeDuo ||
      args.productType === ProductType.IOSoftwareHomeCompleteDuo ||
      args.productType === ProductType.IOSoftwareCommercialDuo
    ) {
      const coursePacks = this.cart[index]?.coursePacks;
      const coursePacksDuo = this.cart[index]?.coursePacksDuo;
      const serial = this.cart[index].serial;
      if (coursePacks && coursePacks.length > 0) {
        let radarLicense = this.customerRadarLicense.radarLicenseInfos.find(
          (info) => info.serial === this.cart[index].serial
        );
        if (!radarLicense && serial) {
          const radarLicenseResult = await radarLicenseApi.get(`${serial}`);
          radarLicense = radarLicenseResult.radarLicenseInfos.find(
            (info) => info.serial === this.cart[index].serial
          );
        }

        let redarLicenseSoftwareKey;
        if (args.productType === ProductType.IOSoftwareHome)
          redarLicenseSoftwareKey = radarLicense?.ioSoftwareHome;
        else if (args.productType === ProductType.IOSoftwareHomeComplete)
          redarLicenseSoftwareKey = radarLicense?.ioSoftwareHomeComplete;
        else if (args.productType === ProductType.IOSoftwareCommercial)
          redarLicenseSoftwareKey = radarLicense?.ioSoftwareCommercial;
        else if (args.productType === ProductType.IOSoftwareHomeDuo)
          redarLicenseSoftwareKey = radarLicense?.ioSoftwareHomeDuo;
        else if (args.productType === ProductType.IOSoftwareHomeCompleteDuo)
          redarLicenseSoftwareKey = radarLicense?.ioSoftwareHomeCompleteDuo;
        else if (args.productType === ProductType.IOSoftwareCommercialDuo)
          redarLicenseSoftwareKey = radarLicense?.ioSoftwareCommercialDuo;
        else redarLicenseSoftwareKey = radarLicense?.software;

        if (
          redarLicenseSoftwareKey?.isActive === false ||
          redarLicenseSoftwareKey?.expiration === coursePacks[0].expiration
        ) {
          this.setIsSoftwareExpiryModalVisible(true);
          return;
        }
      }
      if (coursePacksDuo && coursePacksDuo.length > 0) {
        let radarLicense = this.customerRadarLicense.radarLicenseInfos.find(
          (info) => info.serial === this.cart[index].serial
        );
        if (!radarLicense && serial) {
          const radarLicenseResult = await radarLicenseApi.get(`${serial}`);
          radarLicense = radarLicenseResult.radarLicenseInfos.find(
            (info) => info.serial === this.cart[index].serial
          );
        }

        let redarLicenseSoftwareKey;
        if (args.productType === ProductType.IOSoftwareHome)
          redarLicenseSoftwareKey = radarLicense?.ioSoftwareHome;
        else if (args.productType === ProductType.IOSoftwareHomeComplete)
          redarLicenseSoftwareKey = radarLicense?.ioSoftwareHomeComplete;
        else if (args.productType === ProductType.IOSoftwareCommercial)
          redarLicenseSoftwareKey = radarLicense?.ioSoftwareCommercial;
        else if (args.productType === ProductType.IOSoftwareHomeDuo)
          redarLicenseSoftwareKey = radarLicense?.ioSoftwareHomeDuo;
        else if (args.productType === ProductType.IOSoftwareHomeCompleteDuo)
          redarLicenseSoftwareKey = radarLicense?.ioSoftwareHomeCompleteDuo;
        else if (args.productType === ProductType.IOSoftwareCommercialDuo)
          redarLicenseSoftwareKey = radarLicense?.ioSoftwareCommercialDuo;
        else redarLicenseSoftwareKey = radarLicense?.software;

        if (
          redarLicenseSoftwareKey?.isActive === false ||
          redarLicenseSoftwareKey?.expiration === coursePacksDuo[0].expiration
        ) {
          this.setIsSoftwareExpiryModalVisible(true);
          return;
        }
      }
    }

    switch (args.productType) {
      case ProductType.Software:
        delete this.cart[index].software;
        break;
      case ProductType.Hardware:
        delete this.cart[index].hardware;
        break;
      case ProductType.CoursePack:
        delete this.cart[index].coursePacks;
        break;
      case ProductType.B1Software:
        delete this.cart[index].b1Software;
        break;
      case ProductType.IOSoftwareHome:
        delete this.cart[index].ioSoftwareHome;
        break;
      case ProductType.IOSoftwareHomeComplete:
        delete this.cart[index].ioSoftwareHomeComplete;
        break;
      case ProductType.IOSoftwareCommercial:
        delete this.cart[index].ioSoftwareCommercial;
        break;
      case ProductType.IOSoftwareHomeDuo:
        delete this.cart[index].ioSoftwareHomeDuo;
        break;
      case ProductType.IOSoftwareHomeCompleteDuo:
        delete this.cart[index].ioSoftwareHomeCompleteDuo;
        break;
      case ProductType.IOSoftwareCommercialDuo:
        delete this.cart[index].ioSoftwareCommercialDuo;
        break;
      case ProductType.IOHardware:
        delete this.cart[index].ioHardware;
        break;
      case ProductType.IOHardwareDuo:
        delete this.cart[index].ioHardwareDuo;
        break;
      case ProductType.CoursePackDuo:
        delete this.cart[index].coursePacksDuo;
        break;
    }

    this.updateLocalStorage(LocalStorageStore.Cart, this.cart);
  };

  getCorrespondingCartItemFromListItem = (listItem: IListItem): IListItem | undefined => {
    return this.cart.find((x) => x.serial === listItem.serial);
  };

  checkIfSoftwareExpirationEqualsCoursePackExpiration = (
    listItems: IListItem[],
    product: ProductGetResponse,
    cart: IListItem[]
  ): boolean => {
    // get list of items with an software and coursepack expiration ending the same date
    const items = listItems.filter((x) => {
      let listItemSoftwareKey;
      if (x.priceGroup === 'TMiOHome') listItemSoftwareKey = x?.ioSoftwareHome;
      else if (x.priceGroup === 'TMiOHomeComplete') listItemSoftwareKey = x?.ioSoftwareHomeComplete;
      else if (x.priceGroup === 'TMiOCommercial') listItemSoftwareKey = x?.ioSoftwareCommercial;
      else if (x.priceGroup === 'TMiOHomeDuo') listItemSoftwareKey = x?.ioSoftwareHomeDuo;
      else if (x.priceGroup === 'TMiOHomeCompleteDuo')
        listItemSoftwareKey = x?.ioSoftwareHomeCompleteDuo;
      else if (x.priceGroup === 'TMiOCommercialDuo')
        listItemSoftwareKey = x?.ioSoftwareCommercialDuo;
      else if (x.priceGroup === 'TM4') listItemSoftwareKey = x?.software;
      else return false;

      return (
        listItemSoftwareKey?.isActive === false ||
        listItemSoftwareKey?.expiration ===
          getCoursePackByProductId(x, this.getCoursePackProductIdByDocumentId(product.id))
            ?.expiration
      );
    });
    const serialsToCheck = items.map((x) => x.serial);

    if (items.length > 0) {
      if (cart.length > 0) {
        const isSoftwareInTheCart = cart.filter((cartItem) => {
          const cartItemSoftwareKey =
            cartItem.software ??
            cartItem.ioSoftwareHome ??
            cartItem.ioSoftwareHomeComplete ??
            cartItem.ioSoftwareCommercial ??
            cartItem.ioSoftwareHomeDuo ??
            cartItem.ioSoftwareHomeCompleteDuo ??
            cartItem.ioSoftwareCommercialDuo;

          //Prolonging the software subscription for longer should enable you to buy the course
          return (
            serialsToCheck.includes(cartItem.serial) &&
            cartItemSoftwareKey?.yearDuration &&
            this.yearDuration <= cartItemSoftwareKey?.yearDuration
          );
        });
        // If one or more of the software subscriptions in the list, doesn't have a corresponding new software subscription in the cart
        return items.length !== isSoftwareInTheCart.length;
      } else {
        return true;
      }
    } else {
      return false;
    }
  };

  getCoursePackProductIdByDocumentId = (documentId: string): string | undefined => {
    const product = this.products.find((x) => x.id === documentId);
    return product?.nameIdentifier;
  };

  clearStore = () => {
    localStorage.removeItem(LocalStorageStore.Cart);
    localStorage.removeItem(LocalStorageStore.RadarLicenses);
    localStorage.removeItem(LocalStorageStore.Expiration);
    this.cart = [];
    this.customerRadarLicense = {} as IApiCustomerRadarLicense;
    this.isLoading = false;
  };

  checkout = async (body: CheckoutPostBody): Promise<CheckoutPostResponse> => {
    this.isLoading = true;
    try {
      return await checkoutApi.checkout(body);
    } finally {
      this.isLoading = false;
    }
  };
}
