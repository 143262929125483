import React, { FC } from 'react';
import MuiTooltip from '@material-ui/core/Tooltip';
import { InfoIcon } from 'icons';
import './Tooltip.scss';
import { makeStyles } from '@material-ui/core/styles';
import { Colors } from 'styles/colors';

interface IProps {
  text: string;
}

const useMuiStyles = makeStyles(() => ({
  arrow: {
    color: Colors.GRAY,
  },
  tooltip: {
    backgroundColor: Colors.GRAY,
    textAlign: 'center',
    background: Colors.GRAY,
    borderRadius: '2px',
    padding: '4px 8px',
    top: '-15px',
    maxWidth: '200px',
    fontSize: '11px',
    color: Colors.WHITE,
  },
}));

function BootstrapTooltip(props: any) {
  const classes = useMuiStyles();

  return <MuiTooltip arrow classes={classes} {...props} />;
}

export const Tooltip: FC<IProps> = ({ text }) => {
  return (
    <div className="tooltip">
      <BootstrapTooltip title={<>{text}</>}>
        <span>
          <InfoIcon />
        </span>
      </BootstrapTooltip>
    </div>
  );
};
