import React, { FC } from 'react';
import 'react-responsive-carousel/lib/styles/carousel.min.css';
import { Carousel } from 'react-responsive-carousel';
import './ImgCarousel.scss';

export interface ICarouselImg {
  src: string;
  legend: string;
}

interface IProps {
  images: ICarouselImg[];
}

export const ImgCarousel: FC<IProps> = ({ images }) => {
  return (
    <Carousel renderThumbs={() => []} statusFormatter={() => ''} infiniteLoop={true}>
      {images.map((img, index) => {
        return (
          <div className="img-wrapper" key={index}>
            <img src={img.src} alt={img.legend} />
            <p className="legend">{img.legend}</p>
          </div>
        );
      })}
    </Carousel>
  );
};
