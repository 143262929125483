import { Col, Row } from 'styled-bootstrap-grid';
import React, { FC } from 'react';

import { Colors } from 'styles/colors';
import { ProductType } from 'api';
import { TrashIcon } from 'icons';
import moment from 'moment';
import styled from 'styled-components';
import { useGolfShopStore } from 'stores/hooks';

const Wrapper = styled.div`
  margin-bottom: 40px;
  border-bottom: 1px ${Colors.LIGHT_GRAY} solid;
  padding-bottom: 49px;

  img {
    max-width: 100%;
    border-radius: 4px;
    box-shadow: 0px 0px 20px rgba(0, 0, 0, 0.14);
    margin-bottom: 20px;
  }

  .title {
    margin-bottom: 52px;
    text-transform: uppercase;
    display: flex;
    justify-content: space-between;

    div {
      color: ${Colors.SECONDARY_GRAY};
      font-family: Oswald;
      font-weight: 500;
    }
  }

  h3 {
    font-size: 18px;
  }

  li {
    display: flex;
    justify-content: space-between;
    align-items: center;
    font-size: 15px;
    border-bottom: 1px ${Colors.LIGHT_GRAY} solid;
    padding: 14px 0 14px 0;
  }

  .price-and-trash {
    display: flex;
    align-items: center;
  }

  .subtotal {
    font-size: 18px;
    text-align: right;
    margin-top: 16px;
  }

  .price {
    color: ${Colors.BRAND};
  }

  .items-title {
    display: none;
  }

  .total-discount {
    background: transparent;
    width: 100%;
    height: 36px;
    border-radius: 4px;
    font-size: 15px;
    display: flex;
    justify-content: space-between;
    margin: 10px 0px 10px 0px;
  }

  button {
    border: none;
    background: inherit;
    cursor: pointer;
    color: inherit;
    outline: none;
  }

  @media (max-width: 576px) {
    .items-title {
      display: block;
      border: none;
      padding-bottom: 12px;
    }

    .expiry {
      display: none;
    }
  }
`;

interface ShoppingItemProps {
  productType: ProductType;
  productIdentifier: string;
  subtotal: number;
  discount: number;
  discountPercentage: number;
  items: Array<{
    serial: number;
    price: number;
    expiration: string;
  }>;
}

export const ShoppingItem: FC<ShoppingItemProps> = ({
  productType,
  productIdentifier,
  items,
  subtotal,
  discount,
  discountPercentage,
}) => {
  const { currencySymbol, deleteCartItem, getProductImage, getProductName, dateFormat } =
    useGolfShopStore();

  return (
    <Wrapper>
      <Row>
        <Col sm={4}>
          <div className="image-wrap">
            <img
              src={getProductImage(productType, productIdentifier)}
              alt={getProductName(productType, productIdentifier)}
            />
          </div>
        </Col>
        <Col sm={8}>
          <div className="content">
            <div className="title">
              <h3>{getProductName(productType, productIdentifier)}</h3>
              <div></div>
            </div>
            <ul>
              <li className="items-title">Items:</li>
              {items.map((el, index) => (
                <li key={el.serial}>
                  <div>
                    {`${index + 1}) S/N: ${el.serial}`}
                    <span className="expiry">{` (Upon renewal, new expiry date: ${moment(
                      el.expiration
                    ).format(dateFormat)})`}</span>
                  </div>
                  <div className="price-and-trash">
                    <span className="price">
                      {currencySymbol}
                      {el.price.toFixed(2)}
                    </span>
                    <button onClick={() => deleteCartItem({ serial: el.serial, productType })}>
                      <TrashIcon />
                    </button>
                  </div>
                </li>
              ))}
            </ul>
            <div className="subtotal">
              {discount > 0 && (
                <div className="total-discount">
                  <div>
                    Discount ({discountPercentage.toFixed(2)}
                    %)
                  </div>

                  <div>
                    -{currencySymbol}
                    {discount.toFixed(2)}
                  </div>
                </div>
              )}
              Subtotal:{' '}
              <span>
                {currencySymbol}
                {subtotal.toFixed(2)}
              </span>
            </div>
          </div>
        </Col>
      </Row>
    </Wrapper>
  );
};
