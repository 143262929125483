import { ICoursePack, IListItem } from '../models/IListItem';
export const getCoursePackByProductId = (
  item: IListItem,
  productIdentifier?: string
): ICoursePack | undefined => {
  const filteredList = item.coursePacks?.filter((cp) => cp.productIdentifier === productIdentifier);
  const filteredListDuo = item.coursePacksDuo?.filter(
    (cp) => cp.productIdentifier === productIdentifier
  );
  return filteredList ? filteredList[0] : filteredListDuo ? filteredListDuo[0] : undefined;
};
