import { observable, action } from 'mobx';
import { INotification } from 'models/INotification';
import { RootStore } from './RootStore';

export class NotificationStore {
  constructor(rootStore: RootStore) {
    this.rootStore = rootStore;
  }

  rootStore: RootStore;
  @observable notifications: INotification[] = [];

  @action removeNotificationById = (id: string): void => {
    this.notifications = this.notifications.filter((notification) => notification.id !== id);
  };

  @action removeNotificationByIndex = (index: number): void => {
    const array = [...this.notifications];
    array.splice(index, 1);
    this.notifications = array;
  };

  @action addNotification = (notification: INotification): void => {
    this.notifications.push(notification);
  };
}
