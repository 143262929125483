import { ILicense } from 'models/IListItem';
import moment from 'moment';

export const milliseconds = 1000;
export const minutes = milliseconds * 60;
export const hours = minutes * 60;
export const days = hours * 24;
export const years = days * 365;
export const yearRange = 10;

export const dateTime = (expiration: string | number | Date) => {
  return new Date(expiration).getTime();
};

export const now = () => {
  return dateTime(Date.now());
};

export const daysLeftFromNow = (expiration: string) => {
  return (dateTime(expiration) - now()) / days;
};

export const yearsLeftFromNow = (expiration: string) => {
  return (dateTime(expiration) - now()) / years;
};

export const yearIsInRange = (expiration: string) => {
  const year = yearsLeftFromNow(expiration);
  return year > -yearRange && year < yearRange;
};

export const renderDaysLeftFromNow = (subscription: ILicense) => {
  if (subscription.expiration === null) return 'No Subscription';
  const dlfn = daysLeftFromNow(subscription.expiration);
  const daysLeft = dlfn >= 2 ? 'days left' : 'day left';

  return yearIsInRange(subscription.expiration)
    ? dlfn > 0
      ? `${Number(dlfn).toFixed(0)} ${daysLeft}`
      : 'Expired'
    : 'No Subscription';
};

export const renderSubscriptionExpiration = (
  subscription: ILicense,
  dateFormat: string
): string => {
  if (subscription.expiration === null) return '';
  const dlfn = daysLeftFromNow(subscription.expiration);
  const expiration = dlfn >= 0 ? 'Expires' : 'Expired';

  return yearIsInRange(subscription.expiration)
    ? `(${expiration} ${moment(subscription.expiration).format(dateFormat)})`
    : '';
};
