import React, { FC } from 'react';
import { Modal, Button } from '@trackman/react-ui';
import styled from 'styled-components';

const Wrapper = styled.div`
  .disclaimer-modal {
    .header {
      border-bottom: 1px solid #e0e0e0;
      text-transform: uppercase;
      text-align: center;
    }
    .content {
      all: unset;
      width: 100%;
      box-sizing: border-box;

      .disclaimer-modal-action {
        display: flex;
        justify-content: space-around;
      }

      .disclaimer-modal-body {
        padding: 32px;
        text-align: center;
        .text {
          display: flex;
          justify-content: center;
        }
        .renew {
          margin-top: 32px;
          display: flex;
          justify-content: center;
        }
      }
    }
  }
`;
interface IProps {
  visible: boolean;
  onDismissCallback: () => void;
  onClickRenewCallBack?: () => void;
}

export const DisclaimerModal: FC<IProps> = ({
  visible,
  onDismissCallback,
  onClickRenewCallBack,
}) => {
  return (
    <Wrapper>
      <Modal
        visible={visible}
        onDismissCallback={onDismissCallback}
        width={'fit-content'}
        headline="Disclaimer"
        modalClassName="disclaimer-modal"
      >
        <div className="disclaimer-modal-body">
          <div className="text">
            Course packs cannot be purchased for radars with an expired Software Subscription.
          </div>
          <div className="renew">
            Please renew the software subscriptions or delete them from this list to proceed.
          </div>
        </div>
        {onClickRenewCallBack && (
          <div className="disclaimer-modal-action">
            <Button
              label="Renew software subscriptions"
              onClick={onClickRenewCallBack}
              outlook="solid"
              intent="primary"
              margin="20px 0"
            />
          </div>
        )}
      </Modal>
    </Wrapper>
  );
};
