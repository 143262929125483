import './SpecialDiscounts.scss';

import React, { FC } from 'react';

export const SpecialDiscounts: FC = () => {
  return (
    <div className="special-discounts">
      <div className="footnote">
        <p>* Prices are calculated on remaining days on the Software Subscription.</p>
        {/* <p>
          ** If you purchase a 3 year pack and the Software Subscription runs beyond 2 years, then
          any time period beyond the first two years is free of charge up to 3 years.
        </p> */}
      </div>
      <div className="title">Special discounts</div>
      <div className="row">
        <div className="unit-no"></div>From 2 to 3 units<div className="discount">5% OFF</div>
      </div>
      <div className="row">
        <div className="unit-no"></div>From 4 to 9 units<div className="discount">7,5% OFF</div>
      </div>
      <div className="row">
        <div className="unit-no"></div>From 10 to 19 units<div className="discount">10% OFF</div>
      </div>
      <div className="row">
        <div className="unit-no"></div>From 20 to 29 units<div className="discount">15% OFF</div>
      </div>
      <div className="row">
        <div className="unit-no"></div>From 30 to 49 units<div className="discount">20% OFF</div>
      </div>
      <div className="row">
        <div className="unit-no"></div>From 50 to 99 units<div className="discount">25% OFF</div>
      </div>
      <div className="row">
        <div className="unit-no"></div>100 or more units<div className="discount">30% OFF</div>
      </div>
      <div className="footnote">
        <p>
          * Discount structure applies per “unit”. Trackman iO DUO is considered 1 unit although
          listed as two serial numbers in the cart for technical reasons.
        </p>
      </div>
    </div>
  );
};
