import React, { FC } from 'react';
import { AlertType } from 'models/Alert';
import styled from 'styled-components';
import { observer } from 'mobx-react-lite';
import { useAlertStore } from 'stores/hooks';
import { Colors } from 'styles/colors';
import { CloseIcon } from 'icons';

const Wrapper = styled.div<{ color: string; isShown: boolean }>`
  background-color: white;
  border-left: 8px solid ${({ color }) => color};
  box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19);
  position: fixed;
  top: 90px;
  right: ${({ isShown }) => (isShown ? '-1px' : '-1000px')};
  padding: 15px 60px 15px 25px;
  border-radius: 4px 0 0 4px;
  transition: 0.5s;
  z-index: 10000;
  width: 300px;

  h2 {
    font-family: Oswald;
    font-size: 18px;
    font-weight: 500;
    margin-bottom: 9px;
  }

  p {
    color: ${Colors.SECONDARY_GRAY};
    font-size: 13px;
    line-height: 16px;
  }

  .close {
    position: absolute;
    top: 15px;
    right: 15px;
  }
`;

export const Alert: FC = observer(() => {
  const { activeMessage, isShown, hideAlert } = useAlertStore();
  const title =
    activeMessage.type === AlertType.SUCCESS
      ? 'SUCCESS'
      : activeMessage.type === AlertType.WARNING
      ? 'INFO'
      : 'ERROR';
  const color =
    activeMessage.type === AlertType.SUCCESS
      ? '#009900'
      : activeMessage.type === AlertType.WARNING
      ? '#414141'
      : '#C00219';
  return (
    <Wrapper color={color} isShown={isShown}>
      <div className="close" onClick={hideAlert}>
        <CloseIcon />
      </div>
      <h2>{title}</h2>
      <p>{activeMessage.text}</p>
    </Wrapper>
  );
});
