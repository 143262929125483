export enum Colors {
  GRAY = '#414141',
  WHITE = '#FFF',
  BRAND = '#EC691A',
  SECONDARY_GRAY = '#A0A0A0',
  LIGHT_GRAY = '#E0E0E0',
  BACKGROUND = '#F8F9FA',
  ACCEPT_GREEN = '#009900',
  WARNING = '#EE1B34',
}
