import { axiosInstance } from './base';

export const euCountriesApi = {
  get: async (): Promise<string[]> => {
    const { data, status } = await axiosInstance.get(`/api/v1/Country/eucountrycodes`);
    if (status !== 200) {
      throw new Error('Fetching EU countries failed' + status);
    }
    return data;
  },
};
