//TODO: Replace with i18n

const replaceOption = (text: string, option?: string) => {
  return text.replace('{{option}}', option ?? 'default');
};

const productTranslation = (text: string, option?: string) => {
  switch (text) {
    //Hardware and Software
    case 'hardware':
      return 'TM4 hardware subscription';
    case 'software':
      return 'TM4 software subscription';
    case 'hardware_product_description_content':
      return '<p>Peace of mind…</p><p>While the TrackMan system has proven highly reliable – it is a very sophisticated system including electronics, microwave component, battery, camera, and motors. At some point, your system might experience a hardware failure.</p><p>Some of these components are very expensive to repair. Please consider the impact to your business operations and budget. TrackMan’s Hardware Maintenance Program removes this concern and offers peace of mind.</p><p>Prioritized Repairs service puts your system first in line. It’s free, fast and seamless, making sure you are up and running in no time. Protect your investment with TrackMan’s Hardware Plan.</p>';
    case 'hardware_product_description_header':
      return 'Premium hardware maintenance plan';
    case 'software_product_description_content':
      return '<p>Subscribing to our software solutions keeps you up-to-date with our latest developments. In this fast moving world of technology, we release new software several times per year.</p><p>The software subscriptions gives you access to all the new features and functionalities we frequently release.</p><p>You also get full access to our Tour Swing Library, Virtual Golf courses* and our suite of communication tools, allowing you to upload and share all your work (screencasts, videos, and reports) directly from your iPad or PC.</p><p>* Virtual Pebble Beach Course Pack is available as an optional supplement to the standard TrackMan Virtual Golf course library. <a href="/product/bf897668-b65a-4c83-9c30-ffc0ab4a01bb/3">Read more.</a></p>';
    case 'software_product_description_header':
      return 'Stay up-to-date with our latest developments';

    // IO Hardware and Software
    case 'iohardware':
    case 'ioHardware':
    case 'iohardware_universal_name':
      return 'TMiO hardware subscription';
    case 'iohardwareduo':
    case 'iohardware_duo':
    case 'ioHardwareDuo':
    case 'iohardwareDuo':
      return 'TMiO Duo hardware subscription';
    case 'iosoftware_universal_name':
      return 'TMiO software subscription';
    case 'iosoftware_home':
    case 'ioSoftwareHome':
      return 'TMiO Home software subscription';
    case 'iosoftware_home_complete':
    case 'ioSoftwareHomeComplete':
      return 'TMiO Home Complete software subscription';
    case 'iosoftware_commercial':
    case 'ioSoftwareCommercial':
      return 'TMiO Commercial software subscription';
    case 'iosoftware_home_duo':
    case 'ioSoftwareHomeDuo':
      return 'TMiO Home Duo software subscription';
    case 'iosoftware_home_complete_duo':
    case 'ioSoftwareHomeCompleteDuo':
      return 'TMiO Home Complete Duo software subscription';
    case 'iosoftware_commercial_duo':
    case 'ioSoftwareCommercialDuo':
      return 'TMiO Commercial Duo software subscription';
    case 'iohardware_product_description_content':
      return '<p>Peace of mind…</p><p>While the TrackMan system has proven highly reliable – it is a very sophisticated system including electronics, microwave component, battery, camera, and motors. At some point, your system might experience a hardware failure.</p><p>Some of these components are very expensive to repair. Please consider the impact to your business operations and budget. TrackMan’s Hardware Maintenance Program removes this concern and offers peace of mind.</p><p>Prioritized Repairs service puts your system first in line. It’s free, fast and seamless, making sure you are up and running in no time. Protect your investment with TrackMan’s Hardware Plan.</p>';
    case 'iohardware_product_description_header':
      return 'Premium hardware maintenance plan';
    case 'iosoftware_product_description_content':
      return '<p>Subscribing to our software solutions keeps you up-to-date with our latest developments. In this fast moving world of technology, we release new software several times per year.</p><p>Depending on which software license you subscribe to, you will either get full access to all available features and metrics, or a more concentrated “Home” package tailored to self-practice and entertainment.</p><p>* Virtual Pebble Beach Course Pack is available as an optional supplement to the standard TrackMan Virtual Golf course library. <a href="/product/bf897668-b65a-4c83-9c30-ffc0ab4a01bb/3">Read more.</a></p>';
    case 'iosoftware_product_description_header':
      return 'Stay up-to-date with our latest developments';

    //Baseball B1
    case 'b1software':
      return 'Baseball B1 Software Subscription';
    case 'b1software_product_description_content':
      return '<p>Subscribing to our software keeps you up-to-date with our latest developments. In this fast moving world of technology, we release new software several times per year.</p>';
    case 'b1software_product_description_header':
      return 'Stay up-to-date with our latest developments';

    //Pebble Beach
    case 'pebblebeach':
    case 'pebblebeach_universal_name':
      return 'Pebble Beach Course Pack';
    case 'pebblebeach_duo':
    case 'pebblebeachDuo':
    case 'pebblebeachduo':
      return 'Pebble Beach Course Pack for TMiO Duo';
    case 'pebblebeach_legend_1':
    case 'pebblebeachduo_legend_1':
      return 'Pebble Beach';
    case 'pebblebeach_legend_2':
    case 'pebblebeachduo_legend_2':
      return 'Pebble Beach';
    case 'pebblebeach_legend_3':
    case 'pebblebeachduo_legend_3':
      return 'Pebble Beach';
    case 'pebblebeach_legend_4':
    case 'pebblebeachduo_legend_4':
      return 'Spanish Bay';
    case 'pebblebeach_legend_5':
    case 'pebblebeachduo_legend_5':
      return 'Spyglass Hill';
    case 'pebblebeach_legend_6':
    case 'pebblebeachduo_legend_6':
      return 'The Hay';
    case 'pebblebeach_product_description_content':
    case 'pebblebeachduo_product_description_content':
      return ` 
      <div class="product-description-content"> 
      <p>This course pack includes 4 incredible virtual courses from the famous Monterey Peninsula in California: Pebble Beach Golf Links®, Spyglass Hill® Golf Course, The Links at Spanish Bay™ and The Hay short course. All four courses have been reproduced down to the last inch, and the Trackman in-house course designers have used the very latest technology available to bring you the most realistic and engaging experience playing these iconic courses. The results set a new standard for sim realism and are astonishingly faithful to the actual course, as anybody who has had the good fortune to play the real Pebble Beach Resorts’ courses will immediately realize! It’s virtually the real deal.<p>
      <br/>
      <p>By purchasing the annual license, you are granted full access to play the four courses below in the time period you select in the checkout process.<p>
      <br/>
      <strong>Pebble Beach Golf Links®</strong>
      <p>Unanimously rated the No. 1 Public Course in the Country for the past 17 years, this iconic course deserves its status. Pebble Beach hosted its sixth US Open in 2019, more than any other course over the last 50 years.</p>
      <br/>
      <strong>Spyglass Hill® Golf Course</strong>
      <p>Ranked in the top 10 golf courses in California, this championship course challenges golfers amidst sweeping ocean views, daring dunescapes and the understated natural beauty of the Del Monte Forest.</p>
      <br/>
      <strong>The Links at Spanish Bay™</strong>
      <p>A distinctly Scottish links-style course set against a spectacular beachside backdrop. Rolling fairways flow through and around the gorgeous dunescape that weaves between towering Monterey Pines and the white sand of Spanish Bay Beach.</p>
      <br/>
      <strong>The Hay</strong>
      <p>A fixture at Pebble Beach since 1957, The Hay is a mesmerizing nine-hole short course where golfers can enjoy the pinnacle of the game, regardless of ability. Most of the holes of the par-3 course play between 75 and 100 yards, including an exact replica of the iconic No. 7 at Pebble Beach Golf Links.</p>
      <p><i>The Hay is a registered trademark of Pebble Beach Company.</i></p>
      <br/>
      Note: 
      <p>Virtual Pebble Beach Course Pack is available as an optional supplement to the standard Trackman Virtual Golf course library, which is included in the Trackman software package. Make sure you have a valid Trackman software subscription during your checkout process.</p>
      <br/>
      Requirements:
      <ul>
        <li>Trackman 4 or Trackman iO unit with an active software subscription</li>
        <li>PC with minimum specifications as listed here: (<a target="_blank" rel="noopener noreferrer" href="https://support.trackmangolf.com/hc/en-us/articles/5863861869083-PC-Specification-System-Requirements-For-Trackman-4-Trackman-iO">PC</a>)</li>
        <li>Trackman Performance Studio 9.0 or later. Download here: (<a target="_blank" rel="noopener noreferrer" href="https://support.trackmangolf.com/hc/en-us/sections/5103418134555-Downloads">TPS</a>)</li>
        <li>Internet access to download courses after completion of purchase</li>
      </ul>
      <br/>
      <p>Once you have purchased this license, the courses will automatically download to the TPS connected to the appropriate Trackman 4 / Trackman iO unit (your unit serial number is required in the next step to proceed).</p>
      <br/>
      <p>__________________</p>
      <br/>
      <p>All sales are final and subject to the TrackMan Terms and Conditions (<a target="_blank" rel="noopener noreferrer" href="https://mytrackman.com/system/terms-and-conditions">T&C</a>). Pebble Beach®, Pebble Beach Golf Links®, the course logo, The Lone Cypress™, course designs and their respective underlying distinctive images are trademarks, service marks and trade dress of Pebble Beach Company. Used under license.</p>     
      </div>
      `;
    case 'pebblebeach_product_description_header':
    case 'pebblebeachduo_product_description_header':
      return 'PLAY 4 of THE MOST ICONIC COURSES in NORTH AMERICA';
    case 'pebblebeach_course_disclaimer_modal':
    case 'pebblebeachduo_course_disclaimer_modal':
      return ` 
          <div class="text">
            <strong>PLEASE NOTE: </strong>If you purchase the license now, you will get access to
            <strong> Pebble Beach Golf Links® </strong>, and shortly after that
            <strong> Spyglass Hill® </strong>and<strong> The Links at Spanish Bay™ </strong>
            are also automatically added to your library once they have passed our quality control.
          </div>
          <div class="renew">
            If you want all three courses at the same time, please return to this webshop later.
          </div>
        `;
    case 'trial_disclaimer_modal':
      return ` 
          <div class="text">
              Unfortunately, it is not possible to perform a purchase for this radar because it is using a trial licence.
          </div>
          <div class="renew">
              Please contact your local <a target="_blank" rel="noopener noreferrer" href="https://trackmangolf.com/contact">Sales Representative</a> or <a target="_blank" rel="noopener noreferrer" href="https://trackmangolf.com/support">Trackman suport</a> to solve this issue.
          </div>
            `;
    // Images
    case 'hardware_subscription_image':
      return 'hardware_subscription_image.png';
    case 'software_subscription_image':
      return 'software_subscription_image.png';
    case 'b1software_subscription_image':
      return 'b1software_subscription_image.png';
    case 'iosoftware_subscription_image':
      return 'software_subscription_image_tmio.png';
    case 'iohardware_subscription_image':
      return 'hardware_subscription_image_tmio.png';

    //CoursePack Images
    case 'course_pack_image_1':
      return 'pebblebeach_course_pack_image_1.png';
    case 'course_pack_image_2':
      return 'pebblebeach_course_pack_image_2.png';
    case 'course_pack_image_3':
      return 'pebblebeach_course_pack_image_3.png';
    case 'course_pack_image_4':
      return 'pebblebeach_course_pack_image_4.png';
    case 'course_pack_image_5':
      return 'pebblebeach_course_pack_image_5.png';
    case 'course_pack_image_6':
      return 'pebblebeach_course_pack_image_6.png';

    //Not Found
    case 'page_not_found_title':
      return 'Sorry!';
    case 'page_not_found_text':
      return 'The page you are looking for was not found.';

    default:
      return text;
  }
};

export const stripe: Record<string, string> = {
  amount_too_large:
    'The specified amount is greater than the maximum amount allowed. Use a lower amount and try again.',
  amount_too_small:
    'The specified amount is less than the minimum amount allowed. Use a higher amount and try again.',
  balance_insufficient:
    'The payment could not be completed because the associated account does not have a sufficient balance available.',
  card_declined: 'The card has been declined.',
  country_unsupported:
    'Your platform attempted to create a custom account in a country that is not yet supported. Make sure that users can only sign up in countries supported by custom accounts.',
  email_invalid:
    'The email address is invalid (e.g., not properly formatted). Check that the email address is properly formatted and only includes allowed characters.',
  expired_card: 'The card has expired. Check the expiration date or use a different card.',
  incorrect_address:
    'The card’s address is incorrect. Check the card’s address or use a different card.',
  incorrect_cvc:
    'The card’s security code is incorrect. Check the card’s security code or use a different card.',
  incorrect_number:
    'The card number is incorrect. Check the card’s number or use a different card.',
  incomplete_number: 'The card number is incomplete.',
  incomplete_cvc: 'The cvc number is incomplete.',
  incomplete_expiry: 'The expiry date is incomplete.',
  incorrect_zip:
    'The card’s postal code is incorrect. Check the card’s postal code or use a different card.',
  invalid_card_type:
    'The card provided as an external account is not a debit card. Provide a debit card or use a bank account instead.',
  invalid_charge_amount: 'The specified amount is invalid.',
  invalid_cvc:
    'The card’s security code is invalid. Check the card’s security code or use a different card.',
  invalid_expiry_month:
    'The card’s expiration month is incorrect. Check the expiration date or use a different card.',
  invalid_expiry_month_past: 'The card’s expiration month is in the past.',
  invalid_expiry_year:
    'The card’s expiration year is incorrect. Check the expiration date or use a different card.',
  invalid_expiry_year_past: 'The card’s expiration year is in the past.',
  invalid_number: 'The card number is invalid. Check the card details or use a different card.',
  postal_code_invalid: 'The postal code provided was incorrect.',
  processing_error:
    'An error occurred while processing the card. Check the card details are correct or use a different card.',
  state_unsupported: 'The provided state is not supported.',
};

function stripeTranslation(x: string) {
  return stripe?.[x] || x;
}

export const translate = (ns: string) => {
  let t = (x: string) => x;
  if (ns === 'products') {
    t = (x: string, option?: string) => {
      return productTranslation(x, option);
    };
  }
  if (ns === 'stripe') {
    t = (x: string) => {
      return stripeTranslation(x);
    };
  }
  return t;
};
