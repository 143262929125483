import React, { FC } from 'react';
import styled from 'styled-components';
import { Container, Row, Col } from 'styled-bootstrap-grid';
import { OrderConfirmationCardProps, OrderConfirmationCard } from './OrderConfirmationCard';

const Wrapper = styled.div`
  h1 {
    font-size: 22px;
    text-transform: uppercase;
    padding-bottom: 33px;
  }
`;

export const OrderConfirmation: FC<OrderConfirmationCardProps> = ({
  orderNumber,
  orderDate,
  orderDetails,
  billingDetails,
  cardDetails,
  currency,
}) => {
  return (
    <Wrapper>
      <Container>
        <Row>
          <Col>
            <h1>Order confirmation</h1>
          </Col>
        </Row>
        <OrderConfirmationCard
          orderDetails={orderDetails}
          orderNumber={orderNumber}
          billingDetails={billingDetails}
          cardDetails={cardDetails}
          orderDate={orderDate}
          currency={currency}
        />
      </Container>
    </Wrapper>
  );
};
