import React, { FC } from 'react';
import styled from 'styled-components';

const Wrapper = styled.div`
  .privacy-policy-content {
    width: 100%;
    height: calc(100vh - 300px);
    padding-left: 20px !important;
    background-color: white;
    .policy-iframe {
      width: 100%;
      height: 100%;
      border: none;
    }
  }
`;

export const TermsPage: FC = () => {
  return (
    <Wrapper>
      <div className="privacy-policy-content">
        <iframe
          title="terms"
          className="policy-iframe"
          src="https://mytrackman.com/api/properties/GetPrivacyPolicyHtml"
        ></iframe>
      </div>
    </Wrapper>
  );
};
