import React, { FC } from 'react';
import { Button, Modal } from '@trackman/react-ui';
import styled from 'styled-components';
import { useMediaQuery } from 'react-responsive';
import parse from 'html-react-parser';

const Wrapper = styled.div`
  .course-disclaimer-modal {
    .header {
      display: none;
    }
    .content {
      all: unset;
      box-sizing: border-box;

      .course-disclaimer-modal-action {
        padding: 32px 19px;
        display: flex;
        justify-content: space-around;
      }

      .course-disclaimer-modal-body {
        font-size: 15px;
        line-height: 21px;
        padding: 32px 32px 0px 32px;
        .renew {
          margin-top: 32px;
        }
      }
    }
  }
`;
interface IProps {
  visible: boolean;
  disclaimer: string;
  onDismissCallback: () => void;
}

export const CourseDisclaimerModal: FC<IProps> = ({ visible, disclaimer, onDismissCallback }) => {
  const isTabletOrDesktop = useMediaQuery({ query: '(min-width: 576px)' });

  return (
    <Wrapper>
      <Modal
        visible={visible}
        onDismissCallback={onDismissCallback}
        width={isTabletOrDesktop ? '700px' : 'fit-content'}
        modalClassName="course-disclaimer-modal"
      >
        <div className="course-disclaimer-modal-body">{parse(disclaimer)}</div>
        <div className="course-disclaimer-modal-action">
          <Button label={'OK, GOT IT'} outlook="solid" onClick={onDismissCallback} />
        </div>
      </Modal>
    </Wrapper>
  );
};
