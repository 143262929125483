import React, { FC } from 'react';
import { Modal } from '@trackman/react-ui';
import styled from 'styled-components';
import { IListItem } from 'models/IListItem';
import { useMediaQuery } from 'react-responsive';
import { SubscriptionInformation } from './SubscriptionInformation';

const Wrapper = styled.div`
  .subscription-information-modal {
    .header {
      border-bottom: 1px solid #e0e0e0;
      text-transform: uppercase;
      text-align: left;
    }
    .content {
      all: unset;
      box-sizing: border-box;

      .subscription-information-modal-action {
        padding: 32px 19px;
        display: flex;
        justify-content: space-around;
      }

      .subscription-information-modal-body {
        font-size: 15px;
        line-height: 21px;
        padding: 32px 32px 0px 32px;
        .renew {
          margin-top: 32px;
        }
      }
    }
  }
`;
interface IProps {
  visible: boolean;
  onDismissCallback: () => void;
  item: IListItem;
}

export const SubscriptionInformationModal: FC<IProps> = ({ visible, item, onDismissCallback }) => {
  const isTabletOrDesktop = useMediaQuery({ query: '(min-width: 576px)' });

  return (
    <Wrapper>
      <Modal
        headline="Licenses"
        visible={visible}
        onDismissCallback={onDismissCallback}
        width={isTabletOrDesktop ? '724px' : 'fit-content'}
        modalClassName="subscription-information-modal"
      >
        <div className="subscription-information-modal-body">
          <SubscriptionInformation item={item} />
        </div>
      </Modal>
    </Wrapper>
  );
};
