import { ProductType } from 'api';
import { useGolfShopStore } from 'stores/hooks';
import { useMemo } from 'react';

const useListItemsFilteredByProductType = (productType: ProductType) => {
  const { listItems } = useGolfShopStore();

  return useMemo(() => {
    return listItems.filter((item) => {
      switch (productType) {
        case ProductType.Software:
          return item.software !== undefined;
        case ProductType.Hardware:
          return item.hardware !== undefined;
        case ProductType.CoursePack:
          return item.coursePacks !== undefined;
        case ProductType.B1Software:
          return item.b1Software !== undefined;
        case ProductType.IOSoftwareHome:
          return item.ioSoftwareHome !== undefined;
        case ProductType.IOSoftwareHomeComplete:
          return item.ioSoftwareHomeComplete !== undefined;
        case ProductType.IOSoftwareCommercial:
          return item.ioSoftwareCommercial !== undefined;
        case ProductType.IOSoftwareHomeDuo:
          return item.ioSoftwareHomeDuo !== undefined;
        case ProductType.IOSoftwareHomeCompleteDuo:
          return item.ioSoftwareHomeCompleteDuo !== undefined;
        case ProductType.IOSoftwareCommercialDuo:
          return item.ioSoftwareCommercialDuo !== undefined;
        case ProductType.IOHardware:
          return item.ioHardware !== undefined;
        case ProductType.IOHardwareDuo:
          return item.ioHardwareDuo !== undefined;
        case ProductType.CoursePackDuo:
          return item.coursePacksDuo !== undefined;
        default:
          return false;
      }
    });
  }, [JSON.stringify(listItems), productType]);
};

export default useListItemsFilteredByProductType;
