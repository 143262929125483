import 'mobx-react-lite/batchingForReactDom';
import React from 'react';
import ReactDOM from 'react-dom';
import 'styles/index.scss';
import { App } from './App';
import * as serviceWorker from './serviceWorker';
import { Router } from 'react-router-dom';
import { createBrowserHistory } from 'history';
import { RootStoreContext, RootStore } from 'stores/RootStore';

function reactRender() {
  const history = createBrowserHistory();
  const rootStore = new RootStore();

  ReactDOM.render(
    <Router history={history}>
      <RootStoreContext.Provider value={rootStore}>
        <App />
      </RootStoreContext.Provider>
    </Router>,
    document.getElementById('root')
  );
}

reactRender();

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
