import { GolfShopStore } from './GolfShopStore';
import { NotificationStore } from './NotificationStore';
import { Context, useContext, createContext } from 'react';
import { AlertStore } from './AlertStore';

export class RootStore {
  public golfShopStore: GolfShopStore;
  public notificationStore: NotificationStore;
  public alertStore: AlertStore;

  constructor() {
    this.golfShopStore = new GolfShopStore(this);
    this.notificationStore = new NotificationStore(this);
    this.alertStore = new AlertStore(this);
  }
}

export const RootStoreContext = createContext<RootStore | null>(null) as Context<RootStore>;

export const useRootStore = (): RootStore => {
  return useContext(RootStoreContext);
};
