import React, { FC, FormEvent, useState } from 'react';
import {
  useStripe,
  useElements,
  CardNumberElement,
  CardExpiryElement,
  CardCvcElement,
} from '@stripe/react-stripe-js';
import { StripeCardNumberElement } from '@stripe/stripe-js';
import { Button } from '@trackman/react-ui';
import styled from 'styled-components';
import { observer } from 'mobx-react-lite';
import { useAlertStore, useGolfShopStore } from 'stores/hooks';
import { stripeErrors } from './StripeErrorHandler';
import { AlertType } from 'models/Alert';

const Wrapper = styled.form`
  .pay-now-button {
    margin-top: 12px;
  }

  .payment-input {
    font-size: 16px;
    font-family: Lato;
    padding: 20px;
    background-color: rgba(0, 0, 0, 0.09);
    border-bottom: 1px solid rgba(0, 0, 0, 0.42);
    border-radius: 4px 4px 0 0;
    margin-bottom: 32px;
    transition: 200ms;

    :hover {
      background: rgba(0, 0, 0, 0.12);
    }
  }

  .half-width {
    display: inline-block;
    width: calc(50% - 10.5px);
  }

  .expiry {
    margin-right: 21px;
  }
`;

interface StripePaymentProps {
  clientSecret: string;
  isDisabled: boolean;
  onPaymentSuccess: (...args: any[]) => void;
}

export const StripePayment: FC<StripePaymentProps> = observer(
  ({ clientSecret, isDisabled, onPaymentSuccess }) => {
    const { pushAlert } = useAlertStore();
    const stripe = useStripe();
    const elements = useElements();
    const { isLoading, setIsLoading } = useGolfShopStore();
    const [isCardValid, setIsCardValid] = useState(false);
    const [isExpirationValid, setIsExpirationValid] = useState(false);
    const [isCvcValid, setIsCvcValid] = useState(false);
    const handleSubmit = async (e: FormEvent) => {
      e.preventDefault();
      setIsLoading(true);
      if (!stripe || !elements) {
        return;
      }

      const cardNumberElement = elements.getElement(CardNumberElement) as StripeCardNumberElement;

      // Use your card Element with other Stripe.js APIs
      const { error, paymentMethod } = await stripe.createPaymentMethod({
        type: 'card',
        card: cardNumberElement,
      });

      if (error) {
        setIsLoading(false);
        pushAlert({ text: stripeErrors(error), type: AlertType.ERROR, lifetimeInSec: 10 });
        return;
      }
      const { error: er2, paymentIntent } = await stripe.confirmCardPayment(clientSecret, {
        payment_method: {
          card: cardNumberElement,
        },
      });

      setIsLoading(false);
      if (er2) {
        pushAlert({ text: stripeErrors(er2), type: AlertType.ERROR, lifetimeInSec: 10 });
      } else {
        onPaymentSuccess({
          orderDate: paymentIntent ? new Date(paymentIntent.created * 1000).toDateString() : '',
          currency: paymentIntent?.currency,
          orderNumber: paymentIntent?.id,
          cardDetails: {
            lastDigits: paymentMethod?.card?.last4,
            brand: paymentMethod?.card?.brand,
            expiry: `${paymentMethod?.card?.exp_month}/${paymentMethod?.card?.exp_year}`,
          },
        });
      }
    };

    const options = {
      style: {
        base: {
          fontSize: '16px',
        },
      },
    };

    return (
      <Wrapper onSubmit={handleSubmit}>
        <CardNumberElement
          className="payment-input"
          options={options}
          onChange={(event) => {
            setIsCardValid(event.complete && !event.error);
          }}
        />
        <CardExpiryElement
          className="payment-input half-width expiry"
          options={options}
          onChange={(event) => {
            setIsExpirationValid(event.complete && !event.error);
          }}
        />
        <CardCvcElement
          className="payment-input half-width"
          options={options}
          onChange={(event) => {
            setIsCvcValid(event.complete && !event.error);
          }}
        />
        <Button
          className="pay-now-button"
          label="PAY NOW"
          onClick={() => null}
          outlook="solid"
          intent="primary"
          width="112px"
          height="32px"
          isDisabled={
            isDisabled ||
            !clientSecret ||
            isLoading ||
            !(isCardValid && isExpirationValid && isCvcValid)
          }
        />
      </Wrapper>
    );
  }
);
