import { axiosInstance } from './base';
import { ProductGetResponse } from './models';

export const productsApi = {
  getAll: async (args?: { skip: number; limit: number }): Promise<ProductGetResponse[]> => {
    const { data, status } = await axiosInstance.get(
      `/api/v1/product/skip/${args?.skip || 0}/take/${args?.limit || 1000}/`
    );
    if (status !== 200) {
      throw new Error('Fetching products failed' + status);
    }
    return data;
  },
};
