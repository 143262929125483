import { Col, Container, Row } from 'styled-bootstrap-grid';
import { FormControl, TextField } from '@material-ui/core';
import React, { FC, useState } from 'react';
import { USStates, countries } from 'data';
import { useAlertStore, useGolfShopStore } from 'stores/hooks';

import { AlertType } from 'models/Alert';
import { CheckoutOrderDetails } from '../models';
import { CheckoutPostBodyItem } from 'api';
import { Currency } from 'models/Currency';
import { Select } from '@trackman/web-shared-components';
import { StripePayment } from './StripePayment';
import { isValidEmail } from 'utils';
import { observer } from 'mobx-react-lite';
import styled from 'styled-components';
import { useDebounce } from 'utils';

const Wrapper = styled.div`
  background: #ffffff;
  box-shadow: 0px 5px 15px rgba(0, 0, 0, 0.1);
  border-radius: 4px;
  padding: 32px 0px;

  h3 {
    text-transform: uppercase;
    font-size: 18px;
    font-weight: 600;
    margin-bottom: 30px;
  }

  .input {
    width: 100%;
    margin-bottom: 32px;
  }
`;

interface CheckoutFormProps {
  onBillingInfoChange: (billingFields: BillingFields) => void;
  onOrderChange: (orderDetails: CheckoutOrderDetails) => void;
  onOrderConfirmation: (...args: any) => void;
}

interface BillingFields {
  email: string;
  firstName: string;
  lastName: string;
  address: string;
  postCode: string;
  city: string;
  phone: string;
  company: string;
  countryCode: string;
  stateCode: string;
  vatNo: string;
}

export const CheckoutForm: FC<CheckoutFormProps> = observer(
  ({ onBillingInfoChange, onOrderChange, onOrderConfirmation }) => {
    const store = useGolfShopStore();
    const { pushAlert } = useAlertStore();
    const [email, setEmail] = useState('');
    const [firstName, setFirstName] = useState('');
    const [lastName, setLastName] = useState('');
    const [company, setCompany] = useState('');
    const [address, setAddress] = useState('');
    const [postCode, setPostCode] = useState('');
    const [city, setCity] = useState('');
    const [stateCode, setStateCode] = useState('');
    const [countryCode, setCountryCode] = useState('');
    const [vatNo, setVatNo] = useState('');
    const [phone, setPhone] = useState('');
    const [paymentIntentId, setPaymentIntentId] = useState<string>('');
    const [clientSecret, setClientSecret] = useState('');
    const [isUSA, setIsUSA] = useState(false);
    const [orderDetails, setOrderDetails] = useState({});
    const [isReadyToPay, setIsReadyToPay] = useState(false);
    const ls: any[] = store.cart || [];
    const [displayErrors, setDisplayErrors] = useState(false);

    const usCountryCodes = ['US', 'UM', 'VI'];
    const selectableCountries =
      store.region === 'US'
        ? countries.filter((c) => usCountryCodes.includes(c.code))
        : countries.filter((c) => !usCountryCodes.includes(c.code));

    let isEU = store.euCountryCodes.includes(countryCode);
    const date = new Date().toISOString();

    const items = ls.map(
      ({
        serial,
        coursePacks,
        hardware,
        software,
        b1Software,
        ioHardware,
        ioSoftwareHome,
        ioSoftwareHomeComplete,
        ioSoftwareCommercial,
        ioSoftwareHomeDuo,
        ioSoftwareHomeCompleteDuo,
        ioSoftwareCommercialDuo,
        ioHardwareDuo,
        coursePacksDuo,
      }) => {
        const result: CheckoutPostBodyItem = {
          serial,
        };

        if (hardware?.yearDuration) {
          result.hardwareDurationInYears = hardware?.yearDuration;
        }

        if (software?.yearDuration) {
          result.softwareDurationInYears = software?.yearDuration;
        }

        if (b1Software?.yearDuration) {
          result.b1softwareDurationInYears = b1Software?.yearDuration;
        }

        if (ioHardware?.yearDuration) {
          result.ioHardwareDurationInYears = ioHardware?.yearDuration;
        }

        if (ioSoftwareHome?.yearDuration) {
          result.ioSoftwareHomeDurationInYears = ioSoftwareHome?.yearDuration;
        }

        if (ioSoftwareHomeComplete?.yearDuration) {
          result.ioSoftwareHomeCompleteDurationInYears = ioSoftwareHomeComplete?.yearDuration;
        }

        if (ioSoftwareCommercial?.yearDuration) {
          result.ioSoftwareCommercialDurationInYears = ioSoftwareCommercial?.yearDuration;
        }

        if (ioSoftwareHomeDuo?.yearDuration) {
          result.ioSoftwareHomeDuoDurationInYears = ioSoftwareHomeDuo?.yearDuration;
        }

        if (ioSoftwareHomeCompleteDuo?.yearDuration) {
          result.ioSoftwareHomeCompleteDuoDurationInYears = ioSoftwareHomeCompleteDuo?.yearDuration;
        }

        if (ioSoftwareCommercialDuo?.yearDuration) {
          result.ioSoftwareCommercialDuoDurationInYears = ioSoftwareCommercialDuo?.yearDuration;
        }

        if (ioHardwareDuo?.yearDuration) {
          result.ioHardwareDuoDurationInYears = ioHardwareDuo?.yearDuration;
        }

        if (coursePacksDuo) {
          result.courseLicensesDuo = [
            {
              durationInYears:
                coursePacksDuo?.find((cp: any) => cp.yearDuration)?.yearDuration || null,
              productIdentifier:
                coursePacksDuo?.find((cp: any) => cp.yearDuration)?.productIdentifier || null,
            },
          ];
        }

        if (coursePacks) {
          result.courseLicenses = [
            {
              durationInYears:
                coursePacks?.find((cp: any) => cp.yearDuration)?.yearDuration || null,
              productIdentifier:
                coursePacks?.find((cp: any) => cp.yearDuration)?.productIdentifier || null,
            },
          ];
        }

        return result;
      }
    ) as CheckoutPostBodyItem[];

    const post = async (args: BillingFields) => {
      setIsReadyToPay(false);
      try {
        const res = await store.checkout({
          paymentIntentId: paymentIntentId || null,
          email: args.email,
          currency: store.currency,
          createdAt: date,
          billingInformation: {
            firstName: args.firstName,
            lastName: args.lastName,
            company: args.company || null,
            phone: args.phone || null,
            billingAddress: {
              countryCode: args.countryCode,
              city: args.city,
              zip: args.postCode,
              state: args.stateCode,
              addressLine: args.address,
            },
          },
          vatNo: args.vatNo || null,
          items,
        });
        const newOrderDetails = {
          taxPercent: res?.orderAmount?.taxPercent,
          taxAmount: res?.orderAmount?.taxAmount,
          totalPrice: res?.orderAmount?.totalPriceInCents / 100,
          totalPriceExcludingTax:
            res?.orderAmount?.totalPriceInCents / 100 - res?.orderAmount?.taxAmount,
          orderItems: res?.orderAmount?.orderItems?.map((item) => ({
            qty: item?.quantity,
            price: item?.priceWithDiscount,
            productType: item?.productType,
            productIdentifier: item?.productIdentifier,
          })),
        };
        setIsReadyToPay(true);
        setPaymentIntentId(res.paymentIntentId);
        setClientSecret(res.clientSecret);
        setOrderDetails(newOrderDetails);
        onBillingInfoChange(args);
        onOrderChange(newOrderDetails);
        pushAlert({
          text: `Billing information updated! ${
            args.vatNo && !res.isVatValid ? 'However, the provided VAT number was not valid.' : ''
          }`,
          type: args.vatNo && !res.isVatValid ? AlertType.ERROR : AlertType.SUCCESS,
          lifetimeInSec: args.vatNo && !res.isVatValid ? 6 : 5,
        });
      } catch (error) {
        pushAlert({
          text: `Error updating billing information! ${
            // @ts-ignore
            error.response?.data?.errors[0]?.error_details?._exception_message ?? ''
          }`,
          type: AlertType.ERROR,
          lifetimeInSec: 10,
        });
      }
    };

    const evaluateForPost = useDebounce(async (args: BillingFields) => {
      if (
        isValidEmail(args.email) &&
        args.firstName &&
        args.lastName &&
        args.address &&
        args.postCode &&
        args.city &&
        args.countryCode &&
        (args.countryCode === 'US' ? args.stateCode : true)
      ) {
        setDisplayErrors(false);
        await post(args);
      } else if (args.countryCode) {
        setDisplayErrors(true);
      }
    }, 1500);

    const onPaymentSuccess = (args: {
      orderDate: string;
      currency: Currency;
      orderNumber: string;
      cardDetails: {
        lastDigits: number;
        brand: string;
        expiry: string;
      };
    }) => {
      onOrderConfirmation({
        ...args,
        orderDetails,
        billingDetails: {
          firstName,
          lastName,
          address,
          postCode,
          city,
          countryCode,
          phone,
          email,
        },
      });
      store.clearStore();
    };

    return (
      <Wrapper>
        <Container fluid>
          <h3>contact information</h3>
          <Row>
            <Col>
              <TextField
                error={displayErrors && !isValidEmail(email)}
                className="input"
                label="Email*"
                type="email"
                variant="filled"
                value={email}
                onChange={(e) => {
                  setEmail(e.target.value.trim());
                  evaluateForPost({
                    email: e.target.value.trim(),
                    firstName,
                    lastName,
                    address,
                    postCode,
                    city,
                    countryCode,
                    stateCode,
                    vatNo,
                    phone,
                    company,
                  });
                }}
              />
            </Col>
          </Row>

          <h3>billing details</h3>
          <Row>
            <Col col={6}>
              <TextField
                error={displayErrors && !firstName}
                className="input"
                label="First name*"
                variant="filled"
                value={firstName}
                onChange={(e) => {
                  setFirstName(e.target.value);
                  evaluateForPost({
                    email,
                    firstName: e.target.value,
                    lastName,
                    address,
                    postCode,
                    city,
                    countryCode,
                    stateCode,
                    vatNo,
                    phone,
                    company,
                  });
                }}
              />
            </Col>
            <Col col={6}>
              <TextField
                error={displayErrors && !lastName}
                className="input"
                label="Last name*"
                variant="filled"
                value={lastName}
                onChange={(e) => {
                  setLastName(e.target.value);
                  evaluateForPost({
                    email,
                    firstName,
                    lastName: e.target.value,
                    address,
                    postCode,
                    city,
                    countryCode,
                    stateCode,
                    vatNo,
                    phone,
                    company,
                  });
                }}
              />
            </Col>
          </Row>
          <Row>
            <Col>
              <TextField
                className="input"
                label="Company name"
                variant="filled"
                value={company}
                onChange={(e) => {
                  setCompany(e.target.value);
                  evaluateForPost({
                    email,
                    firstName,
                    lastName,
                    address,
                    postCode,
                    city,
                    countryCode,
                    stateCode,
                    vatNo,
                    phone,
                    company: e.target.value,
                  });
                }}
              />
            </Col>
          </Row>
          <Row>
            <Col>
              <TextField
                error={displayErrors && !address}
                className="input"
                label="Address*"
                variant="filled"
                value={address}
                onChange={(e) => {
                  setAddress(e.target.value);
                  evaluateForPost({
                    email,
                    firstName,
                    lastName,
                    address: e.target.value,
                    postCode,
                    city,
                    countryCode,
                    stateCode,
                    vatNo,
                    phone,
                    company,
                  });
                }}
              />
            </Col>
          </Row>
          <Row>
            <Col col={6}>
              <TextField
                error={displayErrors && !postCode}
                className="input"
                label="Post code*"
                variant="filled"
                value={postCode}
                onChange={(e) => {
                  setPostCode(e.target.value.trim());
                  evaluateForPost({
                    email,
                    firstName,
                    lastName,
                    address,
                    postCode: e.target.value.trim(),
                    city,
                    countryCode,
                    stateCode,
                    vatNo,
                    phone,
                    company,
                  });
                }}
              />
            </Col>
            <Col col={6}>
              <TextField
                error={displayErrors && !city}
                className="input"
                label="City*"
                variant="filled"
                value={city}
                onChange={(e) => {
                  setCity(e.target.value);
                  evaluateForPost({
                    email,
                    firstName,
                    lastName,
                    address,
                    postCode,
                    city: e.target.value,
                    countryCode,
                    stateCode,
                    vatNo,
                    phone,
                    company,
                  });
                }}
              />
            </Col>
          </Row>
          <Row>
            <Col>
              <FormControl variant="filled" className="input">
                <Select
                  defaultValue=""
                  autoComplete="country"
                  placeholder="Country*"
                  label="Country*"
                  selectWidth="100%"
                  onChange={(event: any) => {
                    setIsUSA(event.target.value === 'US');
                    setCountryCode(event.target.value);
                    isEU = store.euCountryCodes.includes(event.target.value as string);
                    if (!isEU) {
                      setVatNo('');
                    }
                    evaluateForPost({
                      email,
                      firstName,
                      lastName,
                      address,
                      postCode,
                      city,
                      countryCode: event.target.value as string,
                      stateCode,
                      vatNo: isEU ? vatNo : '',
                      phone,
                      company,
                    });
                  }}
                  selectOptions={selectableCountries.map((c) => ({ value: c.code, label: c.name }))}
                />
              </FormControl>
            </Col>
          </Row>
          {isUSA && (
            <Row>
              <Col>
                <FormControl variant="filled" className="input">
                  <Select
                    defaultValue=""
                    autoComplete="state"
                    label="State*"
                    value={stateCode}
                    selectWidth="100%"
                    onChange={(event: any) => {
                      setStateCode(event.target.value);
                      evaluateForPost({
                        email,
                        firstName,
                        lastName,
                        address,
                        postCode,
                        city,
                        countryCode,
                        stateCode: event.target.value as string,
                        vatNo,
                        phone,
                        company,
                      });
                    }}
                    selectOptions={USStates.map((c) => ({ value: c.code, label: c.name }))}
                  />
                </FormControl>
              </Col>
            </Row>
          )}
          <Row>
            <Col col={6}>
              <TextField
                className="input"
                label="Phone number"
                variant="filled"
                value={phone}
                onChange={(e) => {
                  setPhone(e.target.value.trim());
                  evaluateForPost({
                    email,
                    firstName,
                    lastName,
                    address,
                    postCode,
                    city,
                    countryCode,
                    stateCode,
                    vatNo,
                    phone: e.target.value.trim(),
                    company,
                  });
                }}
              />
            </Col>
            {isEU && (
              <Col col={6}>
                <TextField
                  className="input"
                  label="VAT number"
                  variant="filled"
                  value={vatNo}
                  onChange={(e) => {
                    const hasMatch = /^([a-zA-Z]{0,2}[-]?)([0-9][\w]*)?$/.test(
                      e.target.value.trim()
                    );
                    hasMatch && setVatNo(e.target.value.trim().toUpperCase());
                    hasMatch &&
                      evaluateForPost({
                        email,
                        firstName,
                        lastName,
                        address,
                        postCode,
                        city,
                        countryCode,
                        stateCode,
                        vatNo: e.target.value.trim(),
                        phone,
                        company,
                      });
                  }}
                />
              </Col>
            )}
          </Row>

          <h3>payment method</h3>
          <Row>
            <Col>
              <StripePayment
                clientSecret={clientSecret}
                onPaymentSuccess={onPaymentSuccess}
                isDisabled={!isReadyToPay || displayErrors}
              />
            </Col>
          </Row>
        </Container>
      </Wrapper>
    );
  }
);
