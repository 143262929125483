import { useEffect, useState } from 'react';
import { useHistory } from 'react-router-dom';
import { useGolfShopStore } from 'stores/hooks';

const useCurrency = () => {
  const { canUserChangeCurrency } = useGolfShopStore();
  const history = useHistory();

  const checkCurrency = (location: any) => {
    return canUserChangeCurrency && location.pathname !== '/checkout';
  };

  const [isCurrencyChangeable, setCurrencyChangeable] = useState(checkCurrency(history.location));

  useEffect(() => {
    setCurrencyChangeable(checkCurrency(history.location));
    return history.listen((location) => {
      setCurrencyChangeable(checkCurrency(location));
    });
  }, [history, canUserChangeCurrency]);

  return [isCurrencyChangeable];
};

export default useCurrency;
