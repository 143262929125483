import { axiosInstance } from './base';
import { ConfigResponse } from './models';

export const configsApi = {
  get: async (): Promise<ConfigResponse> => {
    const { data, status } = await axiosInstance.get(`/api/v1/Configuration`); //refer to ConfigurationService and AxiosServiceFactory
    if (status !== 200) {
      throw new Error('Fetching configurations failed' + status);
    }
    return data;
  },
};
