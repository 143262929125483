import './ListItemDisplay.scss';

import { ICoursePack, ILicense, IListItem } from 'models/IListItem';
import { ProductGetResponse, ProductType } from 'api';
import React, { FC } from 'react';
import {
  calculateFullThreeYearPrice,
  getCoursePackByProductId,
  getDisplayPrice,
  getExpiration,
  getExpirationForYear,
} from 'utils';

import { Colors } from 'styles/colors';
import { InfoIcon } from 'icons';
import { Tooltip } from 'components/Shared/Tooltip/Tooltip';
import moment from 'moment';
import { observer } from 'mobx-react-lite';
import { useGolfShopStore } from 'stores/hooks';

interface IProps {
  product?: ProductGetResponse;
  productType: ProductType;
  item: IListItem;
  onClickTrialWarning: () => void;
  onClickSubscriptionInformation: () => void;
}

export const productTypeToLicense = (productType: ProductType, item: IListItem) => {
  const mapping = {
    [ProductType.None]: undefined,
    [ProductType.Software]: item.software,
    [ProductType.Hardware]: item.hardware,
    [ProductType.CoursePack]: item.coursePacks,
    [ProductType.B1Software]: item.b1Software,
    [ProductType.IOSoftwareHome]: item.ioSoftwareHome,
    [ProductType.IOSoftwareHomeComplete]: item.ioSoftwareHomeComplete,
    [ProductType.IOSoftwareCommercial]: item.ioSoftwareCommercial,
    [ProductType.IOSoftwareHomeDuo]: item.ioSoftwareHomeDuo,
    [ProductType.IOSoftwareHomeCompleteDuo]: item.ioSoftwareHomeCompleteDuo,
    [ProductType.IOSoftwareCommercialDuo]: item.ioSoftwareCommercialDuo,
    [ProductType.IOHardware]: item.ioHardware,
    [ProductType.IOHardwareDuo]: item.ioHardwareDuo,
    [ProductType.CoursePackDuo]: item.coursePacksDuo,
  } as const;

  return mapping[productType];
};

export const ListItemDisplay: FC<IProps> = observer(
  ({ productType, item, product, onClickTrialWarning, onClickSubscriptionInformation }) => {
    const {
      currency,
      yearDuration,
      currencySymbol,
      getCoursePackProductIdByDocumentId,
      getCorrespondingCartItemFromListItem,
      dateFormat,
    } = useGolfShopStore();

    const renderCoursePackExpiration = (): string | undefined => {
      let coursePack;
      if (product) {
        coursePack = getCoursePackByProductId(item, getCoursePackProductIdByDocumentId(product.id));
      }
      if (coursePack) {
        const expiration = getExpirationForYear({
          item: coursePack,
          yearDuration: yearDuration,
        });
        return expiration ? moment(expiration).format(dateFormat) : '';
      }
    };

    let itemSoftwareKey: ILicense | undefined;
    let productTypeSoftwareKey: ProductType;
    if (item.priceGroup === 'TMiOHome') {
      itemSoftwareKey = item?.ioSoftwareHome;
      productTypeSoftwareKey = ProductType.IOSoftwareHome;
    } else if (item.priceGroup === 'TMiOHomeComplete') {
      itemSoftwareKey = item?.ioSoftwareHomeComplete;
      productTypeSoftwareKey = ProductType.IOSoftwareHomeComplete;
    } else if (item.priceGroup === 'TMiOCommercial') {
      itemSoftwareKey = item?.ioSoftwareCommercial;
      productTypeSoftwareKey = ProductType.IOSoftwareCommercial;
    } else if (item.priceGroup === 'TMiOHomeDuo') {
      itemSoftwareKey = item?.ioSoftwareHomeDuo;
      productTypeSoftwareKey = ProductType.IOSoftwareHomeDuo;
    } else if (item.priceGroup === 'TMiOHomeCompleteDuo') {
      itemSoftwareKey = item?.ioSoftwareHomeCompleteDuo;
      productTypeSoftwareKey = ProductType.IOSoftwareHomeCompleteDuo;
    } else if (item.priceGroup === 'TMiOCommercialDuo') {
      itemSoftwareKey = item?.ioSoftwareCommercialDuo;
      productTypeSoftwareKey = ProductType.IOSoftwareCommercialDuo;
    } else {
      itemSoftwareKey = item?.software;
      productTypeSoftwareKey = ProductType.Software;
    }

    const isSoftwareLessThanYearDuration = (
      productYearDuration: number,
      course: ICoursePack | undefined
    ): boolean => {
      // if there is a software subscription in the cart with a yearDuration sat
      const cartItem = getCorrespondingCartItemFromListItem(item);

      let cartItemSoftwareKey: ILicense | undefined;
      if (item.priceGroup === 'TMiOHome') cartItemSoftwareKey = cartItem?.ioSoftwareHome;
      else if (item.priceGroup === 'TMiOHomeComplete')
        cartItemSoftwareKey = cartItem?.ioSoftwareHomeComplete;
      else if (item.priceGroup === 'TMiOCommercial')
        cartItemSoftwareKey = cartItem?.ioSoftwareCommercial;
      else if (item.priceGroup === 'TMiOHomeDuo') cartItemSoftwareKey = cartItem?.ioSoftwareHomeDuo;
      else if (item.priceGroup === 'TMiOHomeCompleteDuo')
        cartItemSoftwareKey = cartItem?.ioSoftwareHomeCompleteDuo;
      else if (item.priceGroup === 'TMiOCommercialDuo')
        cartItemSoftwareKey = cartItem?.ioSoftwareCommercialDuo;
      else cartItemSoftwareKey = cartItem?.software;

      let softwareExpiration;
      if (cartItemSoftwareKey?.yearDuration) {
        softwareExpiration = getExpiration({
          productType: productTypeSoftwareKey,
          item: cartItemSoftwareKey,
          yearDuration: cartItemSoftwareKey?.yearDuration,
        });
      }

      const expiration = softwareExpiration ?? itemSoftwareKey?.expiration;
      const softwareYearDuration = cartItemSoftwareKey?.yearDuration ?? 0;
      if (!expiration) return true;

      //days left include today so not up to 365 * year duration
      const productExpirationDate =
        course?.isActive && course?.expiration ? new Date(course.expiration) : new Date(Date.now());
      productExpirationDate.setFullYear(productExpirationDate.getFullYear() + productYearDuration);

      const softwareExpirationDate = new Date(expiration);
      softwareExpirationDate.setFullYear(
        softwareExpirationDate.getFullYear() + softwareYearDuration
      );

      return productExpirationDate > softwareExpirationDate;
    };

    return (
      <div className="list-item-display">
        {item.isTrial ? (
          <>
            <div className="left-col">
              <div className="serial-and-expiration-wrapper">
                <div className="expiration">
                  <div className="serial-trial">S/N: {item.serial}</div>
                  <div className="trial-tooltip" onClick={() => onClickTrialWarning()}>
                    <span>
                      <InfoIcon color={Colors.WARNING} />
                    </span>
                  </div>
                </div>
              </div>
              <div className="price"></div>
            </div>
          </>
        ) : (
          <>
            <div className="left-col">
              <div className="serial-and-expiration-wrapper">
                <div className="serial" onClick={onClickSubscriptionInformation}>
                  S/N: {item.serial}
                </div>
                <div className="expiration">
                  (Upon renewal, new expiry date:{' '}
                  {productType === ProductType.CoursePack ||
                  productType === ProductType.CoursePackDuo ? (
                    <>{renderCoursePackExpiration()}</>
                  ) : (
                    moment(
                      getExpirationForYear({
                        item: productTypeToLicense(productType, item) as ILicense | undefined, // only ProductType.CoursePack and ProductType.CoursePackDuo return ICoursePack[], and it has been handled above
                        yearDuration: yearDuration,
                      })
                    ).format(dateFormat)
                  )}
                  )
                  {(productType === ProductType.CoursePack ||
                    productType === ProductType.CoursePackDuo) &&
                    itemSoftwareKey?.isActive &&
                    (isSoftwareLessThanYearDuration(yearDuration, item?.coursePacks?.[0]) ||
                      isSoftwareLessThanYearDuration(yearDuration, item?.coursePacksDuo?.[0])) && (
                      <Tooltip text="Price is calculated on the days left until your Software subscription expires." />
                    )}
                </div>
              </div>
              {(productType === ProductType.CoursePack ||
                productType === ProductType.CoursePackDuo) &&
                !itemSoftwareKey?.isActive && (
                  <div className="is-expired">Expired Software Subscription</div>
                )}
              {productType === ProductType.Hardware &&
                !item.hardware?.isActive &&
                item.hardware?.priceAndDate?.other[currency] && (
                  <div className="backpay">
                    {currencySymbol + item.hardware?.priceAndDate.other[currency]} (Backpay) +{' '}
                    {currencySymbol}
                    {yearDuration === 1
                      ? product?.price[currency]
                      : yearDuration === 3
                      ? product && calculateFullThreeYearPrice(productType, product.price[currency])
                      : undefined}
                    <Tooltip text="Backpay is the payment for the period between the expire date and today." />
                  </div>
                )}
              {productType === ProductType.IOHardware &&
                !item.ioHardware?.isActive &&
                item.ioHardware?.priceAndDate?.other[currency] && (
                  <div className="backpay">
                    {currencySymbol + item.ioHardware?.priceAndDate.other[currency]} (Backpay) +{' '}
                    {currencySymbol}
                    {yearDuration === 1
                      ? product?.price[currency]
                      : yearDuration === 3
                      ? product && calculateFullThreeYearPrice(productType, product.price[currency])
                      : undefined}
                    <Tooltip text="Backpay is the payment for the period between the expire date and today." />
                  </div>
                )}
              {productType === ProductType.B1Software &&
                !item.b1Software?.isActive &&
                item.b1Software?.priceAndDate?.other[currency] && (
                  <div className="backpay">
                    {currencySymbol + item.b1Software?.priceAndDate.other[currency]} (Backpay) +{' '}
                    {currencySymbol}
                    {yearDuration === 1
                      ? product?.price[currency]
                      : yearDuration === 3
                      ? product && calculateFullThreeYearPrice(productType, product.price[currency])
                      : undefined}
                    <Tooltip text="Backpay is the payment for the period between the expire date and today." />
                  </div>
                )}
            </div>
            <div className="price">
              {currencySymbol}
              {product &&
                getDisplayPrice(
                  productType,
                  yearDuration,
                  currency,
                  item,
                  product,
                  getCorrespondingCartItemFromListItem(item),
                  getCoursePackProductIdByDocumentId(product.id)
                )?.toFixed(2)}
            </div>
          </>
        )}
      </div>
    );
  }
);
