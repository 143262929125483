import React, { FC } from 'react';
import { EachOrder } from './EachOrder';
import styled from 'styled-components';
import { observer } from 'mobx-react-lite';
import { useGolfShopStore } from 'stores/hooks';
import { Colors } from 'styles/colors';
import { CheckoutOrderDetails } from '../models';

const Wrapper = styled.div`
  .sum {
    text-align: right;
    margin-top: 50px;
    font-size: 18px;

    > div {
      margin-bottom: 14px;
    }
  }

  .total {
    border-top: 1px solid ${Colors.LIGHT_GRAY};

    span {
      color: ${Colors.BRAND};
    }
  }

  li:not(:last-of-type) {
    margin-bottom: 64px;
  }
`;

interface OrderSummaryProps {
  orderDetails: CheckoutOrderDetails;
  countryCode: string; 
}

export const OrderSummary: FC<OrderSummaryProps> = observer(({ orderDetails, countryCode }) => {
  const { currencySymbol } = useGolfShopStore();
  const { totalPrice, taxAmount, totalPriceExcludingTax, orderItems } = orderDetails;
  const vatText = countryCode.toUpperCase() === 'US' ? 'TAX' : 'VAT';
  return (
    <Wrapper>
      <ul>
        {orderItems?.map(({ price, qty, productType, productIdentifier }) => {
          return (
            <EachOrder
              key={productType}
              subtotal={price}
              quantity={qty}
              productType={productType}
              productIdentifier={productIdentifier}
            />
          );
        })}
      </ul>
      <div className="sum">
        <div>
      Total excluding {vatText}: {currencySymbol}
          {totalPriceExcludingTax.toFixed(2)}
        </div>
        <div>{vatText}: {currencySymbol}{taxAmount.toFixed(2)}</div> 
        <div className="total">
          Total{' '}
          <span>
            {currencySymbol}
            {totalPrice.toFixed(2)}
          </span>
        </div>
      </div>
    </Wrapper>
  );
});
