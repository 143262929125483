import React, { FC } from 'react';

import { ProductType } from 'api';
import { observer } from 'mobx-react-lite';
import styled from 'styled-components';
import { useGolfShopStore } from 'stores/hooks';

const Wrapper = styled.div`
  img {
    box-shadow: 0px 5px 15px rgba(0, 0, 0, 0.1);
    border-radius: 4px;
    width: 100%;
    object-fit: contain;
    height: 115px;
    background: white;
  }
  .product-info {
  }
  .product-name {
    font-size: 15px;
    font-weight: bold;
  }
`;

interface IProps {
  productType: ProductType;
  nameIdentifier: string;
}

export const ProductInformation: FC<IProps> = observer(({ productType, nameIdentifier }) => {
  const { getProductImage, getProductName } = useGolfShopStore();

  return (
    <Wrapper>
      <img
        src={getProductImage(productType, nameIdentifier)}
        alt={getProductName(productType, nameIdentifier)}
      />
      <div className="product-info">
        <span className="product-name">{getProductName(productType, nameIdentifier)}</span>
      </div>
    </Wrapper>
  );
});
