import './ListItems.scss';

import { ProductGetResponse, ProductType } from 'api';
import React, { FC } from 'react';

import { IListItem } from 'models/IListItem';
import { ListItem } from '../ListItem/ListItem';
import { observer } from 'mobx-react-lite';
import { useGolfShopStore } from 'stores/hooks';
import useListItemsFilteredByProductType from 'utils/useListItemsFilteredByProductType';

interface IProps {
  onClickCheckButtonCallback: () => void;
  onCloseError: () => void;
  error: string;
  productType: ProductType;
  product?: ProductGetResponse;
  onClickTrialWarning?: () => void;
  onClickSubscriptionInformation?: (item: IListItem) => void;
}

export const ListItems: FC<IProps> = observer(
  ({
    onClickCheckButtonCallback,
    error,
    onCloseError,
    productType,
    product,
    onClickTrialWarning,
    onClickSubscriptionInformation,
  }) => {
    const { editItem, isLoading, updateListItem, removeListItem } = useGolfShopStore();

    const handleKeyPress = (event: React.KeyboardEvent<HTMLInputElement>) => {
      event.stopPropagation();
      if (event.key === 'Enter' && editItem?.value) {
        onClickCheckButtonCallback();
      }
    };

    const listItemsFiltered = useListItemsFilteredByProductType(productType);

    return (
      <div className="list-items">
        <div className="items" onKeyPress={handleKeyPress}>
          <ListItem
            key={listItemsFiltered.length}
            index={listItemsFiltered.length}
            item={editItem}
            productType={productType}
            product={product}
            onChange={(value) => updateListItem(value?.trim())}
            error={error}
            onCloseError={onCloseError}
            onClick={() => editItem?.value && onClickCheckButtonCallback()}
            isDisabled={isLoading}
          />
          {listItemsFiltered.map((item, index) => {
            return (
              <ListItem
                key={index}
                index={index}
                onClick={() => removeListItem(item)}
                onClickTrialWarning={() => onClickTrialWarning && onClickTrialWarning()}
                onClickSubscriptionInformation={() =>
                  onClickSubscriptionInformation && onClickSubscriptionInformation(item)
                }
                error={error}
                onCloseError={onCloseError}
                item={item}
                productType={productType}
                product={product}
                isDisabled={isLoading}
              />
            );
          })}
        </div>
      </div>
    );
  }
);
