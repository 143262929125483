import React, { FC } from 'react';
import { renderDaysLeftFromNow, renderSubscriptionExpiration } from 'utils/subscription';

import { ILicense } from 'models/IListItem';
import { ProductInformation } from './ProductInformation';
import { ProductType } from 'api';
import { observer } from 'mobx-react-lite';
import styled from 'styled-components';
import { useGolfShopStore } from 'stores/hooks';

const Wrapper = styled.div``;

interface IProps {
  subscription: ILicense;
  productType: ProductType;
  nameIdentifier: string;
}

export const Subscription: FC<IProps> = observer(
  ({ subscription, productType, nameIdentifier }) => {
    const { dateFormat } = useGolfShopStore();

    return (
      <Wrapper>
        <ProductInformation productType={productType} nameIdentifier={nameIdentifier} />
        <div className="subscription-info">
          <div className="subscription-days">{renderDaysLeftFromNow(subscription)}</div>
          <span className="subscription-expiration">
            {renderSubscriptionExpiration(subscription, dateFormat)}
          </span>
        </div>
      </Wrapper>
    );
  }
);
