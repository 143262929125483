import React, { FC } from 'react';
import './Overlay.scss';

interface IProps {
  onClickCallback?: () => void;
}

export const Overlay: FC<IProps> = ({ onClickCallback }) => {
  return <div onClick={onClickCallback && onClickCallback} className="overlay" />;
};
