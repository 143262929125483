import { Currency } from 'models/Currency';

export interface CheckoutPostBody {
  paymentIntentId: string | null;
  email: string;
  currency: string;
  createdAt: string; //ask: do we need to pass date from frontend?
  billingInformation: {
    firstName: string;
    lastName: string;
    company: string | null;
    phone: string | null;
    billingAddress: {
      countryCode: string;
      city: string;
      zip: string;
      state: string;
      addressLine: string;
    };
  };
  vatNo: string | null;
  items: CheckoutPostBodyItem[];
}

export interface CheckoutPostResponse {
  clientSecret: string;
  isVatValid: boolean;
  paymentIntentId: string;
  orderAmount: {
    priceRaw: number;
    totalPriceInCents: number;
    discount: number;
    taxAmount: number;
    taxPercent: number;
    orderItems: [
      {
        quantity: number;
        price: number;
        priceWithDiscount: number;
        discount: number;
        vat: number;
        totalPrice: number;
        productType: ProductType;
        productIdentifier: string;
      }
    ];
  };
}

export interface CheckoutPostBodyItem {
  serial: number; //ask: should this be number?
  hardwareDurationInYears?: number; //ask: ?
  softwareDurationInYears?: number; //ask: ?
  b1softwareDurationInYears?: number;
  ioHardwareDurationInYears?: number;
  ioSoftwareHomeDurationInYears?: number;
  ioSoftwareHomeCompleteDurationInYears?: number;
  ioSoftwareCommercialDurationInYears?: number;
  ioSoftwareHomeDuoDurationInYears?: number;
  ioSoftwareHomeCompleteDuoDurationInYears?: number;
  ioSoftwareCommercialDuoDurationInYears?: number;
  ioHardwareDuoDurationInYears?: number;
  courseLicenses?: [
    {
      durationInYears: number; //ask: ?
      productIdentifier: string;
    }
  ];
  courseLicensesDuo?: [
    {
      durationInYears: number; //ask: ?
      productIdentifier: string;
    }
  ];
}

export enum Region {
  EU = 'EU',
  US = 'US',
}

export interface ConfigResponse {
  currency: Currency;
  region: Region;
  stripePublicKey: string;
  cdnUri: string;
}

export interface ProductGetResponse {
  id: string;
  productType: ProductType;
  nameIdentifier: string;
  price: {
    EUR: number;
    USD: number;
  };
}

export interface IApiCustomerRadarLicense {
  customerName: string;
  radarLicenseInfos: IApiRadarLicenseInfo[];
}

export interface IApiRadarLicenseInfo {
  courseLicenses: IApiCourseLicense[];
  hardware: IApiSubscription;
  software: IApiSubscription;
  b1Software: IApiSubscription;
  ioHardware: IApiSubscription;
  ioSoftwareHome: IApiSubscription;
  ioSoftwareHomeComplete: IApiSubscription;
  ioSoftwareCommercial: IApiSubscription;
  ioSoftwareHomeDuo: IApiSubscription;
  ioSoftwareHomeCompleteDuo: IApiSubscription;
  ioSoftwareCommercialDuo: IApiSubscription;
  courseLicensesDuo: IApiCourseLicense[];
  ioHardwareDuo: IApiSubscription;
  serial: number;
  licenseType: number; // Enum values depend on environment
  priceGroup: PriceGroup;
  status: number;
}

export interface IApiCourseLicense {
  expireDate: string;
  isCoursePackActive: boolean;
  priceAndDate: PriceAndDate;
  productIdentifier: string;
  renewSoftwareSubscriptionOneYearPriceAndDate: PriceAndDate;
  renewSoftwareSubscriptionThreeYearPriceAndDate: PriceAndDate;
}

export interface Prices {
  EUR: number;
  USD: number;
}

export interface IApiSubscription {
  expiration: string | null;
  isActive: boolean;
  priceAndDate: PriceAndDate;
}

export interface PriceAndDate {
  oneYear: Prices;
  threeYear: Prices;
  other: Prices;
  oneYearExpirationDate: string;
  threeYearExpirationDate: string;
}

export enum ProductType {
  None,
  Software,
  Hardware,
  CoursePack,
  B1Software,
  IOSoftwareHome,
  IOSoftwareHomeComplete,
  IOSoftwareCommercial,
  IOSoftwareHomeDuo,
  IOSoftwareHomeCompleteDuo,
  IOSoftwareCommercialDuo,
  IOHardware,
  IOHardwareDuo,
  CoursePackDuo,
}

export type PriceGroup =
  | 'B1Software'
  | 'TMiOHome'
  | 'TMiOHomeComplete'
  | 'TMiOCommercial'
  | 'TMiOHomeDuo'
  | 'TMiOHomeCompleteDuo'
  | 'TMiOCommercialDuo'
  | 'TM4';
