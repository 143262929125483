import './CustomSnackbar.scss';

import React, { FC } from 'react';
import Slide, { SlideProps } from '@material-ui/core/Slide';
import Snackbar, { SnackbarCloseReason } from '@material-ui/core/Snackbar';

import { Button } from '@trackman/react-ui';
import { CloseIcon } from 'icons';
import { NotificationType } from 'models/NotficationType';
import { SnackbarContent } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import { observer } from 'mobx-react-lite';

interface IProps {
  autoHideDuration?: number;
  onClose: (event: React.SyntheticEvent<any, Event>, reason: SnackbarCloseReason) => void;
  onClickCartCallback: () => void;
  onClickRedirectCallback: (withCallback: boolean) => void;
  onClickCallback: (getAll: boolean) => void;
  message: string;
  notificationType: NotificationType;
}

const useStyles = makeStyles({
  root: {
    right: '0px',
    top: '97px',
    color: '#000',
    display: 'flex',
  },
  snackbarContent: {
    backgroundColor: 'white',
    boxShadow: '0px 5px 15px rgba(0, 0, 0, 0.1)',
    borderRadius: '4px',
    color: '#414141',
  },
  message: {
    fontFamily: 'Lato',
    fontSize: '13px',
    lineHeight: '18px',
  },
});
export const CustomSnackbar: FC<IProps> = observer(
  ({
    autoHideDuration,
    onClose,
    message,
    notificationType,
    onClickCartCallback,
    onClickRedirectCallback,
    onClickCallback,
  }) => {
    const classes = useStyles();

    const TransitionComponent = (props: SlideProps) => {
      return <Slide {...props} direction="left" />;
    };

    const getAction = (): React.ReactFragment => {
      switch (notificationType) {
        case NotificationType.CartNotification:
          return (
            <>
              <Button
                className="checkout-button"
                label={'Go to Cart'}
                onClick={onClickCartCallback}
                outlook="solid"
                intent="secondary"
                width="112px"
                height="32px"
              />
              <div className="close-icon" onClick={(e) => onClose(e, 'clickaway')}>
                <CloseIcon />
              </div>
            </>
          );
        case NotificationType.FacilityNotification:
          return (
            <>
              <Button
                className="no-button"
                label={'No, thank you'}
                onClick={() => onClickCallback(false)}
                outlook="plain"
                intent="secondary"
                width="112px"
                height="32px"
              />
              <Button
                className="yes-button"
                label={'Yes, please'}
                onClick={() => onClickCallback(true)}
                outlook="solid"
                intent="primary"
                width="112px"
                height="32px"
              />
            </>
          );
        case NotificationType.DifferentProduct:
          return (
            <>
              <Button
                className="no-button"
                label={'No, thank you'}
                onClick={() => onClickRedirectCallback(false)}
                outlook="plain"
                intent="secondary"
                width="112px"
                height="32px"
              />
              <Button
                className="yes-button"
                label={'Yes, please'}
                onClick={() => onClickRedirectCallback(true)}
                outlook="solid"
                intent="primary"
                width="112px"
                height="32px"
              />
            </>
          );
        case NotificationType.UnhandledLicense:
          return (
            <>
              <div className="close-icon" onClick={(e) => onClose(e, 'clickaway')}>
                <CloseIcon />
              </div>
            </>
          );
        case NotificationType.None:
          return <div>None</div>;
      }
    };

    return (
      <div className="custom-snackbar">
        <Snackbar
          classes={{
            root: classes.root,
          }}
          anchorOrigin={{
            vertical: 'top',
            horizontal: 'right',
          }}
          open
          autoHideDuration={autoHideDuration}
          onClose={onClose}
          TransitionComponent={TransitionComponent}
        >
          <SnackbarContent
            classes={{
              root: classes.snackbarContent,
              message: classes.message,
            }}
            message={message}
            action={getAction()}
          />
        </Snackbar>
      </div>
    );
  }
);
