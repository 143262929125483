import React, { FC } from 'react';
import { NavLink } from 'react-router-dom';
import { CloseIcon } from 'icons';
import { useGolfShopStore } from 'stores/hooks';
import { CurrencyDropDown } from '../DropDowns/CurrencyDropDown';
import styled from 'styled-components';
import { Colors } from 'styles/colors';
import useCurrency from '../useCurrency';

const Wrapper = styled.div<{ isMobileMenuExpanded: boolean }>`
  display: flex;
  justify-content: space-between;
  flex-direction: column;
  align-items: flex-start;

  position: fixed;
  top: 80px;
  left: 0;
  width: 100%;
  z-index: 3;

  background-color: white;
  box-shadow: 0px 5px 15px rgba(0, 0, 0, 0.1);

  overflow: ${({ isMobileMenuExpanded }) => (isMobileMenuExpanded ? 'visible' : 'hidden')};

  .menu-item {
    text-transform: uppercase;
    width: 100%;
    text-align: left;
    a {
      text-decoration: none;
      color: ${Colors.GRAY};
      &.active {
        color: ${Colors.BRAND};
      }
    }
    .icon-wrapper {
      margin-left: auto;
    }
    &.with-close-button {
      display: flex;
    }
  }
`;

export const MobileMenu: FC = () => {
  const { isMobileMenuExpanded, setIsMobileMenuExpanded } = useGolfShopStore();

  const [isCurrencyChangeable] = useCurrency();
  return (
    <Wrapper
      isMobileMenuExpanded={isMobileMenuExpanded}
      style={{
        height: isMobileMenuExpanded ? '112px' : '0px',
        padding: isMobileMenuExpanded ? '24px 36px' : '0px 36px',
      }}
    >
      <div onClick={() => setIsMobileMenuExpanded(false)} className="menu-item">
        <NavLink activeClassName="active" exact to="/">
          Home
        </NavLink>
      </div>
      <div onClick={() => setIsMobileMenuExpanded(false)} className="menu-item with-close-button">
        <NavLink activeClassName="active" exact to="/terms">
          {'Terms & conditions'}
        </NavLink>
        <span className="icon-wrapper">
          <CloseIcon />
        </span>
      </div>
      <div className="localization">
        {/* <LanguageDropDown /> */}
        {isCurrencyChangeable && <CurrencyDropDown />}
      </div>
    </Wrapper>
  );
};
