import { IApiCustomerRadarLicense } from './models';
import { axiosInstance } from './base';

export const radarLicenseApi = {
  get: async (serialNumber: string): Promise<IApiCustomerRadarLicense> => {
    const { data, status } = await axiosInstance.get(`/api/v1/radarlicense/${serialNumber}`);
    if (status !== 200) {
      throw new Error('Fetching radar license failed' + status);
    }
    return data;
  },
};
