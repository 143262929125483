import React, { FC, useState, createRef, useEffect } from 'react';
import styled from 'styled-components';

const Wrapper = styled.div<{ isExpanded: boolean }>`
  position: relative;
  overflow: visible;
  .options {
    display: ${({ isExpanded }) => (isExpanded ? 'block' : 'none')};
    padding: 2px 16px;
    position: absolute;
    top: 25px;
    white-space: nowrap;
    background: white;
    box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19);

    > li {
      cursor: pointer;
      margin: 16px 0;
    }
  }

  button .hide-on-select {
  }

  button {
    border: none;
    background: inherit;
    cursor: pointer;
    color: inherit;
    outline: none;
  }
`;

interface FancyDropdownProps {
  onChange: (value: any) => void;
  selected: string | number;
  items: Array<{
    element: JSX.Element | string;
    value: string | number;
  }>;
}

export const FancyDropDown: FC<FancyDropdownProps> = ({ items, selected, onChange }) => {
  const [isExpanded, setIsExpanded] = useState(false);
  const dropdownRef = createRef<any>();

  useEffect(() => {
    const handleClick = (e: any) => {
      const path = e.path || (e.composedPath && e.composedPath());
      if (!path.includes(dropdownRef.current) && isExpanded) {
        setIsExpanded(false);
      }
    };
    document.addEventListener('click', handleClick);
    return () => document.removeEventListener('click', handleClick);
  });

  return (
    <Wrapper isExpanded={isExpanded}>
      <button
        className="selected"
        onClick={() => {
          setIsExpanded(true);
        }}
      >
        {items.find((i) => i.value === selected)?.element}
      </button>
      <ul className="options" ref={dropdownRef}>
        {items.map((item) => (
          <li
            key={item.value}
            onClick={() => {
              onChange(item.value);
              setIsExpanded(false);
            }}
          >
            {item.element}
          </li>
        ))}
      </ul>
    </Wrapper>
  );
};
