import { Col, Container, Row } from 'styled-bootstrap-grid';
import React, { FC } from 'react';

import { Button } from '@trackman/react-ui';
import { Colors } from 'styles/colors';
import { DisclaimerModal } from 'pages/ProductPage/components/DisclaimerModal/DisclaimerModal';
import { ShoppingItem } from './ShoppingItem';
import { observer } from 'mobx-react-lite';
import styled from 'styled-components';
import { useGolfShopStore } from 'stores/hooks';
import { useHistory } from 'react-router-dom';

const Wrapper = styled.div`
  .header {
    border-bottom: 1px ${Colors.LIGHT_GRAY} solid;
    padding-bottom: 28px;
    margin-bottom: 40px;

    h2 {
      font-size: 22px;
      text-transform: uppercase;
      padding-bottom: 25px;
    }
  }

  .total {
    font-size: 28px;
    margin: auto;
    width: 100%;
    display: flex;
    justify-content: flex-end;

    .text-and-button {
      text-align: center;
    }

    .text {
      margin-bottom: 30px;
    }

    span {
      color: ${Colors.BRAND};
    }
  }

  @media (max-width: 576px) {
    .button {
      width: 100%;
    }

    .text-and-button {
      width: 100%;
    }
  }
`;

export const CartPage: FC = observer(() => {
  const {
    cartGroupedByProductType,
    currencySymbol,
    setIsSoftwareExpiryModalVisible,
    isSoftwareExpiryModalVisible,
    clearListItems,
  } = useGolfShopStore();
  const history = useHistory();
  const totalPrice = cartGroupedByProductType.reduce(
    (total, current) => total + current.subtotal,
    0
  );

  const handleOnClickCheckout = () => {
    clearListItems();
    history.push('/checkout');
  };

  return (
    <Wrapper>
      <Container>
        <Row>
          <Col>
            <div className="header">
              <h2>shopping cart</h2>
              <Button
                className="button"
                label={'Continue shopping'}
                onClick={() => history.push('/')}
                outlook="solid"
                intent="secondary"
                width="174px"
                height="32px"
              />
            </div>
            {cartGroupedByProductType.map((el) => (
              <ShoppingItem
                key={el.productType}
                items={el.items}
                productType={el.productType}
                productIdentifier={el.items?.[0].productIdentifier ?? ''}
                subtotal={el.subtotal}
                discount={el.discount}
                discountPercentage={el.discountPercentage}
              />
            ))}
            <div className="total">
              <div className="text-and-button">
                <div className="text">
                  Total{' '}
                  <span>
                    {currencySymbol}
                    {totalPrice.toFixed(2)}
                  </span>
                </div>
                <Button
                  className="button"
                  label={'Check out'}
                  onClick={handleOnClickCheckout}
                  isDisabled={!cartGroupedByProductType.length}
                  outlook="solid"
                  intent="primary"
                  width="160px"
                  height="32px"
                />
              </div>
            </div>
          </Col>
        </Row>
      </Container>
      <DisclaimerModal
        visible={isSoftwareExpiryModalVisible}
        onDismissCallback={() => setIsSoftwareExpiryModalVisible(false)}
      />
    </Wrapper>
  );
});
