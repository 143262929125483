import React, { FC } from 'react';
import './Footer.scss';
import { ReactComponent as MasterCardIcon } from './images/mastercard.svg';
import { ReactComponent as AmExIcon } from './images/amex.svg';
import { ReactComponent as VisaIcon } from './images/visa.svg';

export const Footer: FC = () => {
  const date = new Date();
  return (
    <div className="footer">
      <div className="footer-content">
        <div className="footer-icons">
          <MasterCardIcon />
          <AmExIcon />
          <VisaIcon />
        </div>
        <div className="footer-text">
          Copyright © {date.getFullYear()} <span className="trackman">TrackMan</span> | All prices
          are excl. VAT
        </div>
      </div>
    </div>
  );
};
