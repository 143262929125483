import './TotalPrice.scss';

import { ProductGetResponse, ProductType } from 'api';
import React, { FC } from 'react';
import {
  getCoursePackByProductId,
  getDiscount,
  getPrice,
  getTotalDiscountPrice,
  getTotalPriceAfterDiscount,
} from 'utils';

import { useGolfShopStore } from 'stores/hooks';
import useListItemsFilteredByProductType from 'utils/useListItemsFilteredByProductType';

interface IProps {
  productType: ProductType;
  product?: ProductGetResponse;
}

export const TotalPrice: FC<IProps> = ({ productType, product }) => {
  const store = useGolfShopStore();
  const {
    listItems,
    currency,
    yearDuration,
    currencySymbol,
    cartGroupedByProductType,
    getCoursePackProductIdByDocumentId,
    getCorrespondingCartItemFromListItem,
  } = store;
  let numberOfUnits = 0;

  const listItemsFiltered = useListItemsFilteredByProductType(productType);
  const items = listItemsFiltered.filter((x) => x.isMapped && !x.isTrial);

  const totalPrice = ((): number => {
    let price = 0;
    items.forEach((item) => {
      numberOfUnits++;

      if (productType === ProductType.CoursePack && product) {
        const coursePack = getCoursePackByProductId(
          item,
          getCoursePackProductIdByDocumentId(product.id)
        );
        if (coursePack) {
          const cartItem = getCorrespondingCartItemFromListItem(item);
          price +=
            getPrice({
              productType: ProductType.CoursePack,
              item: coursePack,
              currency: currency,
              softwareYearDuration: cartItem?.software?.yearDuration,
              yearDuration: yearDuration,
            }) || 0;
        }
      } else if (productType === ProductType.Hardware && item.hardware) {
        price +=
          getPrice({
            productType: ProductType.Hardware,
            item: item.hardware,
            currency: currency,
            yearDuration: yearDuration,
          }) || 0;
      } else if (productType === ProductType.Software && item.software) {
        price +=
          getPrice({
            productType: ProductType.Software,
            item: item.software,
            currency: currency,
            yearDuration: yearDuration,
          }) || 0;
      } else if (productType === ProductType.B1Software && item.b1Software) {
        price +=
          getPrice({
            productType: ProductType.B1Software,
            item: item.b1Software,
            currency: currency,
            yearDuration: yearDuration,
          }) || 0;
      } else if (productType === ProductType.IOHardware && item.ioHardware) {
        price +=
          getPrice({
            productType: ProductType.IOHardware,
            item: item.ioHardware,
            currency: currency,
            yearDuration: yearDuration,
          }) || 0;
      } else if (productType === ProductType.IOSoftwareHome && item.ioSoftwareHome) {
        price +=
          getPrice({
            productType: ProductType.IOSoftwareHome,
            item: item.ioSoftwareHome,
            currency: currency,
            yearDuration: yearDuration,
          }) || 0;
      } else if (
        productType === ProductType.IOSoftwareHomeComplete &&
        item.ioSoftwareHomeComplete
      ) {
        price +=
          getPrice({
            productType: ProductType.IOSoftwareHomeComplete,
            item: item.ioSoftwareHomeComplete,
            currency: currency,
            yearDuration: yearDuration,
          }) || 0;
      } else if (productType === ProductType.IOSoftwareCommercial && item.ioSoftwareCommercial) {
        price +=
          getPrice({
            productType: ProductType.IOSoftwareCommercial,
            item: item.ioSoftwareCommercial,
            currency: currency,
            yearDuration: yearDuration,
          }) || 0;
      } else if (productType === ProductType.IOSoftwareHomeDuo && item.ioSoftwareHomeDuo) {
        price +=
          getPrice({
            productType: ProductType.IOSoftwareHomeDuo,
            item: item.ioSoftwareHomeDuo,
            currency: currency,
            yearDuration: yearDuration,
          }) || 0;
      } else if (
        productType === ProductType.IOSoftwareHomeCompleteDuo &&
        item.ioSoftwareHomeCompleteDuo
      ) {
        price +=
          getPrice({
            productType: ProductType.IOSoftwareHomeCompleteDuo,
            item: item.ioSoftwareHomeCompleteDuo,
            currency: currency,
            yearDuration: yearDuration,
          }) || 0;
      } else if (
        productType === ProductType.IOSoftwareCommercialDuo &&
        item.ioSoftwareCommercialDuo
      ) {
        price +=
          getPrice({
            productType: ProductType.IOSoftwareCommercialDuo,
            item: item.ioSoftwareCommercialDuo,
            currency: currency,
            yearDuration: yearDuration,
          }) || 0;
      } else if (productType === ProductType.CoursePackDuo && product) {
        const coursePack = getCoursePackByProductId(
          item,
          getCoursePackProductIdByDocumentId(product.id)
        );
        if (coursePack) {
          const cartItem = getCorrespondingCartItemFromListItem(item);
          price +=
            getPrice({
              productType: ProductType.CoursePackDuo,
              item: coursePack,
              currency: currency,
              softwareYearDuration:
                cartItem?.ioSoftwareHomeDuo?.yearDuration ??
                cartItem?.ioSoftwareHomeCompleteDuo?.yearDuration ??
                cartItem?.ioSoftwareCommercialDuo?.yearDuration,
              yearDuration: yearDuration,
            }) || 0;
        }
      } else if (productType === ProductType.IOHardwareDuo && item.ioHardwareDuo) {
        price +=
          getPrice({
            productType: ProductType.IOHardwareDuo,
            item: item.ioHardwareDuo,
            currency: currency,
            yearDuration: yearDuration,
          }) || 0;
      }
    });
    return price;
  })();

  const coursePackItemsInCart = cartGroupedByProductType
    .filter((item) => item.productType === ProductType.CoursePack)
    .map((item) => item.items)
    .flat().length;

  const coursePackDuoItemsInCart = cartGroupedByProductType
    .filter((item) => item.productType === ProductType.CoursePackDuo)
    .map((item) => item.items)
    .flat().length;

  const unitsForDiscount =
    coursePackItemsInCart +
    Math.floor(
      (coursePackDuoItemsInCart + (productType === ProductType.CoursePackDuo ? numberOfUnits : 0)) /
        2
    ) +
    (productType === ProductType.CoursePack ? numberOfUnits : 0);

  return (
    <>
      {unitsForDiscount > 1 &&
        (productType === ProductType.CoursePack || productType === ProductType.CoursePackDuo) && (
          <div className="total-price">
            <div>Discount ({getDiscount(unitsForDiscount)}%)</div>

            <div>
              -{currencySymbol}
              {getTotalDiscountPrice(totalPrice, unitsForDiscount).toFixed(2)}
            </div>
          </div>
        )}
      <div className="total-price">
        <div>Total ({listItemsFiltered.filter((x) => x.isMapped && !x.isTrial).length} S/N)</div>
        <div className="total-price-value">
          {currencySymbol}
          {productType === ProductType.CoursePack || productType === ProductType.CoursePackDuo
            ? getTotalPriceAfterDiscount(totalPrice, unitsForDiscount).toFixed(2)
            : totalPrice.toFixed(2)}
        </div>
      </div>
    </>
  );
};
