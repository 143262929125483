import 'reflect-metadata';

import React, { FC, useEffect } from 'react';
import { Route, Switch, useLocation } from 'react-router-dom';

import { Alert } from 'components/Alert';
import { CSSTransition } from 'react-transition-group';
import { CartPage } from 'pages/Cart';
import { Checkout } from 'pages/Checkout';
import { Footer } from './components/Layout/Footer';
import { LoadingPlaceholder } from '@trackman/react-ui';
import { NavBar } from './components/Layout/NavBar/NavBar';
import { NotFound } from 'pages/NotFound/NotFound';
import { Overlay } from './components/Layout/Overlay/Overlay';
import { ProductPage } from './pages/ProductPage';
import { ProductsOverview } from './pages/ProductsOverview/ProductsOverview';
import ReactGA from 'react-ga';
import { Elements as StripesProvider } from '@stripe/react-stripe-js';
import { TermsPage } from 'pages/Terms/TermsPage';
import { loadStripe } from '@stripe/stripe-js';
import { observer } from 'mobx-react-lite';
import { useGolfShopStore } from 'stores/hooks';

ReactGA.initialize('UA-9235741-34');

export const App: FC = observer(() => {
  const {
    isMobileMenuExpanded,
    products,
    stripePublicKey,
    loadProducts,
    setIsMobileMenuExpanded,
    isInitialized,
  } = useGolfShopStore();

  useEffect(() => {
    if (products.length === 0) {
      loadProducts();
    }
  }, [loadProducts, products]);

  const location = useLocation();
  React.useEffect(() => {
    ReactGA.set({
      page: location.pathname,
    });
    ReactGA.pageview(location.pathname);
  }, [location]);

  return (
    <StripesProvider
      stripe={stripePublicKey ? loadStripe(stripePublicKey) : null}
      options={{ locale: 'en' }}
    >
      <div className="App">
        <NavBar />
        <Alert />
        <CSSTransition
          in={isMobileMenuExpanded}
          timeout={500}
          classNames="overlay-transition"
          unmountOnExit
        >
          <Overlay onClickCallback={() => setIsMobileMenuExpanded(false)} />
        </CSSTransition>
        <div style={{ marginTop: 144 }}>
          {!isInitialized ? (
            <LoadingPlaceholder />
          ) : (
            <Switch>
              <Route exact={true} path="/" component={ProductsOverview} />
              <Route path="/product/:id/:productType" component={ProductPage} />
              <Route path="/baseballproduct/:id/:productType" component={ProductPage} />
              <Route path="/cart" component={CartPage} />
              <Route path="/checkout" component={Checkout} />
              <Route path="/terms" component={TermsPage} />
              <Route>
                <NotFound />
              </Route>
            </Switch>
          )}
        </div>
        <Footer />
      </div>
    </StripesProvider>
  );
});
