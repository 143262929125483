import React, { FC } from 'react';

import { IListItem } from 'models/IListItem';
import { ProductType } from 'api';
import { Subscription } from './Subscription';
import { observer } from 'mobx-react-lite';
import styled from 'styled-components';

const Wrapper = styled.div`
  display: flex;
  flex-flow: row wrap;

  .subscription-tile {
    background: white;
    margin: 10px;
    width: calc((100% / 3) - 20px);
    padding: 10px;

    .subscription-info {
      margin-top: 8px;
    }

    .subscription-expiration {
      font-size: 13px;
      font-weight: normal;
      position: relative;
      color: #a0a0a0;
    }
  }

  @media (max-width: 576px) {
    .subscription-tile {
      width: 100%;
    }
  }
`;

interface IProps {
  item: IListItem;
}

export const SubscriptionInformation: FC<IProps> = observer(({ item }) => {
  return (
    <Wrapper>
      {item.b1Software && (
        <div className="subscription-tile">
          <Subscription
            subscription={item.b1Software}
            productType={ProductType.B1Software}
            nameIdentifier="b1software"
          />
        </div>
      )}
      {item.ioHardware && (
        <div className="subscription-tile">
          <Subscription
            subscription={item.ioHardware}
            productType={ProductType.IOHardware}
            nameIdentifier="iohardware"
          />
        </div>
      )}
      {item.ioHardwareDuo && (
        <div className="subscription-tile">
          <Subscription
            subscription={item.ioHardwareDuo}
            productType={ProductType.IOHardwareDuo}
            nameIdentifier="iohardware_duo"
          />
        </div>
      )}
      {item.ioSoftwareHome && (
        <div className="subscription-tile">
          <Subscription
            subscription={item.ioSoftwareHome}
            productType={ProductType.IOSoftwareHome}
            nameIdentifier="iosoftware_home"
          />
        </div>
      )}
      {item.ioSoftwareHomeComplete && (
        <div className="subscription-tile">
          <Subscription
            subscription={item.ioSoftwareHomeComplete}
            productType={ProductType.IOSoftwareHomeComplete}
            nameIdentifier="iosoftware_home_complete"
          />
        </div>
      )}
      {item.ioSoftwareCommercial && (
        <div className="subscription-tile">
          <Subscription
            subscription={item.ioSoftwareCommercial}
            productType={ProductType.IOSoftwareCommercial}
            nameIdentifier="iosoftware_commercial"
          />
        </div>
      )}
      {item.ioSoftwareHomeDuo && (
        <div className="subscription-tile">
          <Subscription
            subscription={item.ioSoftwareHomeDuo}
            productType={ProductType.IOSoftwareHomeDuo}
            nameIdentifier="iosoftware_home_duo"
          />
        </div>
      )}
      {item.ioSoftwareHomeCompleteDuo && (
        <div className="subscription-tile">
          <Subscription
            subscription={item.ioSoftwareHomeCompleteDuo}
            productType={ProductType.IOSoftwareHomeCompleteDuo}
            nameIdentifier="iosoftware_home_complete_duo"
          />
        </div>
      )}
      {item.ioSoftwareCommercialDuo && (
        <div className="subscription-tile">
          <Subscription
            subscription={item.ioSoftwareCommercialDuo}
            productType={ProductType.IOSoftwareCommercialDuo}
            nameIdentifier="iosoftware_commercial_duo"
          />
        </div>
      )}
      {item.hardware && (
        <div className="subscription-tile">
          <Subscription
            subscription={item.hardware}
            productType={ProductType.Hardware}
            nameIdentifier="hardware"
          />
        </div>
      )}
      {item.software && (
        <div className="subscription-tile">
          <Subscription
            subscription={item.software}
            productType={ProductType.Software}
            nameIdentifier="software"
          />
        </div>
      )}
      {item.coursePacks?.map((subscription, index) => {
        return (
          <div key={index} className="subscription-tile">
            <Subscription
              subscription={subscription}
              productType={ProductType.CoursePack}
              nameIdentifier={subscription.productIdentifier}
            />
          </div>
        );
      })}
      {item.coursePacksDuo?.map((subscription, index) => {
        return (
          <div key={index} className="subscription-tile">
            <Subscription
              subscription={subscription}
              productType={ProductType.CoursePackDuo}
              nameIdentifier={subscription.productIdentifier}
            />
          </div>
        );
      })}
    </Wrapper>
  );
});
