import React, { FC } from 'react';
import { FancyDropDown } from './FancyDropDown';
import { currencies } from 'data';
import { useGolfShopStore } from 'stores/hooks';
import { observer } from 'mobx-react-lite';
import { Currency } from 'models/Currency';

export const CurrencyDropDown: FC = observer(() => {
  const { currency, setCurrency } = useGolfShopStore();
  return (
    <FancyDropDown
      selected={currency}
      items={currencies.map((c) => ({
        value: c.code,
        element: `${`${c.symbol} ${c.text}`}`,
      }))}
      onChange={(newCurrency: Currency) => setCurrency(newCurrency)}
    />
  );
});
