import React, { FC } from 'react';

import { observer } from 'mobx-react-lite';
import styled from 'styled-components';
import { useGolfShopStore } from 'stores/hooks';

const Wrapper = styled.div`
  cursor: pointer;
  margin-top: 32px;

  &:hover {
    transform: scale(1.005);
  }

  img {
    box-shadow: 0px 5px 15px rgba(0, 0, 0, 0.1);
    border-radius: 4px;
    width: 100%;
    object-fit: contain;
    height: 212px;
    background: white;
    @media (max-width: 768px) {
      height: auto;
    }
  }

  .product-info {
    margin-top: 8px;
    height: 40px;
    align-items: center;
    display: flex;
    background: white;
    box-shadow: 0px 5px 15px rgba(0, 0, 0, 0.1);
    border-radius: 4px;
    padding: 0 16px;
  }

  .product-name {
    font-size: 15px;
  }

  .product-price {
    font-weight: bold;
    margin-left: auto;
    font-size: 20px;
    .from-price {
      font-weight: normal;
      font-size: 15px;
    }
  }
`;

interface IProps {
  productName: string;
  productImgSrc: string;
  productPrice: number;
  onClickCallback: () => void;
}

export const Product: FC<IProps> = observer(
  ({ productName, productImgSrc, productPrice, onClickCallback }) => {
    const { currencySymbol } = useGolfShopStore();

    return (
      <Wrapper onClick={onClickCallback}>
        <img src={productImgSrc} alt={productName} />
        <div className="product-info">
          <span className="product-name">{productName}</span>
          <span className="product-price">
            {productPrice}
            {currencySymbol}
          </span>
          <div className="per-year">&nbsp;/yr</div>
        </div>
      </Wrapper>
    );
  }
);
