import { useRef } from 'react';

export const useDebounce = (func: (...args: any) => any, delay: number) => {
  const timeout = useRef<any>();
  return (...args: any[]) => {
    clearTimeout(timeout.current);
    timeout.current = setTimeout(() => {
      return func(...args);
    }, delay);
  };
};
