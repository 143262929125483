import { ICoursePack, ILicense } from 'models/IListItem';
import { ProductGetResponse, ProductType } from 'api';

import { Currency } from 'models/Currency';
import { IListItem } from '../models/IListItem';
import { getCoursePackByProductId } from './coursePack';

export const getExpirationForYear = (args: {
  item?: ICoursePack | ILicense;
  yearDuration?: number;
}) => {
  const { item, yearDuration } = args;

  return yearDuration === 1
    ? item?.priceAndDate?.oneYearExpirationDate
    : item?.priceAndDate?.threeYearExpirationDate;
};

export const getExpiration = (args: {
  productType: ProductType;
  item?: ICoursePack | ILicense;
  softwareYearDuration?: number;
  yearDuration?: number;
}) => {
  const { productType, item, softwareYearDuration, yearDuration } = args;

  if (
    [
      ProductType.Hardware,
      ProductType.Software,
      ProductType.B1Software,
      ProductType.IOHardware,
      ProductType.IOSoftwareHome,
      ProductType.IOSoftwareHomeComplete,
      ProductType.IOSoftwareCommercial,
      ProductType.IOSoftwareHomeDuo,
      ProductType.IOSoftwareHomeCompleteDuo,
      ProductType.IOSoftwareCommercialDuo,
      ProductType.IOHardwareDuo,
    ].includes(productType)
  ) {
    return yearDuration === 1
      ? item?.priceAndDate?.oneYearExpirationDate
      : item?.priceAndDate?.threeYearExpirationDate;
  }

  if (productType === ProductType.CoursePack) {
    const coursePack = item as ICoursePack;

    if (softwareYearDuration === 1) {
      return yearDuration === 1
        ? coursePack?.renewSoftwareSubscriptionOneYearPriceAndDate?.oneYearExpirationDate
        : coursePack?.renewSoftwareSubscriptionOneYearPriceAndDate?.threeYearExpirationDate;
    } else if (softwareYearDuration === 3) {
      return yearDuration === 1
        ? coursePack?.renewSoftwareSubscriptionThreeYearPriceAndDate?.oneYearExpirationDate
        : coursePack?.renewSoftwareSubscriptionThreeYearPriceAndDate?.threeYearExpirationDate;
    } else
      return coursePack?.yearDuration === 1
        ? coursePack?.priceAndDate?.oneYearExpirationDate
        : coursePack?.priceAndDate?.threeYearExpirationDate;
  }

  if (productType === ProductType.CoursePackDuo) {
    const coursePackDuo = item as ICoursePack;

    if (softwareYearDuration === 1) {
      return yearDuration === 1
        ? coursePackDuo?.renewSoftwareSubscriptionOneYearPriceAndDate?.oneYearExpirationDate
        : coursePackDuo?.renewSoftwareSubscriptionOneYearPriceAndDate?.threeYearExpirationDate;
    } else if (softwareYearDuration === 3) {
      return yearDuration === 1
        ? coursePackDuo?.renewSoftwareSubscriptionThreeYearPriceAndDate?.oneYearExpirationDate
        : coursePackDuo?.renewSoftwareSubscriptionThreeYearPriceAndDate?.threeYearExpirationDate;
    } else
      return coursePackDuo?.yearDuration === 1
        ? coursePackDuo?.priceAndDate?.oneYearExpirationDate
        : coursePackDuo?.priceAndDate?.threeYearExpirationDate;
  }
};

export const getPrice = (args: {
  productType: ProductType;
  item?: ICoursePack | ILicense;
  currency: Currency;
  softwareYearDuration?: number;
  yearDuration?: number;
}) => {
  const { productType, item, currency, softwareYearDuration, yearDuration } = args;

  if (
    [
      ProductType.Hardware,
      ProductType.Software,
      ProductType.B1Software,
      ProductType.IOHardware,
      ProductType.IOSoftwareHome,
      ProductType.IOSoftwareHomeComplete,
      ProductType.IOSoftwareCommercial,
      ProductType.IOSoftwareHomeDuo,
      ProductType.IOSoftwareHomeCompleteDuo,
      ProductType.IOSoftwareCommercialDuo,
      ProductType.IOHardwareDuo,
    ].includes(productType)
  ) {
    return yearDuration === 1
      ? item?.priceAndDate?.oneYear[currency]
      : item?.priceAndDate?.threeYear[currency];
  }

  if (productType === ProductType.CoursePack) {
    const coursePack = item as ICoursePack;
    // if there is a software subscription in the cart with a yearDuration sat

    if (!softwareYearDuration) {
      return yearDuration === 1
        ? coursePack?.priceAndDate?.oneYear[currency]
        : coursePack?.priceAndDate?.threeYear[currency];
    }

    // the software subscription in the cart has a year duration of 1
    if (softwareYearDuration === 1) {
      return yearDuration === 1
        ? coursePack.renewSoftwareSubscriptionOneYearPriceAndDate.oneYear[currency]
        : coursePack.renewSoftwareSubscriptionOneYearPriceAndDate.threeYear[currency];
      // the software subscription in the cart has a year duration of 3
    }

    if (softwareYearDuration === 3) {
      return yearDuration === 1
        ? coursePack?.renewSoftwareSubscriptionThreeYearPriceAndDate?.oneYear[currency]
        : coursePack?.renewSoftwareSubscriptionThreeYearPriceAndDate?.threeYear[currency];
    }
  }

  if (productType === ProductType.CoursePackDuo) {
    const coursePackDuo = item as ICoursePack;
    // if there is a software subscription in the cart with a yearDuration sat

    if (!softwareYearDuration) {
      return yearDuration === 1
        ? coursePackDuo?.priceAndDate?.oneYear[currency]
        : coursePackDuo?.priceAndDate?.threeYear[currency];
    }

    // the software subscription in the cart has a year duration of 1
    if (softwareYearDuration === 1) {
      return yearDuration === 1
        ? coursePackDuo.renewSoftwareSubscriptionOneYearPriceAndDate.oneYear[currency]
        : coursePackDuo.renewSoftwareSubscriptionOneYearPriceAndDate.threeYear[currency];
      // the software subscription in the cart has a year duration of 3
    }

    if (softwareYearDuration === 3) {
      return yearDuration === 1
        ? coursePackDuo?.renewSoftwareSubscriptionThreeYearPriceAndDate?.oneYear[currency]
        : coursePackDuo?.renewSoftwareSubscriptionThreeYearPriceAndDate?.threeYear[currency];
    }
  }
  return 0;
};

// TODO: has to be replaced by the one above
export const getDisplayPrice = (
  productType: ProductType,
  yearDuration: number,
  currency: Currency,
  item: IListItem,
  product: ProductGetResponse,
  cartItem?: IListItem,
  coursePackProductId?: string
): number | undefined => {
  if (productType === ProductType.Hardware) {
    if (yearDuration === 1) {
      return item.hardware?.priceAndDate?.oneYear[currency];
    } else if (yearDuration === 3) {
      return item.hardware?.priceAndDate?.threeYear[currency];
    }
  } else if (productType === ProductType.B1Software) {
    if (yearDuration === 1) {
      return item.b1Software?.priceAndDate?.oneYear[currency];
    } else if (yearDuration === 3) {
      return item.b1Software?.priceAndDate?.threeYear[currency];
    }
  } else if (productType === ProductType.Software) {
    if (yearDuration === 1) {
      return item.software?.priceAndDate?.oneYear[currency];
    } else if (yearDuration === 3) {
      return item.software?.priceAndDate?.threeYear[currency];
    }
  } else if (productType === ProductType.IOHardware) {
    if (yearDuration === 1) {
      return item.ioHardware?.priceAndDate?.oneYear[currency];
    } else if (yearDuration === 3) {
      return item.ioHardware?.priceAndDate?.threeYear[currency];
    }
  } else if (productType === ProductType.IOSoftwareHome) {
    if (yearDuration === 1) {
      return item.ioSoftwareHome?.priceAndDate?.oneYear[currency];
    } else if (yearDuration === 3) {
      return item.ioSoftwareHome?.priceAndDate?.threeYear[currency];
    }
  } else if (productType === ProductType.IOSoftwareHomeComplete) {
    if (yearDuration === 1) {
      return item.ioSoftwareHomeComplete?.priceAndDate?.oneYear[currency];
    } else if (yearDuration === 3) {
      return item.ioSoftwareHomeComplete?.priceAndDate?.threeYear[currency];
    }
  } else if (productType === ProductType.IOSoftwareCommercial) {
    if (yearDuration === 1) {
      return item.ioSoftwareCommercial?.priceAndDate?.oneYear[currency];
    } else if (yearDuration === 3) {
      return item.ioSoftwareCommercial?.priceAndDate?.threeYear[currency];
    }
  } else if (productType === ProductType.IOSoftwareHomeDuo) {
    if (yearDuration === 1) {
      return item.ioSoftwareHomeDuo?.priceAndDate?.oneYear[currency];
    } else if (yearDuration === 3) {
      return item.ioSoftwareHomeDuo?.priceAndDate?.threeYear[currency];
    }
  } else if (productType === ProductType.IOSoftwareHomeCompleteDuo) {
    if (yearDuration === 1) {
      return item.ioSoftwareHomeCompleteDuo?.priceAndDate?.oneYear[currency];
    } else if (yearDuration === 3) {
      return item.ioSoftwareHomeCompleteDuo?.priceAndDate?.threeYear[currency];
    }
  } else if (productType === ProductType.IOSoftwareCommercialDuo) {
    if (yearDuration === 1) {
      return item.ioSoftwareCommercialDuo?.priceAndDate?.oneYear[currency];
    } else if (yearDuration === 3) {
      return item.ioSoftwareCommercialDuo?.priceAndDate?.threeYear[currency];
    }
  } else if (productType === ProductType.CoursePack) {
    const coursePack = getCoursePackByProductId(item, coursePackProductId);
    if (coursePack) {
      // if there is a software subscription in the cart with a yearDuration sat
      if (cartItem?.software?.yearDuration) {
        // the software subscription in the cart has a year duration of 1
        if (cartItem?.software?.yearDuration === 1) {
          if (yearDuration === 1) {
            return coursePack.renewSoftwareSubscriptionOneYearPriceAndDate?.oneYear[currency];
          } else if (yearDuration === 3) {
            return coursePack.renewSoftwareSubscriptionOneYearPriceAndDate?.threeYear[currency];
          }
          // the software subscription in the cart has a year duration of 3
        } else if (cartItem.software.yearDuration === 3) {
          if (yearDuration === 1) {
            return coursePack.renewSoftwareSubscriptionThreeYearPriceAndDate?.oneYear[currency];
          } else if (yearDuration === 3) {
            return coursePack.renewSoftwareSubscriptionThreeYearPriceAndDate?.threeYear[currency];
          }
        }
      } else {
        if (yearDuration === 1) {
          return product && coursePack.priceAndDate?.oneYear[currency];
        } else if (yearDuration === 3) {
          return product && coursePack.priceAndDate?.threeYear[currency];
        }
      }
    }
    return;
  } else if (productType === ProductType.IOHardwareDuo) {
    if (yearDuration === 1) {
      return item.ioHardwareDuo?.priceAndDate?.oneYear[currency];
    } else if (yearDuration === 3) {
      return item.ioHardwareDuo?.priceAndDate?.threeYear[currency];
    }
  } else if (productType === ProductType.CoursePackDuo) {
    const coursePackDuo = getCoursePackByProductId(item, coursePackProductId);
    const softwareYearDuration =
      cartItem?.ioSoftwareHomeDuo?.yearDuration ??
      cartItem?.ioSoftwareHomeCompleteDuo?.yearDuration ??
      cartItem?.ioSoftwareCommercialDuo?.yearDuration;
    if (coursePackDuo) {
      // if there is a software subscription in the cart with a yearDuration sat
      if (softwareYearDuration) {
        // the software subscription in the cart has a year duration of 1
        if (softwareYearDuration === 1) {
          if (yearDuration === 1) {
            return coursePackDuo.renewSoftwareSubscriptionOneYearPriceAndDate?.oneYear[currency];
          } else if (yearDuration === 3) {
            return coursePackDuo.renewSoftwareSubscriptionOneYearPriceAndDate?.threeYear[currency];
          }
          // the software subscription in the cart has a year duration of 3
        } else if (softwareYearDuration === 3) {
          if (yearDuration === 1) {
            return coursePackDuo.renewSoftwareSubscriptionThreeYearPriceAndDate?.oneYear[currency];
          } else if (yearDuration === 3) {
            return coursePackDuo.renewSoftwareSubscriptionThreeYearPriceAndDate?.threeYear[
              currency
            ];
          }
        }
      } else {
        if (yearDuration === 1) {
          return product && coursePackDuo.priceAndDate?.oneYear[currency];
        } else if (yearDuration === 3) {
          return product && coursePackDuo.priceAndDate?.threeYear[currency];
        }
      }
    }
    return;
  }
};

export const getDiscount = (numberOfUnits: number) => {
  switch (true) {
    case numberOfUnits >= 100:
      return 30;
    case numberOfUnits >= 50:
      return 25;
    case numberOfUnits >= 30:
      return 20;
    case numberOfUnits >= 20:
      return 15;
    case numberOfUnits >= 10:
      return 10;
    case numberOfUnits >= 4:
      return 7.5;
    case numberOfUnits >= 2:
      return 5;
    default:
      return 0;
  }
};

export const getTotalPriceAfterDiscount = (price: number, numberOfUnits: number) => {
  const discount = getDiscount(numberOfUnits) / 100;
  return Number(((1 - discount) * price).toFixed(2));
};

export const getTotalDiscountPrice = (price: number, numberOfUnits: number) => {
  const discount = getDiscount(numberOfUnits) / 100;
  return Number((price * discount).toFixed(2));
};

export const calculate5PercentThreeYearPrice = (productType: ProductType, price: number) => {
  const total = price * 3;
  switch (productType) {
    //Hardware and Software
    case ProductType.Hardware:
    case ProductType.B1Software:
    case ProductType.IOHardware:
    case ProductType.IOSoftwareHome:
    case ProductType.IOSoftwareHomeComplete:
    case ProductType.IOSoftwareCommercial:
    case ProductType.IOSoftwareHomeDuo:
    case ProductType.IOSoftwareHomeCompleteDuo:
    case ProductType.IOSoftwareCommercialDuo:
    case ProductType.Software:
    case ProductType.IOHardwareDuo:
      return total - total * 0.05;
    case ProductType.CoursePack:
    case ProductType.CoursePackDuo:
      return price * 2;
    default:
      new Error(`Unkown product ${productType}`);
  }
};

export const calculateFullThreeYearPrice = (productType: ProductType, price: number) => {
  switch (productType) {
    //Hardware and Software
    case ProductType.Hardware:
    case ProductType.B1Software:
    case ProductType.IOHardware:
    case ProductType.IOSoftwareHome:
    case ProductType.IOSoftwareHomeComplete:
    case ProductType.IOSoftwareCommercial:
    case ProductType.IOSoftwareHomeDuo:
    case ProductType.IOSoftwareHomeCompleteDuo:
    case ProductType.IOSoftwareCommercialDuo:
    case ProductType.Software:
    case ProductType.CoursePack:
    case ProductType.IOHardwareDuo:
    case ProductType.CoursePackDuo:
      return price * 3;
    default:
      new Error(`Unkown product ${productType}`);
  }
};
