import React, { FC } from 'react';
import styled from 'styled-components';
import { Container, Row, Col } from 'styled-bootstrap-grid';
import { CheckoutOrderDetails, BillingDetails, CardDetails } from 'pages/Checkout/models';
import { OrderSummary } from 'pages/Checkout/OrderSummary';
import { Currency } from 'models/Currency';
import { getCountryName } from 'utils';
import { capitalizeFirstLetter } from 'utils/strings';

const Wrapper = styled.div`
  background: #ffffff;
  box-shadow: 0px 5px 15px rgba(0, 0, 0, 0.1);
  border-radius: 4px;

  @media only screen and (min-width: 768px) {
    padding: 47px 55px;
  }
  @media only screen and (min-width: 992px) {
    padding: 47px 95px;
  }

  h2 {
    font-size: 28px;
    margin-bottom: 30px;
    margin-right: 16px;
    display: inline-block;
    + div {
      width: 100%;
      font-size: 15px;
      font-weight: normal;
      overflow: hidden;
      display: inline-block;
    }
  }

  h3 {
    font-size: 18px;
    text-transform: uppercase;
  }

  .block {
    margin-bottom: 48px;
    font-size: 18px;
    line-height: 25px;
  }

  .confirmation {
    margin-bottom: 48px;
  }
`;

export interface OrderConfirmationCardProps {
  orderNumber: string;
  orderDate: string;
  currency: Currency;
  orderDetails: CheckoutOrderDetails;
  billingDetails: BillingDetails;
  cardDetails: CardDetails;
}

export const OrderConfirmationCard: FC<OrderConfirmationCardProps> = ({
  orderNumber,
  orderDate,
  orderDetails,
  billingDetails,
  cardDetails,
}) => {
  const { firstName, lastName, address, postCode, city, countryCode, phone } = billingDetails;

  return (
    <Wrapper>
      <Container>
        <Row>
          <Col>
            <h2>Thank you for your order</h2>
            <div className="confirmation">
              You will receive a confirmation email when your order has been processed. It can take
              a couple of hours before the license is activated.
            </div>
          </Col>
        </Row>
        <Row>
          <Col sm={6}>
            <div>
              <div className="block">
                <h3>Order number</h3>
                <p>{orderNumber}</p>
              </div>
              <div className="block">
                <h3>Date</h3>
                <span>{orderDate}</span>
              </div>
              <div className="block">
                <h3>Billing details</h3>
                <p>
                  {firstName} {lastName}
                </p>
                <p>{address}</p>
                <p>{postCode}</p>
                <p>{city}</p>
                <p>{getCountryName(countryCode)}</p>
                <p>{phone}</p>
              </div>
              <div className="block">
                <h3>Payment method</h3>
                <p>
                  {cardDetails.lastDigits} - {capitalizeFirstLetter(cardDetails.brand)}
                </p>
                <p>Expiry date: {cardDetails.expiry}</p>
              </div>
            </div>
          </Col>
          <Col sm={6}>
            <h3 className="block">Order summary</h3>
            <OrderSummary orderDetails={orderDetails} countryCode={billingDetails.countryCode} />
          </Col>
        </Row>
      </Container>
    </Wrapper>
  );
};
